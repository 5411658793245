import {
  Box,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";

const Account = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      height="100vh"
      paddingTop="12rem"
      width="100vw"
    >
      <Box width="100vw" marginTop="2rem">
        <Tabs variant="enclosed" isFitted colorScheme="brand">
          <TabList>
            <Tab>
              <Heading variant="h4">Sign In</Heading>
            </Tab>
            <Tab>
              <Heading variant="h4">Sign Up</Heading>
            </Tab>
          </TabList>
          <TabIndicator height="1px" bg="brand.900" />
          <TabPanels>
            <TabPanel>
              <SignIn />
            </TabPanel>
            <TabPanel>
              <SignUp />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default Account;
