import React, { useEffect, useState } from "react";
import { Link, Link as ReachLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
} from "@chakra-ui/react";
import useFetch from "../../../../actions/useFetch";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
// Helpers

import useUrlState from "@ahooksjs/use-url-state";
// Components
import StandingsArtwork from "../components/StandingsArtwork";
import axios from "axios";
import { useQuery } from "react-query";
import CustomSpinner from "../../components/CustomSpinner";
import KoLeagueStandingsArtwork from "../../soccer/components/soccerLaduma/KoLeagueStandingsArtwork";

// const fetchTableData = () => {
//   return axios.get(
//     "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/standings/1394.xml?json=1"
//   );
// };

const fetchHistoricTableData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/standings/1204.xml?json=1"
  );
};

const fetchLeagueData = (leagueId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/standings/${leagueId}.xml?json=1`
  );
};

const LeagueStandings = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const [standingsInput, setStandingsInput] = useUrlState({
    leagueId: "",
    team: "",
    image: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setStandingsInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const {
    data: standingsData,
    isPending: isStandingsDataPending,
    error: standingsListError,
    isFetching: isFetchingStandingsData,
    refetch: refetchStandingsData,
  } = useQuery("logStandings", () => fetchLeagueData(standingsInput.leagueId));

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("soccer");

  // Define Log standings

  useEffect(() => {
    if (standingsInput.leagueId != "") {
      refetchStandingsData();
    }
  }, [standingsInput.leagueId]);

  const [leagueStandings, setLeagueStandings] = useState([]);
  useEffect(() => {
    setLeagueStandings(standingsData?.data?.standings?.tournament?.team);
  }, [standingsData]);

  const [displayedLeagueStandings, setDisplayedLeagueStandings] = useState([]);

  useEffect(() => {
    if (leagueStandings?.length > 0 && graphicData != null) {
      const formattedStandings = leagueStandings?.map((team) => {
        const matchingTeam = graphicData?.[
          standingsInput.leagueId
        ]?.clubs?.find((item) => item.id === team["@id"]);

        if (matchingTeam != null) {
          return { ...team, flag: matchingTeam.flag };
        }
      });

      setDisplayedLeagueStandings({
        competition: standingsInput.leagueId,
        standings: formattedStandings,
      });
    }
  }, [leagueStandings, graphicData, standingsInput.leagueId]);

  // Download Image
  const imageFilename = "Standings.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [standingsInput]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  if (isFetchingStandingsData || isGraphicDataPending) {
    return <CustomSpinner />;
  }

  return (
    <>
      {graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h2">
                Standings
              </Heading>
              <Flex mt={8} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="League"
                  name="leagueId"
                  onChange={handleInputChange}
                  value={standingsInput.leagueId}
                >
                  <option value="1204">English Premier League</option>
                  <option value="1394">DStv Prem</option>
                </Select>
              </Flex>

              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              {/* <StandingsArtwork
                displayedLeagueStandings={displayedLeagueStandings}
              /> */}
              <KoLeagueStandingsArtwork
                displayedLeagueStandings={displayedLeagueStandings}
              />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default LeagueStandings;
