import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

const ArtWorkSpinner = () => {
  return (
    <Flex
      //   className="body-container"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexShrink="1"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="brand.500"
        color="brand.900"
        size="xl"
      />
    </Flex>
  );
};

export default ArtWorkSpinner;
