import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import InterviewArtwork from "../../womensWorldCup/components/InterviewArtwork";
import React, { useEffect, useState } from "react";
import useFetch from "../../../../actions/useFetch";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
import useUrlState from "@ahooksjs/use-url-state";
import { sortByAlphabet } from "../../../../helpers/SortByAlphabet";
import LeagueFixturesArtwork from "../components/LeagueFixturesArtwork";
import { useQuery } from "react-query";
// React Date Picker
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const fetchFixturesData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerfixtures/leagueid/1394?json=1"
  );
};

const Fixtures = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  //   Fetch Data
  const {
    data: fixtureData,
    isPending: isFixtureDataPending,
    error: fixtureDataError,
  } = useFetch(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerfixtures/leagueid/1394?json=1"
  );

  const {
    data: fixturesData,
    isPending: isFixturesDataPending,
    error: fixturesDataError,
  } = useQuery("fixturesData", fetchFixturesData);

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("psl");

  //   Define and get inputs
  const [fixturesInput, setFixturesInput] = useUrlState({
    week: "",
    team: "",
    image: "",
    selectedImage: "",
  });

  const handleFixturesInput = (e) => {
    const { name, value } = e.target;

    setFixturesInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const fixtureWeeks = [];

  for (let i = 1; i <= 5; i++) {
    fixtureWeeks.push(i);
  }

  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleDateChange = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  // const handleDateChange = (date) => {
  //   setActiveFixtureDate(date);
  // };

  // if (matchDate != null) {
  //     formattedDate = matchDate.toLocaleString( 'en-US',{
  //         year: 'numeric',
  //         month: 'long',
  //         day: 'numeric',
  //         hour: 'numeric',
  //         hour12: false, // Use 24-hour format
  //         minute: 'numeric',
  //     });

  //     kickOff = formattedDate.replace(/,|\bat\b/gi, '');
  // }

  // New Fixture Approach
  const [leagueFixturesData, setLeagueFixturesData] = useState(undefined);
  const [flattenedLeagueFixturesData, setFlattenedLeagueFixturesData] =
    useState(undefined);

  // Define League Fixtures Data
  useEffect(() => {
    if (fixturesData) {
      setLeagueFixturesData(fixturesData?.data?.results.tournament.week);
    }
  }, [fixturesData]);

  // Flatten Fixture Array
  useEffect(() => {
    if (leagueFixturesData) {
      // Use reduce to flatten the match arrays into one array of objects
      const flattenedMatches = leagueFixturesData.reduce(
        (accumulator, currentValue) => {
          // Concatenate the match arrays of each round into the accumulator array
          return accumulator.concat(currentValue.match);
        },
        []
      );

      setFlattenedLeagueFixturesData(flattenedMatches);
    }
  }, [leagueFixturesData]);

  // Filter According to Picked Dates
  const [activeFixturesData, setActiveFixturesData] = useState([]);
  useEffect(() => {
    if (flattenedLeagueFixturesData && startDate && endDate) {
      console.log(flattenedLeagueFixturesData);
      const filteredFixtures = flattenedLeagueFixturesData.filter((fixture) => {
        // Parse the date in the format 'dd.mm.yyyy' to a Date object
        const fixtureDateParts = fixture["@date"].split(".");
        const fixtureDate = new Date(
          Number(fixtureDateParts[2]), // Year
          Number(fixtureDateParts[1]) - 1, // Month (subtract 1 because months are zero-based)
          Number(fixtureDateParts[0]) // Day
        );

        // Check if the fixture date is within the selected range
        return fixtureDate >= startDate && fixtureDate <= endDate;
      });

      // Group Fixtures By Date
      const sortedFixtures = filteredFixtures.sort((a, b) => {
        const dateA = new Date(a["@date"]);
        const dateB = new Date(b["@date"]);
        return dateA - dateB;
      });

      // Group fixtures by date into separate arrays
      const groupedFixtures = {};
      sortedFixtures.forEach((fixture) => {
        const dateKey = fixture["@date"];
        if (!groupedFixtures[dateKey]) {
          groupedFixtures[dateKey] = [];
        }
        groupedFixtures[dateKey].push(fixture);
      });

      // Convert groupedFixtures object into an array of arrays
      const formattedFixtures = Object.values(groupedFixtures);
      if (formattedFixtures) {
        setActiveFixturesData(formattedFixtures);
      }
      console.log(formattedFixtures);
    }
  }, [flattenedLeagueFixturesData, startDate, endDate]);

  // Define active fixtures
  const [leagueFixtures, setLeagueFixtures] = useState([]);
  const [activeFixtures, setActiveFixtures] = useState([]);

  useEffect(() => {
    if (fixtureData != null) {
      setLeagueFixtures(fixtureData?.results?.tournament?.week);
    }
  }, [fixtureData]);

  // console.log("fixtures ", leagueFixtures);

  useEffect(() => {
    if (leagueFixtures.length > 0 && fixturesInput.week != "") {
      const matchingWeek = leagueFixtures.find(
        (item) => item["@number"] == fixturesInput.week
      );

      if (matchingWeek != null) {
        setActiveFixtures(matchingWeek.match);
      }
    }
  }, [fixturesInput.week, leagueFixtures]);

  const [formattedLeagueFixtures, setFormattedLeagueFixtures] = useState([""]);

  useEffect(() => {
    if (activeFixtures.length > 0 && fixturesInput.week != "") {
      const definedLeagueFixtures = activeFixtures?.map((fixture) => ({
        date: fixture["@date"],
        venue: fixture["@venue"],
        time: fixture["@time"],
        localTeam: {
          id: fixture.localteam["@id"],
          name: fixture.localteam["@name"],
          flag: "",
          score: fixture.localteam["@score"],
        },
        visitorTeam: {
          id: fixture.visitorteam["@id"],
          name: fixture.visitorteam["@name"],
          flag: "",
          score: fixture.visitorteam["@score"],
        },
      }));

      setFormattedLeagueFixtures(definedLeagueFixtures);
    }
  }, [activeFixtures, fixturesInput.week]);

  // Graphic assets
  const [displayedFixtures, setDisplayedFixtures] = useState([]);

  useEffect(() => {
    if (graphicData != null && formattedLeagueFixtures.length > 0) {
      const updatedFixtures = formattedLeagueFixtures.map((fixture) => {
        const matchingLocalTeam = graphicData?.clubs?.find(
          (item) => item.id == fixture.localTeam?.id
        );

        const matchingVisitorTeam = graphicData?.clubs?.find(
          (item) => item.id == fixture.visitorTeam?.id
        );

        if (matchingLocalTeam && matchingVisitorTeam != null) {
          return {
            ...fixture,
            localTeam: {
              ...fixture.localTeam,
              flag: matchingLocalTeam.flag,
            },
            visitorTeam: {
              ...fixture.visitorTeam,
              flag: matchingVisitorTeam.flag,
            },
          };
        }

        return fixture;
      });

      setDisplayedFixtures(updatedFixtures);
    }
  }, [graphicData, fixturesInput.week, formattedLeagueFixtures]);

  // Download Image
  const imageFilename = "Fixtures.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [displayedFixtures]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  return (
    <>
      {fixtureData && graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h2">
                Fixtures
              </Heading>

              <Flex mt={3} mb={2}>
                <Select
                  width="100%"
                  sx={selectStyle}
                  placeholder="Fixture Week"
                  name="week"
                  value={fixturesInput.week}
                  onChange={handleFixturesInput}
                >
                  {fixtureWeeks.map((week) => (
                    <option key={week} value={week}>
                      Week {week}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <ReactDatePicker
                  selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(2023, 7, 4)}
                  placeholderText="Fixtures Date"
                  width="100%"
                />
              </Flex>
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              <LeagueFixturesArtwork displayedFixtures={displayedFixtures} />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default Fixtures;
