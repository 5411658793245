import React from "react";
import SubHeader from "../components/SubHeader";
import { Box } from "@chakra-ui/react";
import PageIntro from "../components/PageIntro";
import AboutSection from "../components/AboutSection";

const About = () => {
  return (
    <Box>
      <SubHeader />
      <PageIntro />
      <AboutSection />
    </Box>
  );
};

export default About;
