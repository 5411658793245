import { Box, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import ReactPlayer from "react-player";
import Typewriter from "typewriter-effect";
import React, { useEffect, useState } from "react";

// Video
import productShowcase from "../../../assets/productShowcase/RHESTsub product showcase.mp4";
// Animations
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const HomeVideo = () => {
  // Framer Motion
  const MotionText = motion(Text);
  const MotionBox = motion(Box);

  const textVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 1, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 1, duration: 1 },
    },
  };

  const videoVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 1.5, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 1.5, duration: 1 },
    },
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [typewriterStarted, setTypewriterStarted] = useState(false);

  useEffect(() => {
    if (inView && !typewriterStarted) {
      setTypewriterStarted(true);
    }
  }, [inView, typewriterStarted]);

  return (
    <>
      <Flex
        alignItems="center"
        flexDir="column"
        height={{ base: "auto", lg: "90vh" }}
        ref={ref}
      >
        <Flex
          margin="8rem 2rem"
          gap="2rem"
          flexDir={{ base: "column", lg: "row" }}
          alignItems="center"
          className="how-container"
        >
          <Box>
            <Heading variant="h2">
              {typewriterStarted && (
                <Typewriter
                  options={{
                    strings: "How?",
                    autoStart: true,
                    delay: 75,
                    pauseFor: 1000000,
                    cursor: "",
                  }}
                />
              )}
            </Heading>

            <MotionText
              variants={textVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              variant="p"
              as="p"
            >
              All sections of the artwork are powered by up-to-the-minute data
              points, RHESTSub offers various options which when selected,
              display various statistics and adjust the artwork accordingly.
            </MotionText>
          </Box>

          <Box>
            <MotionBox
              variants={videoVariants}
              initial="hidden"
              animate="visible"
              boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"
            >
              <ReactPlayer
                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"
                width={{ base: "350px", lg: "800px" }}
                height={{ base: "234px", lg: "534px" }}
                paddingY="4rem"
                url={productShowcase}
                playing={true}
                loop={true}
                muted={true}
              />
            </MotionBox>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default HomeVideo;
