import React, { useEffect, useState } from "react";
import {
  Link,
  Link as ReachLink,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
} from "@chakra-ui/react";
import useFetch from "../../../../actions/useFetch";
import useUrlState from "@ahooksjs/use-url-state";

import useFetchFirebase from "../../../../actions/useFetchFirebase";
// Artworks
import LiveFixturesArtwork from "../components/LiveFixturesArtwork";
import axios from "axios";
import { useQuery } from "react-query";
import CustomSpinner from "../../components/CustomSpinner";

// const fetchSoccerLiveFixturesData = () => {
//   return axios.get(
//     "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/1394.xml?json=1"
//   );
// };
const fetchSoccerLiveFixturesData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/1394?date=27.09.2023&json=1"
  );
};

const fetchRugbyLiveFixturesData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/live_stats&json=1"
  );
};

const LiveFixtures = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  // Get Location
  const location = useLocation();

  const [fetchedSportsData, setFetchedSportsData] = useState(undefined);
  useEffect(() => {
    if (location.pathname == "/rugby/world-cup/live-fixtures") {
      setFetchedSportsData("fetchRugbyLiveFixturesData");
    } else {
      setFetchedSportsData("fetchSoccerLiveFixturesData");
    }
  }, []);

  const {
    data: fixturesData,
    isPending: isFixturesListDataPending,
    error: fixturesListError,
  } = useQuery("liveSoccerFixtures", fetchSoccerLiveFixturesData);

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("psl");

  const [activeFixture, setActiveFixture] = useUrlState({
    id: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setActiveFixture((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const [activeFixturesData, setActiveFixturesData] = useState([]);

  useEffect(() => {
    if (fixturesData != null && graphicData?.clubs != null) {
      const fixtureList = fixturesData?.data?.commentaries?.tournament?.match;

      const updatedActiveFixturesData = fixtureList?.map((fixture) => {
        const matchingHomeClub = graphicData.clubs.find(
          (item) => item.id === fixture.localteam["@id"]
        );
        const matchingVisitorClub = graphicData.clubs.find(
          (item) => item.id === fixture.visitorteam["@id"]
        );

        const localTeamName = fixture.localteam["@name"];
        const visitorTeamName = fixture.visitorteam["@name"];
        const updatedLocalTeamName =
          localTeamName === "Ajax Cape Town"
            ? "Cape Town Spurs"
            : localTeamName;
        const updatedVisitorTeamName =
          visitorTeamName === "Ajax Cape Town"
            ? "Cape Town Spurs"
            : visitorTeamName;

        return {
          time: fixture["@time"],
          status: fixture["@status"],
          localTeam: {
            name: updatedLocalTeamName,
            id: fixture.localteam["@id"],
            score: fixture.localteam["@goals"],
            flag: matchingHomeClub ? matchingHomeClub.flag : null,
          },
          visitorTeam: {
            name: updatedVisitorTeamName,
            id: fixture.visitorteam["@id"],
            score: fixture.visitorteam["@goals"],
            flag: matchingVisitorClub ? matchingVisitorClub.flag : null,
          },
        };
      });

      setActiveFixturesData({
        date: fixtureList?.[0]["@formatted_date"],
        matches: updatedActiveFixturesData,
      });
    }
  }, [fixturesData, graphicData]);

  const [displayedFixtures, setDisplayedFixtures] = useState([]);

  useEffect(() => {
    if (activeFixturesData?.matches?.length > 0) {
      // Sort matches by time
      const sortedMatches = activeFixturesData.matches.sort((a, b) =>
        a.time.localeCompare(b.time)
      );

      const separatedMatchesByTime = sortedMatches.reduce((acc, fixture) => {
        const existingGroup = acc.find((group) => group.time === fixture.time);

        if (existingGroup) {
          existingGroup.matches.push(fixture);
        } else {
          acc.push({ time: fixture.time, matches: [fixture] });
        }

        return acc;
      }, []);

      setDisplayedFixtures(separatedMatchesByTime);
    }
  }, [activeFixturesData.matches]);

  console.log(displayedFixtures);

  // Download Image
  const imageFilename = "In Game Updates.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [activeFixture]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  if (isFixturesListDataPending) {
    return <CustomSpinner />;
  }

  return (
    <>
      {fixturesData && graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h3">
                Live Fixtures
              </Heading>
              {/* <Flex mt={8} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Team Name"
                  name="id"
                  onChange={handleInputChange}
                  value={activeFixture.id}
                > */}
              {/* {activeFixtures && Array.isArray(activeFixtures) ? (
                    activeFixtures.map((dailyFixture, index) => (
                      <option key={index} value={dailyFixture["@id"]}>
                        {dailyFixture?.localteam?.["@name"]} -{" "}
                        {dailyFixture?.visitorteam?.["@name"]}
                      </option>
                    ))
                  ) : (
                    <option value={activeFixtures["@id"]}>
                      {activeFixtures.localteam &&
                        activeFixtures.localteam["@name"]}
                    </option>
                  )} */}
              {/* </Select> */}
              {/* </Flex> */}
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              <LiveFixturesArtwork
                displayedFixtures={displayedFixtures}
                activeFixturesData={activeFixturesData.date}
              />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default LiveFixtures;
