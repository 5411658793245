import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Textarea,
} from "@chakra-ui/react";
import TeamProfileArtwork from "../components/TeamProfileArtwork";
import TeamProfileArtworkSl from "../components/kickOff/TeamProfileArtworkSl";
import React, { useEffect, useState } from "react";
import useFetch from "../../../../actions/useFetch";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
import useUrlState from "@ahooksjs/use-url-state";
import axios from "axios";
import { useQuery } from "react-query";
import CustomSpinner from "../../components/CustomSpinner";

// const fetchLeagueData = () => {
//   return axios.get(
//     "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/standings/1394.xml?json=1"
//   );
// };

const fetchLeagueData = (leagueId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/standings/${leagueId}.xml?json=1`
  );
};

const fetchTeamData = (teamId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerstats/team/${teamId}?json=1`
  );
};

const TeamProfile = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  //   Define and get inputs
  const [teamProfileInput, setTeamProfileInput] = useUrlState({
    leagueId: "",
    teamId: "",
    playerImage: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTeamProfileInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const {
    data: leagueData,
    isFetching: isFetchingLeagueData,
    error: teamDataError,
    refetch: refetchLeagueData,
  } = useQuery("leagueData", () => fetchLeagueData(teamProfileInput.leagueId));

  const {
    data: clubData,
    isFetching: isFetchingClubData,
    error: teamDatadDataError,
    refetch: refetchTeamData,
  } = useQuery("teamData", () => fetchTeamData(teamProfileInput.teamId));

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("soccer");

  // Set Team List
  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    if (teamProfileInput.leagueId != "")
      setTeamList(leagueData?.data?.standings?.tournament?.team);
  }, [leagueData, teamProfileInput.leagueId]);

  // Fetch League Data
  useEffect(() => {
    if (teamProfileInput.leagueId !== "") {
      refetchLeagueData();
    }
  }, [teamProfileInput.leagueId, refetchLeagueData]);

  // Fetch Single Team Data
  useEffect(() => {
    if (teamProfileInput.teamId !== "") {
      refetchTeamData();
    }
  }, [teamProfileInput.teamId, refetchTeamData]);

  // Define and get active team data
  const [activeClubData, setActivelubData] = useState(null);
  useEffect(() => {
    if (clubData) {
      setActivelubData(clubData.data?.teams?.team);
    }
  }, [clubData]);

  // Define Active Club Stats
  const [activeClubStats, setActiveClubStats] = useState({
    name: "",
    yearFounded: "",
    coach: "",
    flag: "",
    trophies: {
      dstvPrem: "",
      mtn8: "",
      nedbankCup: "",
      CafChamps: "",
    },
    playerImage: "",
    stats: {
      cleanSheets: "",
      failedToScore: "",
      avgGoalsScored: "",
      avgGoalsConceded: "",
      biggestDefeat: "",
      biggestVictory: "",
      scoringMinutes: [""],
    },
  });

  useEffect(() => {
    if (activeClubData != null) {
      setActiveClubStats((prevStats) => ({
        ...prevStats,
        league: teamProfileInput?.leagueId,
        name: activeClubData?.name,
        yearFounded: activeClubData?.founded,
        coach: activeClubData?.coach?.["@name"],
        stats: {
          cleanSheets: activeClubData?.statistics?.clean_sheet?.["@total"],
          failedToScore:
            activeClubData?.statistics?.failed_to_score?.["@total"],
          avgGoalsScored:
            activeClubData?.statistics?.avg_goals_per_game_scored?.["@total"],
          avgGoalsConceded:
            activeClubData?.statistics?.avg_goals_per_game_conceded?.["@total"],
          biggestVictory:
            activeClubData?.statistics?.scoring_minutes?.["@total"],
          scoringMinutes: activeClubData?.statistics?.scoring_minutes?.period,
        },
      }));

      if (activeClubData?.trophies?.trophy.length > 0) {
        let leagueWins = null;
        let mtn8Wins = null;
        let NedbankWins = null;
        let cafChampWins = null;

        leagueWins = activeClubData?.trophies?.trophy.find(
          (trophy) =>
            (trophy?.["@league"] === "PSL" &&
              trophy?.["@status"] === "Winner") ||
            (trophy?.["@league"] === "Premier League" &&
              trophy?.["@status"] === "Winner")
        );
        mtn8Wins = activeClubData?.trophies?.trophy.find(
          (trophy) =>
            trophy?.["@league"] === "8 Cup" && trophy?.["@status"] === "Winner"
        );
        NedbankWins = activeClubData?.trophies?.trophy.find(
          (trophy) =>
            trophy?.["@league"] === "Cup" && trophy?.["@status"] === "Winner"
        );
        cafChampWins = activeClubData?.trophies?.trophy.find(
          (trophy) =>
            trophy?.["@league"] === "CAF Champions League" &&
            trophy?.["@status"] === "Winner"
        );

        if (leagueWins || mtn8Wins || NedbankWins || cafChampWins !== null) {
          setActiveClubStats((prevStats) => ({
            ...prevStats,
            trophies: {
              dstvPrem: leagueWins,
              mtn8: mtn8Wins,
              nedbankCup: NedbankWins,
              CafChamps: cafChampWins,
            },
          }));
        }
      }
    }
  }, [activeClubData, teamProfileInput.teamId, teamProfileInput?.leagueId]);

  console.log(activeClubStats);

  // Set and define graphic data
  const [playerList, setPlayerList] = useState([""]);
  useEffect(() => {
    if (activeClubData != null) {
      const matchingClub = graphicData?.[
        teamProfileInput.leagueId
      ]?.clubs?.find((item) => item.id === teamProfileInput.teamId);

      if (matchingClub != null) {
        setPlayerList(matchingClub.images);
      }
    }
  }, [activeClubData, teamProfileInput.teamId, teamProfileInput.leagueId]);

  useEffect(() => {
    if (activeClubData != null) {
      const matchingClub = graphicData?.[
        teamProfileInput.leagueId
      ]?.clubs?.find((item) => item.id === teamProfileInput.teamId);

      if (matchingClub != null) {
        setActiveClubStats((prevStats) => ({
          ...prevStats,
          flag: matchingClub.flag,
        }));
      }
    }
  }, [activeClubData, teamProfileInput.teamId]);

  useEffect(() => {
    if (
      teamProfileInput.playerImage != "" &&
      activeClubStats.name != "" &&
      graphicData != null
    ) {
      setActiveClubStats((prevStats) => ({
        ...prevStats,
        playerImage: teamProfileInput.playerImage,
      }));
    }
  }, [teamProfileInput, activeClubStats.name]);

  // Download Image
  const imageFilename = "Team Profile.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [activeClubStats]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  const handleReset = () => {
    setTeamProfileInput({
      leagueId: "",
      teamId: "",
      playerImage: "",
    });

    // Reset activeClubStats to the initial state
    setActiveClubStats({
      name: "",
      yearFounded: "",
      coach: "",
      flag: "",
      trophies: {
        dstvPrem: "",
        mtn8: "",
        nedbankCup: "",
        CafChamps: "",
      },
      playerImage: "",
      stats: {
        cleanSheets: "",
        failedToScore: "",
        avgGoalsScored: "",
        avgGoalsConceded: "",
        biggestDefeat: "",
        biggestVictory: "",
        scoringMinutes: [""],
      },
    });
  };

  if (isFetchingLeagueData || isGraphicDataPending || isFetchingClubData) {
    return <CustomSpinner />;
  }

  return (
    <>
      {graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h2">
                Team Profile
              </Heading>
              <Flex mt={8} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="League"
                  name="leagueId"
                  onChange={handleInputChange}
                  value={teamProfileInput.leagueId}
                >
                  <option value="1204">English Premier League</option>
                  <option value="1394">DStv Prem</option>
                </Select>
              </Flex>
              <Flex mt={3} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Team Name"
                  name="teamId"
                  onChange={handleInputChange}
                  value={teamProfileInput.teamId}
                >
                  {teamList != undefined && teamList?.length > 0
                    ? teamList.map((team, index) => (
                        <option key={index} value={team["@id"]}>
                          {team["@name"]}
                        </option>
                      ))
                    : "Choose a League First"}
                </Select>
              </Flex>
              <Flex mt={3} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Player Image"
                  name="playerImage"
                  onChange={handleInputChange}
                  value={teamProfileInput.playerImage}
                >
                  {playerList != undefined && playerList?.length > 0
                    ? playerList.map((player, index) => (
                        <option key={index} value={player.image}>
                          {player.name}
                        </option>
                      ))
                    : "Choose a team first"}
                </Select>
              </Flex>

              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>

              <Button variant="outlineButton" onClick={handleReset}>
                Reset
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              {/* <TeamProfileArtwork activeClubStats={activeClubStats} /> */}
              <TeamProfileArtworkSl activeClubStats={activeClubStats} />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default TeamProfile;
