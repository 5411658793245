import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// Animation
import { useInView } from "react-intersection-observer";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

const IndustriesServiced = () => {
  const industriesServiced = [
    "Live Sports",
    "News Networks",
    "Broadcast",
    "Events",
  ];

  // Framer Motion
  const MotionBox = motion(Box);
  const MotionText = motion(Text);
  const MotionHeading = motion(Heading);

  const textVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 1, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 1, duration: 1 },
    },
  };

  const industriesContainterVariants = {
    hidden: {
      opacity: 0,
      y: -20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1,
        duration: 1,
        when: "beforeChildren",
        staggerChildren: 0.4,
      },
    },
    exit: {
      opacity: 0,
      transition: { ease: "easeInOut" },
    },
  };

  const industriesVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    exit: {
      opacity: 0,

      transition: { delay: 1, duration: 1 },
    },
  };

  // TypeWriter Anmimation
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [typewriterStarted, setTypewriterStarted] = useState(false);

  useEffect(() => {
    if (inView && !typewriterStarted) {
      const timeoutId = setTimeout(() => {
        setTypewriterStarted(true);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inView, typewriterStarted]);

  return (
    <>
      <Box margin="4rem 2rem" ref={ref}>
        <Heading
          variant="h2"
          textAlign="center"
          height={{ base: "130px", lg: "62px" }}
        >
          {typewriterStarted && (
            <Typewriter
              options={{
                strings: "Industries Serviced",
                autoStart: true,
                delay: 30,
                pauseFor: 1000000,
                cursor: "",
              }}
            />
          )}
        </Heading>
        <MotionText
          variant="p"
          as="p"
          textAlign="center"
          paddingBottom="1rem"
          margin="auto"
          variants={textVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          RHESTSub strives to increase your brand's commercial revenue and brand
          equity.
        </MotionText>

        <Flex
          justifyContent=" space-between"
          flexDir={{ base: "column", lg: "row" }}
        >
          {industriesServiced.map((service, index) => (
            <MotionBox
              key={index}
              width={{ base: "inherit", lg: "400px" }}
              margin={{ base: "1rem 2rem", lg: "0" }}
              variants={industriesContainterVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <MotionHeading
                variants={industriesVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                variant="h4"
                marginBottom="0.5rem"
                textAlign="center"
              >
                {service}
              </MotionHeading>
            </MotionBox>
          ))}
        </Flex>
      </Box>
    </>
  );
};

export default IndustriesServiced;
