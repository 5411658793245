import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Textarea,
} from "@chakra-ui/react";
import InterviewArtwork from "../../womensWorldCup/components/InterviewArtwork";
import React, { useEffect, useState } from "react";
import useFetch from "../../../../actions/useFetch";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
import useUrlState from "@ahooksjs/use-url-state";
import { removeTrailingW } from "../../../../helpers/removeTrailingW";

const Interview = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  //   Fetch Data
  const {
    data: fixturesData,
    isPending: isFixturesListDataPending,
    error: fixturesListError,
  } = useFetch(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerfixtures/leagueid/1394?json=1"
  );
  // console.log(
  //   "Fixtures Data:",
  //   fixturesData,
  //   "Pending status",
  //   isFixturesListDataPending,
  //   "error",
  //   fixturesListError
  // );
  const {
    data: squadData,
    isPending: isSquadDataPending,
    error: squadDataError,
  } = useFetch(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerleague/1394?json=1"
  );
  // console.log(
  //   "Squad Data:",
  //   squadData,
  //   "Pending status",
  //   isSquadDataPending,
  //   "error",
  //   squadDataError
  // );

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("psl");
  // console.log(
  //   "Graphic Data:",
  //   graphicData,
  //   "Graphic Data Pending status",
  //   isGraphicDataPending,
  //   "Graphic Data error",
  //   graphicDataError
  // );

  const { data: leagueList } = useFetch(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/1643.xml?json=1"
  );

  console.log("league list", leagueList);

  //   Define and get inputs
  const [interviewInput, setInterviewInput] = useUrlState({
    fixture: "",
    intervieweeTeam: "",
    intervieweeName: "",
    intervieweeComments: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInterviewInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  // Define active fixture

  const [activeFixtures, setActiveFixtures] = useState([""]);

  useEffect(() => {
    setActiveFixtures(fixturesData?.results?.tournament?.week?.[1].match);
  }, [fixturesData]);

  console.log("active fixtures", activeFixtures);

  const [activeFixture, setActiveFixture] = useState("");

  // useEffect(() => {
  //   const matchingFixture = activeFixtures?.find(
  //     (item) => item["@id"] === interviewInput?.fixture
  //   );
  //   if (matchingFixture != null) {
  //     setActiveFixture(matchingFixture);
  //   }
  // }, [activeFixtures, interviewInput]);

  // // Define team list
  // const [teamList, setTeamList] = useState([]);

  // useEffect(() => {
  //   setTeamList([
  //     {
  //       name: activeFixture?.localteam?.["@name"],
  //       id: activeFixture?.localteam?.["@id"],
  //     },
  //     {
  //       name: activeFixture?.visitorteam?.["@name"],
  //       id: activeFixture?.visitorteam?.["@id"],
  //     },
  //   ]);
  // }, [activeFixture]);

  // // Set Squad List
  // const [activeSquad, setActiveSquad] = useState([]);

  // useEffect(() => {
  //   const matchingSquad = squadData?.league?.team.find(
  //     (item) => item["@id"] == interviewInput.intervieweeTeam
  //   );

  //   setActiveSquad(matchingSquad?.squad?.player);
  // }, [squadData, interviewInput.intervieweeTeam]);
  // console.log("active squad", activeSquad);

  // useEffect(() => {
  //   if (interviewInput.intervieweeTeam == activeFixture?.localteam?.["@id"]) {
  //     setActiveSquad((prevSquad) => ({
  //       ...prevSquad,
  //       {@id: }
  //     }))
  //   }
  // }, [interviewInput.intervieweeTeam, activeFixture]);

  // console.log("interview input", interviewInput);

  // console.log("interview input", interviewInput);

  return (
    <>
      {fixturesData && graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Flex mt={8} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Fixture"
                  name="fixture"
                  onChange={handleInputChange}
                  value={interviewInput.fixture}
                >
                  {activeFixtures != "" && activeFixtures?.length > 0 && (
                    <select>
                      {activeFixtures.map((fixture, index) => (
                        <option key={index} value={fixture["@id"]}>
                          {fixture.localteam &&
                            removeTrailingW(fixture.localteam["@name"])}{" "}
                          -{" "}
                          {fixture.visitorteam &&
                            removeTrailingW(fixture.visitorteam["@name"])}
                        </option>
                      ))}
                    </select>
                  )}
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Interviewee Team"
                  name="intervieweeTeam"
                  onChange={handleInputChange}
                  value={interviewInput.intervieweeTeam}
                >
                  {/* {teamList &&
                    teamList.map((team, index) => (
                      <option key={index} value={team.id}>
                        {team.name}
                      </option>
                    ))} */}
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Interviewee Name"
                  name="intervieweeName"
                  onChange={handleInputChange}
                  value={interviewInput.intervieweeName}
                >
                  {/* {activeSquad && activeSquad.length > 0 ? (
                    activeSquad.map((player, index) => (
                      <option key={index} value={player["@name"]}>
                        {player["@name"]}
                      </option>
                    ))
                  ) : (
                    <option value="">Select Team First</option>
                  )} */}
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <Textarea
                  variant="outlineTextArea"
                  width="100%"
                  placeholder="Interviewee Comments"
                  name="intervieweeComments"
                  onChange={handleInputChange}
                  value={interviewInput.intervieweeComments}
                />
              </Flex>
              <Button
                // isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                // onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              <InterviewArtwork />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default Interview;
