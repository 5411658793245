import React, { useEffect, useState } from "react";
import { Link, Link as ReachLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
} from "@chakra-ui/react";
import useFetch from "../../../../actions/useFetch";
import useSoccerExternalDataContext from "../../../../actions/useSoccerExternalDataContext";
import useUrlState from "@ahooksjs/use-url-state";

// Helpers
import { removeTrailingW } from "../../../../helpers/removeTrailingW";
import FixturesArtwork from "../components/FixturesArtwork";
import useFetchFirebase from "../../../../actions/useFetchFirebase";

const Fixtures = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const [squadData, setSquadData] = useUrlState({
    id: "",
    image: "",
  });

  const handleSquadInputChange = (e) => {
    const { name, value } = e.target;

    setSquadData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  // Fetch Data
  const {
    data: leagueData,
    isPending: isLeagueDataPending,
    error: leagueDataError,
  } = useFetch(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerleague/1042?json=1"
  );

  // console.log(
  //   "League Data:",
  //   leagueData,
  //   "Pending status",
  //   isLeagueDataPending,
  //   "error",
  //   leagueDataError
  // );

  const {
    data: fixturesList,
    isPending: isFixturesListDataPending,
    error: fixturesListError,
  } = useFetch(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerfixtures/leagueid/1042?json=1"
  );
  // console.log(
  //   "Fixture Data:",
  //   fixturesList,
  //   "Pending status",
  //   isFixturesListDataPending,
  //   "error",
  //   fixturesListError
  // );

  const [clubList, setClubList] = useState(null);

  useEffect(() => {
    if (leagueData != null) {
      setClubList(leagueData.league.team);
    }
  }, [leagueData]);

  // Define and set Active Team
  const [activeTeam, setActiveTeam] = useState({
    name: "",
    id: "",
    flag: "",
    group: "",
    image: "",
  });

  useEffect(() => {
    if (squadData.id && clubList != null) {
      const matchingSquad = clubList.find(
        (item) => item["@id"] == squadData.id
      );

      if (matchingSquad !== undefined) {
        setActiveTeam((prevStats) => ({
          ...prevStats,
          name: matchingSquad["@name"],
          id: squadData.id,
          image: squadData.image,
        }));
      }
    }
  }, [squadData, clubList]);

  // Set Home Graphic Team
  // const {
  //   data: graphicData,
  //   isPending: isGraphicDataPending,
  //   error: graphicDataError,
  // } = useFetchFirebase("worldCupWomen");
  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("worldCupWomen");
  // console.log(
  //   "Graphic Data:",
  //   graphicData,
  //   "Graphic Data Pending status",
  //   isGraphicDataPending,
  //   "Graphic Data error",
  //   graphicDataError
  // );

  // Set Active Team
  useEffect(() => {
    if (graphicData !== null) {
      const matchingHomeGraphics = graphicData?.clubs?.find(
        (item) => item.id == activeTeam.id
      );

      console.log(matchingHomeGraphics);
      if (matchingHomeGraphics != null) {
        setActiveTeam((prevStats) => ({
          ...prevStats,
          group: matchingHomeGraphics.group,
        }));
      }
    }
  }, [graphicData, activeTeam.id]);

  // Define Active Fixtures
  const [activeFixtures, setActiveFixtures] = useState({
    weekOne: {
      homeTeam: null,
      homeTeamId: null,
      visitorTeam: null,
      visitorTeamId: null,
      homeTeamFlag: null,
      visitorTeamFlag: null,
      homeTeamScore: null,
      visitorTeamScore: null,
      date: null,
      time: null,
      stadiumName: null,
    },
    weekTwo: {
      homeTeam: null,
      homeTeamId: null,
      visitorTeam: null,
      visitorTeamId: null,
      homeTeamFlag: null,
      visitorTeamFlag: null,
      homeTeamScore: null,
      visitorTeamScore: null,
      date: null,
      time: null,
      stadiumName: null,
    },
    weekThree: {
      homeTeam: null,
      homeTeamId: null,
      visitorTeam: null,
      visitorTeamId: null,
      homeTeamFlag: null,
      visitorTeamFlag: null,
      homeTeamScore: null,
      visitorTeamScore: null,
      date: null,
      time: null,
      stadiumName: null,
    },
  });

  // Set Week One
  useEffect(() => {
    if (activeTeam && fixturesList != null) {
      const weekOneFixtures =
        fixturesList?.results?.tournament?.stage?.[5]?.week?.[0].match;

      const matchingWeekOneFixture = weekOneFixtures?.find(
        (item) =>
          item.localteam["@id"] == activeTeam.id ||
          item.visitorteam["@id"] == activeTeam.id
      );

      if (matchingWeekOneFixture !== undefined) {
        setActiveFixtures((prevStats) => ({
          ...prevStats,
          weekOne: {
            homeTeam: matchingWeekOneFixture.localteam["@name"],
            homeTeamId: matchingWeekOneFixture.localteam["@id"],
            visitorTeam: matchingWeekOneFixture.visitorteam["@name"],
            visitorTeamId: matchingWeekOneFixture.visitorteam["@id"],
            homeTeamScore: matchingWeekOneFixture.localteam["@score"],
            visitorTeamScore: matchingWeekOneFixture.visitorteam["@score"],
            stadiumName: matchingWeekOneFixture["@venue"],
            stadiumCity: matchingWeekOneFixture["@venue_city"],
            date: matchingWeekOneFixture["@date"],
            time: matchingWeekOneFixture["@time"],
            homeTeamFlag: null,
            visitorTeamFlag: null,
          },
        }));
      }
    }
  }, [activeTeam, fixturesList]);

  useEffect(() => {
    if (graphicData.clubs && activeTeam && fixturesList != null) {
      const weekOneFixtures =
        fixturesList?.results?.tournament?.stage?.[5].week[0].match;

      const matchingWeekOneFixture = weekOneFixtures.find(
        (item) =>
          item.localteam["@id"] == activeTeam.id ||
          item.visitorteam["@id"] == activeTeam.id
      );

      const matchingHomeGraphics = graphicData.clubs.find(
        (item) => item.id == matchingWeekOneFixture?.localteam["@id"]
      );

      const matchingVisitorGraphics = graphicData.clubs.find(
        (item) => item.id == matchingWeekOneFixture?.visitorteam["@id"]
      );

      if (matchingHomeGraphics !== undefined) {
        setActiveFixtures((prevStats) => ({
          ...prevStats,
          weekOne: {
            ...prevStats.weekOne,
            homeTeamFlag: matchingHomeGraphics.flag,
            visitorTeamFlag: matchingVisitorGraphics.flag,
          },
        }));
      }
    }
  }, [activeTeam, graphicData]);

  // Set Week Two
  useEffect(() => {
    if (activeTeam && fixturesList != null) {
      const weekTwoFixtures =
        fixturesList?.results?.tournament?.stage?.[5]?.week[1]?.match;

      const matchingWeekTwoFixture = weekTwoFixtures?.find(
        (item) =>
          item.localteam["@id"] == activeTeam.id ||
          item.visitorteam["@id"] == activeTeam.id
      );

      if (matchingWeekTwoFixture !== undefined) {
        setActiveFixtures((prevStats) => ({
          ...prevStats,
          weekTwo: {
            homeTeam: matchingWeekTwoFixture.localteam["@name"],
            homeTeamId: matchingWeekTwoFixture.localteam["@id"],
            visitorTeam: matchingWeekTwoFixture.visitorteam["@name"],
            visitorTeamId: matchingWeekTwoFixture.visitorteam["@id"],
            homeTeamScore: matchingWeekTwoFixture.localteam["@score"],
            visitorTeamScore: matchingWeekTwoFixture.visitorteam["@score"],
            stadiumName: matchingWeekTwoFixture["@venue"],
            stadiumCity: matchingWeekTwoFixture["@venue_city"],
            date: matchingWeekTwoFixture["@date"],
            time: matchingWeekTwoFixture["@time"],
            homeTeamFlag: null,
            visitorTeamFlag: null,
          },
        }));
      }
    }
  }, [activeTeam, fixturesList]);

  // Week Two Graphics
  useEffect(() => {
    if (graphicData.clubs && activeTeam && fixturesList != null) {
      const weekTwoFixtures =
        fixturesList?.results?.tournament?.stage?.[5].week[1].match;

      const matchingWeekTwoFixture = weekTwoFixtures.find(
        (item) =>
          item.localteam["@id"] == activeTeam.id ||
          item.visitorteam["@id"] == activeTeam.id
      );

      const matchingHomeGraphics = graphicData.clubs.find(
        (item) => item.id == matchingWeekTwoFixture?.localteam["@id"]
      );

      const matchingVisitorGraphics = graphicData.clubs.find(
        (item) => item.id == matchingWeekTwoFixture?.visitorteam["@id"]
      );

      if (matchingHomeGraphics !== undefined) {
        setActiveFixtures((prevStats) => ({
          ...prevStats,
          weekTwo: {
            ...prevStats.weekTwo,
            homeTeamFlag: matchingHomeGraphics.flag,
            visitorTeamFlag: matchingVisitorGraphics.flag,
          },
        }));
      }
    }
  }, [activeTeam, graphicData]);

  // Set Week Three
  useEffect(() => {
    if (activeTeam && fixturesList != null) {
      const weekThreeFixtures =
        fixturesList?.results?.tournament?.stage?.[5]?.week?.[2].match;

      const matchingWeekThreeFixture = weekThreeFixtures?.find(
        (item) =>
          item.localteam["@id"] == activeTeam.id ||
          item.visitorteam["@id"] == activeTeam.id
      );

      if (matchingWeekThreeFixture !== undefined) {
        setActiveFixtures((prevStats) => ({
          ...prevStats,
          weekThree: {
            homeTeam: matchingWeekThreeFixture.localteam["@name"],
            homeTeamId: matchingWeekThreeFixture.localteam["@id"],
            visitorTeam: matchingWeekThreeFixture.visitorteam["@name"],
            visitorTeamId: matchingWeekThreeFixture.visitorteam["@id"],
            homeTeamScore: matchingWeekThreeFixture.localteam["@score"],
            visitorTeamScore: matchingWeekThreeFixture.visitorteam["@score"],
            stadiumName: matchingWeekThreeFixture["@venue"],
            stadiumCity: matchingWeekThreeFixture["@venue_city"],
            date: matchingWeekThreeFixture["@date"],
            time: matchingWeekThreeFixture["@time"],
            homeTeamFlag: null,
            visitorTeamFlag: null,
          },
        }));
      }
    }
  }, [activeTeam, fixturesList]);

  // Week Three Graphics
  useEffect(() => {
    if (graphicData.clubs && activeTeam && fixturesList != null) {
      const weekThreeFixtures =
        fixturesList?.results?.tournament?.stage?.[5].week[2].match;

      const matchingWeekThreeFixture = weekThreeFixtures.find(
        (item) =>
          item.localteam["@id"] == activeTeam.id ||
          item.visitorteam["@id"] == activeTeam.id
      );

      const matchingHomeGraphics = graphicData.clubs.find(
        (item) => item.id == matchingWeekThreeFixture?.localteam["@id"]
      );

      const matchingVisitorGraphics = graphicData.clubs.find(
        (item) => item.id == matchingWeekThreeFixture?.visitorteam["@id"]
      );

      if (matchingHomeGraphics !== undefined) {
        setActiveFixtures((prevStats) => ({
          ...prevStats,
          weekThree: {
            ...prevStats.weekThree,
            homeTeamFlag: matchingHomeGraphics.flag,
            visitorTeamFlag: matchingVisitorGraphics.flag,
          },
        }));
      }
    }
  }, [activeTeam, graphicData]);

  // Define Images
  const [imageList, setImageList] = useState("");

  useEffect(() => {
    if (squadData.id != "" && graphicData != null) {
      const matchingClub = graphicData?.clubs?.find(
        (item) => item.id == squadData.id
      );
      setImageList(matchingClub?.images);
    }
  }, [graphicData, squadData.id]);

  // Download Image
  const imageFilename = "Fixtures.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [squadData]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  return (
    <>
      {leagueData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h4">
                Fixtures
              </Heading>
              <Flex mt={8} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Team Name"
                  name="id"
                  onChange={handleSquadInputChange}
                  value={squadData.id}
                >
                  {clubList &&
                    clubList.map((club, index) => (
                      <option key={index} value={club["@id"]}>
                        {removeTrailingW(club["@name"])}
                      </option>
                    ))}
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Image"
                  name="image"
                  onChange={handleSquadInputChange}
                  value={squadData.image}
                >
                  {imageList ? (
                    imageList.map((image, index) => (
                      <option key={index} value={image.image}>
                        {image.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Select team first</option>
                  )}
                </Select>
              </Flex>
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem>
              <FixturesArtwork
                activeTeam={activeTeam}
                activeFixtures={activeFixtures}
              />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default Fixtures;
