import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import RhestsubLogo from "../../../assets/RHESTSub_Logo.svg";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  SlSocialLinkedin,
  SlSocialTwitter,
  SlSocialInstagram,
} from "react-icons/sl";

// Animations
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import { useInView } from "react-intersection-observer";

const Footer = () => {
  const linkStyling = {
    _hover: { color: "#e5a428" },
  };

  const emails = [
    "info@rhestsub.com",
    "support@rhestsub.com",
    "accounts@rhestsub.com",
  ];

  const menu = [
    {
      link: "/",
      name: "Home",
    },
    {
      link: "/about",
      name: "About",
    },
    {
      link: "/how-it-works",
      name: "How It Works",
    },
    {
      link: "/contact",
      name: "Contact",
    },
  ];

  // Framer Motion
  const MotionBox = motion(Box);
  const MotionImage = motion(Image);
  const MotionText = motion(Text);

  const borderVariants = {
    hidden: {
      width: 0,
    },
    visible: {
      width: "100%",
      transition: { delay: 0.25, duration: 1 },
    },
  };

  const logovariant = {
    hidden: {
      y: -10,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.5, duration: 1 },
    },
  };

  const copyrightVariant = {
    hidden: {
      y: 10,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.5, duration: 1 },
    },
  };

  // TypeWriter Anmimation

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [typewriterStarted, setTypewriterStarted] = useState(false);

  useEffect(() => {
    if (inView && !typewriterStarted) {
      setTypewriterStarted(true);
    }
  }, [inView, typewriterStarted]);

  return (
    <Box className="footer" width="100%" ref={ref}>
      <MotionBox
        variants={borderVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        borderBottom="1px solid #e5a329"
      />
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        gap={{ base: "2rem", lg: "0" }}
        justifyContent="space-between"
        padding="2rem"
      >
        <Box>
          <MotionImage
            variants={logovariant}
            initial="hidden"
            animate="visible"
            exit="exit"
            src={RhestsubLogo}
            width="150px"
            paddingBottom="1rem"
          />
          <MotionText
            variants={copyrightVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
            variant="p"
          >
            All rights reserved <br />
            &copy; {new Date().getFullYear()}
          </MotionText>
        </Box>

        <Box>
          <Heading variant="h5" paddingBottom="1rem" height="45px">
            <Typewriter
              options={{
                strings: "Menu",
                autoStart: typewriterStarted,
                delay: 75,
                pauseFor: 1000000,
                cursor: "",
              }}
            />
          </Heading>
          <MotionBox
            variants={copyrightVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {menu.map((menu, index) => (
              <Link to={menu.link} key={index}>
                <Text sx={linkStyling} variant="p">
                  {menu.name}
                </Text>
              </Link>
            ))}
          </MotionBox>
        </Box>
        <Box>
          <Heading variant="h5" paddingBottom="1rem" height="45px">
            <Typewriter
              options={{
                strings: "Contact",
                autoStart: typewriterStarted,
                delay: 75,
                pauseFor: 1000000,
                cursor: "",
              }}
            />
          </Heading>
          <MotionBox
            variants={copyrightVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {emails.map((email, index) => (
              <Link to={`mailto:${email}`} key={index}>
                <Text sx={linkStyling} variant="p">
                  {email}
                </Text>
              </Link>
            ))}

            <Flex margin="1rem 0" gap=" 4rem">
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/rhestsub/"
              >
                <SlSocialLinkedin className="contact-icon__footer" />
              </Link>

              <Link target="_blank" to="https://twitter.com/_rhestsub">
                <SlSocialTwitter className="contact-icon__footer" />
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/rhestsub/"
                _hover={{ color: "pink" }}
              >
                <SlSocialInstagram className="contact-icon__footer" />
              </Link>
            </Flex>
          </MotionBox>
        </Box>
        <Box>
          <Heading variant="h5" paddingBottom="1rem">
            <Typewriter
              options={{
                strings: "Legal",
                autoStart: typewriterStarted,
                delay: 75,
                pauseFor: 1000000,
                cursor: "",
              }}
            />
          </Heading>
          <MotionBox
            variants={copyrightVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Link target="_blank" to="https://twitter.com/_rhestsub">
              <Text sx={linkStyling} variant="p">
                Privacy Policy
              </Text>
            </Link>
            <Link target="_blank" to="https://twitter.com/_rhestsub">
              <Text sx={linkStyling} variant="p">
                Cookie Policy
              </Text>
            </Link>
          </MotionBox>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
