import { DashboardRouter, WebsiteRouter } from "../AppRouter";

export const APPS = [
  {
    subdomain: "www",
    app: WebsiteRouter,
    main: true,
  },
  {
    subdomain: "dashboard",
    app: DashboardRouter,
    main: false,
  },
];
