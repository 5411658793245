import { 
  BrowserRouter,
  Routes,  
  Route, 
  } from 'react-router-dom';
import './App.css';
import './style/index.css'
import { createBrowserHistory } from "history";
import { getApp } from './helpers/RouterHelper';
// Layouts and pages


import Sidebar from './components/Sidebar';
import ConditionalSidebar from './layouts/ConditionalSidebar';


import SquadList from './features/sports/womensWorldCup/pages/squadList';
import Fixtures from './features/sports/womensWorldCup/pages/Fixtures';

// Pages
import Account from './features/users/pages/Account';

// Leagues
import LeagueFixtures from './features/sports/psl/pages/LeagueFixtures';
import HeadToHead from './features/sports/psl/pages/HeadToHead';
import LeagueInGameUpates from './features/sports/psl/pages/LeagueInGameUpdates';
import LeagueStartingEleven from "./features/sports/psl/pages/LeagueStartingEleven";
import LeagueTeamMatchStats from './features/sports/psl/pages/LeagueTeamMatchStats';
import LeaguePlayerMatchStats from './features/sports/psl/pages/LeaguePlayerMatchStats';
import Interview from './features/sports/psl/pages/Interview';
import TeamProfile from './features/sports/psl/pages/TeamProfile';
import LeagueStandings from './features/sports/psl/pages/LeagueStandings';


// Providers
import { SoccerProvider } from './actions/SoccerContext';
import { SoccerExternalDataProvider } from './actions/SoccerExternalDataContext';





const history = createBrowserHistory();

  function App() {
    const CurrentApp = getApp()
    
  return (
    

    <SoccerProvider>
    
      <BrowserRouter history={history}>
       
          {/* <ConditionalSidebar>
           <Sidebar/>
          </ConditionalSidebar> */}
          
          <CurrentApp/> 
          {/* <Routes>  
            
          
            <Route path='/account' element={<Account/>}/>
            <Route path='/squad-list' element={<SquadList/>}/>
       

            <Route path='/dstv-prem/interview' element={<Interview/>}/>
            <Route path='/dstv-prem/fixtures' element={<LeagueFixtures/>}/>
            <Route path='/dstv-prem/starting-11' element={<LeagueStartingEleven/>}/>
            <Route path='/dstv-prem/score-update' element={<LeagueInGameUpates/>}/>
            <Route path='/dstv-prem/match-stats' element={<LeagueTeamMatchStats/>}/>
            <Route path='/dstv-prem/player-stats' element={<LeaguePlayerMatchStats/>}/>
            <Route path='/dstv-prem/head-to-head' element={<HeadToHead/>}/>
            <Route path='/dstv-prem/team-profile' element={<TeamProfile/>}/>
            <Route path='/dstv-prem/standings' element={<LeagueStandings/>}/>
            <Route path='dstv-prem/fixtures' element={<Fixtures/>}/>
         
          </Routes>
      */}
      </BrowserRouter>
  
    </SoccerProvider>
    );
}

export default App;


