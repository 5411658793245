import {
  Box,
  Flex,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import PlaceHolderImage from "../../../../../assets/soccer/1204/squadImages/Manchester City/E. Haaland-Home2.png";
import PlaceHolderImageAssists from "../../../../../assets/soccer/1204/squadImages/Wolverhampton/P. Neto-Home.png";
import { formatPlayerName } from "../../../../../helpers/FormatPlayerName";

import KickoffLogo from "../../../../../assets/clients/soccerLaduma/Soccer Laduma Logo.png";

import { useLocation } from "react-router";
import HolyWoodBetsStrap from "../../../psl/components/UxComponents/HolyWoodBetsStrap";

const TopGoalAssistArtworkSl = ({ formattedTopGoalScorerData }) => {
  const statContainerHeading = {
    // maxWidth: "3.5rem",
    letterSpacing: "1px",
    paddingY: "0.4rem",
    paddingX: "0.25rem",
    color: "#fff",
    fontFamily: "Neue Power Bold",
    fontSize: "1rem",
    paddingY: "0",
    width: "25px",
  };

  const clubContainerHeading = {
    letterSpacing: "1px",
    paddingY: "0.4rem",
    color: "#fff",
    fontFamily: "Neue Power Bold",
    paddingLeft: "0.25rem",
    fontSize: "1rem",
    width: "25px",
  };

  const statPosContainer = {
    maxWidth: "3.5rem",
    letterSpacing: "1px",

    paddingX: "0.25rem",
    fontFamily: "Neue Power Bold",
    fontSize: "1rem",
    width: "23px",
    color: "#fff",
    paddingY: "0.4rem",
  };

  const clubContainer = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0",
    fontSize: "1rem",
    paddingY: "0",
    paddingLeft: "0.25rem",
    width: "180px",
    color: "#fff",
  };

  const flagContainer = {
    minWidth: "40px",
    paddingY: "0",
    paddingX: "0.25rem",
  };

  const flagStyling = {
    width: "30px",
  };

  const location = useLocation();

  return (
    <Box
      overflow="hidden"
      height="700px"
      width="700px"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="artwork-container"
      backgroundColor="#1b1b1b"
    >
      <Heading
        textTransform="uppercase"
        className="fixture-heading__kick-off"
        variant="h1"
        fontSize="6.75rem"
        fontFamily="Neue Power Ultra"
        margin="1rem 0 0 1.5rem"
        // textAlign="center"
        height="110px"
      >
        {location.pathname === "/soccer/top-assists" ? (
          <> Assists</>
        ) : (
          <> Scorers </>
        )}
      </Heading>
      <Flex
        className="artwork-header"
        justifyContent="space-between"
        alignItems="center"
        margin="-5.75rem 2rem 0 2rem"
      >
        <Box zIndex="1" className="title-container">
          <Heading
            variant="h5"
            fontFamily="Neue Power Ultra"
            textTransform="uppercase"
            lineHeight="1.5rem"
            fontWeight="800"
            color="#fff"
          >
            <Box color="#fff"> Top</Box>
            {location.pathname === "/soccer/top-assists" ? (
              <> Assists</>
            ) : (
              <> Goal Scorers </>
            )}
          </Heading>
        </Box>
        <Box>
          <Image
            src={require("../../../../../assets/clients/kickOff/KO Icon BW.png")}
            height="90px"
          />
        </Box>
      </Flex>

      <Box
        className="artwork-body"
        display="flex"
        height="470px"
        alignItems="center"
        paddingX="2rem"
      >
        <Box
          className="players"
          borderRadius="1rem"
          width="65%"
          border="1px solid #E"
          paddingX="0.5rem"
          marginTop="-1.5rem"
          zIndex="100"
        >
          <Table size="sm" variant="unstyled">
            <Thead
              borderBottom="1px solid #d2232a"
              width="1rem"
              fontFamily="Neue Power Bold"
            >
              <Th sx={statContainerHeading}></Th>
              <Th sx={clubContainerHeading}>Player</Th>
              <Th sx={statContainerHeading}>Club</Th>
              {location.pathname === "/soccer/top-assists" ? (
                <>
                  <Th sx={statContainerHeading}>Assists</Th>
                </>
              ) : (
                <>
                  <Th sx={statContainerHeading}>Pens</Th>
                  <Th sx={statContainerHeading}>Goals</Th>
                </>
              )}
            </Thead>
            <Tbody>
              {formattedTopGoalScorerData &&
                formattedTopGoalScorerData?.topGoalAssists?.length > 0 &&
                formattedTopGoalScorerData?.topGoalAssists
                  .slice(0, 10)
                  .map((player, index) => (
                    <Tr key={index} borderBottom="1px solid #d2232a">
                      <Td sx={statPosContainer}>{player?.["@pos"]}</Td>
                      <Td sx={clubContainer}>
                        {formatPlayerName(player?.["@name"])}
                      </Td>
                      <Td sx={flagContainer}>
                        {player?.flag && (
                          <Image
                            sx={flagStyling}
                            src={require(`../../../../../assets/soccer/${formattedTopGoalScorerData?.competition}/flags/${player?.flag}`)}
                          />
                        )}
                      </Td>
                      {location.pathname === "/soccer/top-assists" ? (
                        <>
                          <Td sx={statPosContainer} textAlign="center">
                            {player?.["@assists"]}
                          </Td>
                        </>
                      ) : (
                        <>
                          <Td sx={statPosContainer}>
                            {player?.["@penalty_goals"]}
                          </Td>
                          <Td sx={statPosContainer} textAlign="center">
                            {player?.["@goals"]}
                          </Td>
                        </>
                      )}
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <HolyWoodBetsStrap />

      <Image
        src={
          location.pathname === "/soccer/top-assists"
            ? PlaceHolderImageAssists
            : PlaceHolderImage
        }
        position="fixed"
        bottom="20"
        right="-10"
        height="620px"
        zIndex="1"
      />
    </Box>
  );
};

export default TopGoalAssistArtworkSl;
