import { Box } from "@chakra-ui/react";
import React from "react";
import HomeHeader from "../components/HomeHeader";
import HomeVideo from "../components/HomeVideo";
import HomeAbout from "../components/HomeAbout";

const Home = () => {
  return (
    <Box>
      <HomeHeader />
      <HomeVideo />
      <HomeAbout />
    </Box>
  );
};

export default Home;
