export const SetLocalTeamMatchEvents = (activeFixtureData) => {
  if (activeFixtureData == null) {
    return [];
  }

  const localTeamMatchEvents = activeFixtureData?.summary?.localteam;

  const formatEvent = (eventData, category) => {
    if (eventData == null) {
      return [];
    }

    const events = Array.isArray(eventData.player)
      ? eventData.player.map((item) => ({
          ...item,
          category,
          minute: parseInt(item.minute, 10),
        }))
      : [
          {
            category,
            minute: parseInt(eventData.player?.minute, 10),
            name: eventData.player?.name,
          },
        ];

    return events;
  };

  const formattedLocalTeamConversions = formatEvent(
    localTeamMatchEvents?.conversions,
    "Conversion"
  );

  const formattedLocalTeamTries = formatEvent(
    localTeamMatchEvents?.tries,
    "Try"
  );

  const formattedLocalTeamPenaltyGoals = formatEvent(
    localTeamMatchEvents.penaltyGoals,
    "Penalty"
  );

  const formattedLocalTeamPenaltyTries = formatEvent(
    localTeamMatchEvents?.tries?.penaltyTries,
    "Penalty Try"
  );

  const formattedLocalTeamYelowCards = formatEvent(
    localTeamMatchEvents?.yellowcards,
    "Yellow Card"
  );

  const formattedLocalTeamRedCards = formatEvent(
    localTeamMatchEvents?.redcards,
    "Red Card"
  );

  const combinedFormattedEvents = [
    ...formattedLocalTeamConversions,
    ...formattedLocalTeamTries,
    ...formattedLocalTeamPenaltyGoals,
    ...formattedLocalTeamPenaltyTries,
    ...formattedLocalTeamYelowCards,
    ...formattedLocalTeamRedCards,
  ];

  combinedFormattedEvents.sort((a, b) => a.minute - b.minute);

  return combinedFormattedEvents;
};
