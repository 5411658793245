import useUrlState from "@ahooksjs/use-url-state";
import { useLocation } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
} from "@chakra-ui/react";
import CustomSpinner from "../../components/CustomSpinner";
import TopGoalAssistArtwork from "../components/TopGoalAssistArtwork";
import TopGoalAssistArtworkSl from "../components/soccerLaduma/TopGoalAssistArtworkSl";

const fetchTopGoalScorerData = (leagueId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/topscorers/${leagueId}?json=1`
  );
};

const fetchTopAssistData = (leagueId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/topscorers/${leagueId}_assists?json=1`
  );
};

const TopGoalScorerAssist = () => {
  // Style Artwork
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };
  // Define Location
  const location = useLocation();

  // Define Image List & Fields
  const [topGoalAssistInput, setTopGoalAssistInput] = useUrlState({
    leagueId: "",
    activeTeam: "",
    activeImage: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTopGoalAssistInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  //   Fetch Data
  const {
    data: topGoalScorerData,
    isPending: isTopGoalScorerDataPending,
    error: topGoalScorerDataError,
    refetch: refetchTopGoalScorerData,
    isFetching: isTopGoalDataFetching,
  } = useQuery("topGoalScorerData", () =>
    fetchTopGoalScorerData(topGoalAssistInput.leagueId)
  );

  const {
    data: topAssistsData,
    isPending: isTopAssistsDataPending,
    isFetching: isTopAssistsDataFetching,
    error: topAssistsDataError,
    refetch: refetchTopAssistsData,
  } = useQuery("topAssistsData", () =>
    fetchTopAssistData(topGoalAssistInput.leagueId)
  );

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("soccer");

  // Fetch Data According To Location
  useEffect(() => {
    if (
      location.pathname === "/soccer/top-assists" &&
      topGoalAssistInput.leagueId != ""
    ) {
      refetchTopAssistsData();
    } else {
      refetchTopGoalScorerData();
    }
  }, [
    location.pathname,
    topGoalAssistInput.leagueId,
    refetchTopAssistsData,
    refetchTopGoalScorerData,
  ]);

  //   Set Goal ScorerData
  const [goalScorerAssistData, setGoalScorerAssistData] = useState(undefined);

  useEffect(() => {
    if (topGoalScorerData || topAssistsData) {
      if (location.pathname === "/soccer/top-assists") {
        setGoalScorerAssistData(
          topAssistsData?.data?.topscorers?.tournament?.player
        );
      } else {
        setGoalScorerAssistData(
          topGoalScorerData?.data?.topscorers?.tournament?.player
        );
      }
    }
  }, [topGoalScorerData, topAssistsData, location.pathname]);

  //   Graphic Data
  const [formattedTopGoalScorerData, setFormattedTopGoalScorerData] =
    useState("");

  useEffect(() => {
    if (goalScorerAssistData && graphicData) {
      const topGoalScorer = goalScorerAssistData.map((goalScorer) => {
        const matchingPlayer = graphicData?.[
          topGoalAssistInput.leagueId
        ]?.clubs?.find((team) => team.id === goalScorer["@team_id"]);

        if (matchingPlayer != null) {
          return { ...goalScorer, flag: matchingPlayer.flag };
        }
        return null; // Make sure to return something in all cases
      });

      setFormattedTopGoalScorerData({
        topGoalAssists: topGoalScorer,
        competition: topGoalAssistInput.leagueId,
      });
    }
  }, [graphicData, goalScorerAssistData, topGoalAssistInput.leagueId]);

  // Set download URL
  // Download Image
  const imageFilename =
    location.pathname === "/soccer/top-goal-scorer"
      ? "Top Goalscorer.png"
      : "Top Assists.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [topGoalAssistInput]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=1&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  if (
    isTopGoalScorerDataPending ||
    isTopAssistsDataPending ||
    isTopGoalDataFetching ||
    isTopAssistsDataFetching
  ) {
    return <CustomSpinner />;
  }
  return (
    <>
      {graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h2">
                {location.pathname === "/soccer/top-assists" ? (
                  <>
                    {" "}
                    Top <br /> Assists
                  </>
                ) : (
                  <> Top Goal Scorers </>
                )}
              </Heading>
              <Flex mt={8} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="League"
                  name="leagueId"
                  onChange={handleInputChange}
                  value={topGoalAssistInput.leagueId}
                >
                  <option value="1204">English Premier League</option>
                  <option value="1394">DStv Prem</option>
                </Select>
              </Flex>

              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              {/* <TopGoalAssistArtwork
                formattedTopGoalScorerData={formattedTopGoalScorerData}
              /> */}
              <TopGoalAssistArtworkSl
                formattedTopGoalScorerData={formattedTopGoalScorerData}
              />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default TopGoalScorerAssist;
