import React, { useEffect, useState } from "react";
import { Link, Link as ReachLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
} from "@chakra-ui/react";
import useUrlState from "@ahooksjs/use-url-state";

import useFetch from "../../../../actions/useFetch";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
// Helpers
import { removeTrailingW } from "../../../../helpers/removeTrailingW";
import { formatPlayerName } from "../../../../helpers/FormatPlayerName";
import { CalculatePercentage } from "../../../../helpers/CalculatePercentage";
// Components
import PlayerMatchStatsArtwork from "../components/PlayerMatchStatsArtwork";
import axios from "axios";
import { useQuery } from "react-query";
import CustomSpinner from "../../components/CustomSpinner";
import KoPlayerMatchStatsArtwork from "../../soccer/components/soccerLaduma/KoPlayerMatchStatsArtwork";

// const fetchLiveFixturesData = (leagueId) => {
//   return axios.get(
//     `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/${leagueId}?date=01.10.2023&json=1`
//   );
// };

const fetchLiveFixturesData = (leagueId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/${leagueId}.xml?json=1`
  );
};
const LeaguePlayerMatchStats = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const [activeFixture, setActiveFixture] = useUrlState({
    leagueId: null,
    fixtureId: null,
    teamId: null,
    playerId: null,
    position: null,
    image: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setActiveFixture((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  // Fetch Data

  const {
    data: fixturesData,
    isFetching: isFetchingFixturesData,
    error: fixturesListError,
    refetch: refetchLeagueData,
  } = useQuery("liveFixtures", () =>
    fetchLiveFixturesData(activeFixture.leagueId)
  );

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("soccer");

  useEffect(() => {
    if (activeFixture.leagueId != "") {
      refetchLeagueData();
    }
  }, [activeFixture.leagueId]);

  const [activeFixtures, setActiveFixtures] = useState([""]);
  useEffect(() => {
    if (fixturesData != null) {
      setActiveFixtures(fixturesData?.data?.commentaries.tournament.match);
    }
  }, [fixturesData]);

  const [activeFixtureData, setActiveFixtureData] = useState("");

  // Set Active Fixture Data
  useEffect(() => {
    if (
      activeFixtures !== null &&
      fixturesData !== null &&
      activeFixture !== null
    ) {
      if (Array.isArray(activeFixtures)) {
        const matchingFixture = activeFixtures.find(
          (item) => item["@id"] === activeFixture.fixtureId
        );
        setActiveFixtureData(matchingFixture);
      } else {
        setActiveFixtureData(activeFixtures);
      }
    }
  }, [activeFixtures, fixturesData, activeFixture]);

  // Set Active Teams
  const [activeTeams, setActiveTeams] = useState([]);

  useEffect(() => {
    if (activeFixtureData && activeFixtureData !== "") {
      const newActiveTeams = [
        {
          id: activeFixtureData?.localteam["@id"],
          name: activeFixtureData?.localteam["@name"],
          status: "localteam",
        },
        {
          id: activeFixtureData?.visitorteam["@id"],
          name: activeFixtureData?.visitorteam["@name"],
          status: "visitorteam",
        },
      ];

      setActiveTeams(newActiveTeams);
    }
  }, [activeFixtureData]);

  const [activeSquad, setActiveSquad] = useState(null);
  const [activeTeam, setActiveTeam] = useState(null);
  const [activeFilteredSquad, setActiveFilteredSquad] = useState(null);

  useEffect(() => {
    if (activeFixture.teamId != null && activeFixtureData !== null) {
      if (activeFixture.teamId == "localteam") {
        setActiveSquad(activeFixtureData?.player_stats?.localteam?.player);
        setActiveTeam(activeFixtureData?.localteam?.["@name"]);
      } else if (activeFixture.teamId == "visitorteam") {
        setActiveSquad(activeFixtureData?.player_stats?.visitorteam?.player);
        setActiveTeam(activeFixtureData?.visitorteam?.["@name"]);
      }
    }
  }, [activeFixtureData, activeFixture.teamId]);

  useEffect(() => {
    if (activeSquad) {
      const filteredSquad = activeSquad.filter(
        (player) => player["@minutes_played"] > 0
      );
      setActiveFilteredSquad(filteredSquad);
    }
  }, [activeSquad]);

  // Set and Define Active Player
  const [activePlayer, setActivePlayer] = useState("");

  useEffect(() => {
    if (activeSquad !== null && activeSquad !== undefined) {
      const matchingPlayer = activeSquad.find(
        (item) => item["@id"] == activeFixture.playerId
      );
      setActivePlayer(matchingPlayer);
    }
  }, [activeSquad, activeFixture]);

  // Define Active player Stats
  const [activePlayerStats, setActivePlayerStats] = useState({
    goalkeeper: {
      minutesPlayed: "",
      goalsConceded: "",
      saves: "",
      punches: "",
      passAccuracy: "",
    },
    defensive: {
      duelsWon: "",
      clearances: "",
      foulsCommitted: "",
      interceptions: "",
      passAccuracy: "",
    },
    attacking: {
      minutesPlayed: "",
      goals: "",
      shots: "",
      shotsOnGoal: "",
      assists: "",
      passAccuracy: "",
      keyPasses: "",
      successfulDribbles: "",
    },
  });

  useEffect(() => {
    if (activePlayer != undefined) {
      setActivePlayerStats((prevStats) => ({
        ...prevStats,
        goalkeeper: {
          minutesPlayed: activePlayer["@minutes_played"] + "'",
          goalsConceded: activePlayer["@goals_conceded"] || 0,
          saves: activePlayer["@saves"] || 0,
          punches: activePlayer["@punches"] || 0,

          passAccuracy: CalculatePercentage(
            activePlayer["@passes"],
            activePlayer["@passes_acc"]
          ),
        },
        defensive: {
          minutesPlayed: activePlayer["@minutes_played"] + "'",
          goalsConceded: activePlayer["@goals_conceded"] || 0,

          duelsWon:
            CalculatePercentage(
              activePlayer["@duelsTotal"] || 0,
              activePlayer["@duelsWon"] || 0
            ) || 0,
          clearances: activePlayer["@clearances"] || 0,
          foulsCommitted: activePlayer["@fouls_commited"] || 0,
          interceptions: activePlayer["@interceptions"] || 0,

          passAccuracy: CalculatePercentage(
            activePlayer["@passes"],
            activePlayer["@passes_acc"]
          ),
        },
        attacking: {
          minutesPlayed: activePlayer["@minutes_played"] + "'",
          goals: activePlayer["@goals"] || 0,
          shots: activePlayer["@shots_total"] || 0,
          shotsOnGoal: activePlayer["@shots_on_goal"] || 0,
          assists: activePlayer["@assists"] || 0,

          passAccuracy: CalculatePercentage(
            activePlayer["@passes"],
            activePlayer["@passes_acc"]
          ),

          keyPasses: activePlayer["@keyPasses"] || 0,

          successfulDribbles: CalculatePercentage(
            activePlayer["@dribbleAttempts"] || 0,
            activePlayer["@dribbleSucc"] || 0
          ),
        },
      }));
    }
  }, [activePlayer, activeFixture.playerId]);

  // // Set Displayed Stats
  const [displayedStats, setDisplayedStats] = useState(null);

  useEffect(() => {
    if (activePlayerStats != null) {
      if (activeFixture.position === "goalkeeper") {
        setDisplayedStats(activePlayerStats.goalkeeper);
      } else if (activeFixture.position === "defensive") {
        setDisplayedStats(activePlayerStats.defensive);
      } else if (activeFixture.position === "attacking") {
        setDisplayedStats(activePlayerStats.attacking);
      }
    }
  }, [activePlayerStats, activeFixture.position]);

  const [formattedDisplayedStats, setFormattedDisplayedStats] = useState([""]);

  useEffect(() => {
    if (displayedStats !== null) {
      setFormattedDisplayedStats(Object.entries(displayedStats));
    }
  }, [displayedStats]);

  // set Active Fixture Info

  const [activeFixtureInfo, setActiveFixtureInfo] = useState({
    localTeamId: "",
    localTeamFlag: "",
    visitorTeamFlag: "",
    visitorTeamId: "",
    localTeamScore: "",
    visitorTeamScore: "",
    activeTeamName: "",
    activePlayerName: "",
    stadiumName: "",
    time: "",
    date: "",
    group: "",
    image: "",
    league: "",
  });

  useEffect(() => {
    if (
      activeFixtureData != "" &&
      activeFixture.image != "" &&
      activeFixture.leagueId != "" &&
      activeTeam
    ) {
      setActiveFixtureInfo((prevStats) => ({
        ...prevStats,
        localTeamId: activeFixtureData?.localteam?.["@id"],
        visitorTeamId: activeFixtureData?.visitorteam?.["@id"],
        localTeamScore: activeFixtureData?.localteam?.["@goals"],
        visitorTeamScore: activeFixtureData?.visitorteam?.["@goals"],
        time: activeFixtureData["@time"],
        date: activeFixtureData["@formatted_date"],
        stadiumName: activeFixtureData?.matchinfo?.stadium["@name"],
        image: activeFixture.image,
        activeTeamName: activeTeam,
        activePlayerName: activePlayer?.["@name"],
        league: activeFixture.leagueId,
      }));
    }
  }, [
    activeFixtureData,
    activeFixture.image,
    activeTeam,
    activePlayer,
    activeFixture.leagueId,
  ]);

  // Set Graphic Info

  const [imageList, setImageList] = useState([""]);

  useEffect(() => {
    if (
      graphicData &&
      activeTeam != "" &&
      activeFixtureInfo.localTeamId != ""
    ) {
      const matchingLocalLogo = graphicData?.[
        activeFixture.leagueId
      ]?.clubs.find((item) => item.id == activeFixtureInfo.localTeamId);

      console.log(matchingLocalLogo);

      const matchingVisitorLogo = graphicData?.[
        activeFixture.leagueId
      ]?.clubs.find((item) => item.id == activeFixtureInfo.visitorTeamId);

      const matchingActiveImages = graphicData?.[
        activeFixture.leagueId
      ]?.clubs.find((item) => item.name == removeTrailingW(activeTeam));

      if (
        matchingLocalLogo &&
        matchingVisitorLogo
        // matchingActiveImages.images != null
      ) {
        setActiveFixtureInfo((prevStats) => ({
          ...prevStats,
          localTeamFlag: matchingLocalLogo.flag,
          visitorTeamFlag: matchingVisitorLogo.flag,
          group: matchingVisitorLogo.group,
        }));

        setImageList(matchingActiveImages?.images);
      }
    }
  }, [
    graphicData,
    activeFixtureInfo.localTeamId,
    activeFixtureInfo.visitorTeamId,
    activeTeam,
  ]);

  // Download Image
  const imageFilename = "Match Stats.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [activeFixture]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  if (isFetchingFixturesData || isGraphicDataPending) {
    return <CustomSpinner />;
  }

  return (
    <>
      <Flex
        className="body-container"
        height="100vh"
        alignItems="flex-end"
        flexShrink="1"
        borderRadius={10}
      >
        <Grid
          className="body-container-grid"
          gridTemplateColumns="40% 60%"
          width="100%"
        >
          <GridItem
            className="input-container"
            background="#fff8ef"
            p={10}
            display="flex"
            flexDir="column"
            justifyContent="center"
            borderRight="1px solid #e5a428"
          >
            <Heading fontWeight="600" variant="h4">
              Player Stats
            </Heading>
            <Flex mt={8} mb={2} gap="2rem">
              <Select
                sx={selectStyle}
                width="100%"
                placeholder="League"
                name="leagueId"
                onChange={handleInputChange}
                value={activeFixture.leagueId}
              >
                <option value="1204">English Premier League</option>
                <option value="1394">DStv Prem</option>
              </Select>
            </Flex>
            <Flex mt={3} mb={2}>
              <Select
                sx={selectStyle}
                width="100%"
                placeholder="Fixtures"
                name="fixtureId"
                onChange={handleInputChange}
                value={activeFixture.fixtureId}
              >
                {activeFixtures && Array.isArray(activeFixtures) ? (
                  activeFixtures?.map((dailyFixture, index) => (
                    <option key={index} value={dailyFixture["@id"]}>
                      {dailyFixture?.localteam?.["@name"]} -{" "}
                      {dailyFixture?.visitorteam?.["@name"]}
                    </option>
                  ))
                ) : (
                  <option value={activeFixtures?.["@id"]}>
                    {activeFixtures?.localteam &&
                      activeFixtures?.localteam?.["@name"]}
                  </option>
                )}
              </Select>
            </Flex>
            <Flex mt={2} mb={2}>
              <Select
                sx={selectStyle}
                width="100%"
                placeholder="Team"
                name="teamId"
                onChange={handleInputChange}
                value={activeFixture.teamId}
              >
                {activeTeams && activeTeams.length > 0 ? (
                  activeTeams.map((activeTeam, index) => (
                    <option key={index} value={activeTeam.status}>
                      {removeTrailingW(activeTeam.name)}
                    </option>
                  ))
                ) : (
                  <option value="">Select Fixture First</option>
                )}
              </Select>
            </Flex>
            <Flex mt={3} mb={2}>
              <Select
                sx={selectStyle}
                width="100%"
                placeholder="Player"
                name="playerId"
                onChange={handleInputChange}
                value={activeFixture.playerId}
              >
                {activeFilteredSquad && activeFilteredSquad.length > 0 ? (
                  activeFilteredSquad.map((player, index) => (
                    <option key={index} value={player["@id"]}>
                      {formatPlayerName(player["@name"])}
                    </option>
                  ))
                ) : (
                  <option value="">Select team first</option>
                )}
              </Select>
            </Flex>
            <Flex mt={3} mb={2}>
              <Select
                sx={selectStyle}
                width="100%"
                placeholder="Position"
                name="position"
                onChange={handleInputChange}
                value={activeFixture.position}
              >
                <option value="goalkeeper">Goalkeeper</option>
                <option value="defensive">Defensive</option>
                <option value="attacking">Attacking</option>
              </Select>
            </Flex>
            <Flex mt={3} mb={2}>
              <Select
                sx={selectStyle}
                width="100%"
                placeholder="Image"
                name="image"
                onChange={handleInputChange}
                value={activeFixture.image}
              >
                {imageList && imageList.length > 0 ? (
                  imageList.map((image, index) => (
                    <option key={index} value={image.image}>
                      {image.name}
                    </option>
                  ))
                ) : (
                  <option value="">Select team first</option>
                )}
              </Select>
            </Flex>
            <Button
              isLoading={isImageDataPending ? true : false}
              loadingText="Downloading Image"
              spinnerPlacement="end"
              variant="outlineButton"
              onClick={downloadImage}
            >
              Download Image
            </Button>
          </GridItem>
          <GridItem height="700px" background="#fff8ef" width="100%">
            {/* <PlayerMatchStatsArtwork
              formattedDisplayedStats={formattedDisplayedStats}
              activeFixtureInfo={activeFixtureInfo}
            /> */}
            <KoPlayerMatchStatsArtwork
              formattedDisplayedStats={formattedDisplayedStats}
              activeFixtureInfo={activeFixtureInfo}
            />
          </GridItem>
        </Grid>
      </Flex>
    </>
  );
};

export default LeaguePlayerMatchStats;
