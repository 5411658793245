import { Box, Grid, GridItem, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import NewsroomImage from "../../../assets/frontendImages/Newsroom.png";
// Animation
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Broadcast = () => {
  // Framer Motion
  const MotionText = motion(Text);

  const textVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 1.5, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 1.5, duration: 1 },
    },
  };

  // TypeWriter Anmimation

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [typewriterStarted, setTypewriterStarted] = useState(false);

  useEffect(() => {
    if (inView && !typewriterStarted) {
      const timeoutId = setTimeout(() => {
        setTypewriterStarted(true);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inView, typewriterStarted]);

  return (
    <Grid
      gridTemplateColumns={{ base: "100%", lg: "30% 40% 30%" }}
      margin="4rem 2rem 5rem 2rem"
      ref={ref}
    >
      <GridItem>
        <Heading variant="h3">
          <Typewriter
            options={{
              strings: "News Networks, Events & Broadcast",
              autoStart: typewriterStarted,
              delay: 30,
              pauseFor: 1000000,
              cursor: "",
            }}
          />
        </Heading>
      </GridItem>
      <GridItem>
        <MotionText
          variant="p"
          textAlign="justify"
          margin={{ base: "2rem 0 0 0 ", lg: "0 0 0 1rem" }}
          variants={textVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          News networks, events and broadcasts are powered by a data-feed
          provided by your brand in accordance to your social media strategy and
          digital assets. For instance if your brand decides to deploy an
          artwork for every show, a list of shows will be availed on your
          dedicated dashboard, once clicked, the name of the show,
          presenters/actors images and names will be populated on the artwork.
          <br /> <br />
          If a particular show produces dynamic content such as interviews, the
          process is similar. However, following selecting the name of the show
          or segment, the guest name and answers will have to be inputted by
          your brand custodian throughout the duration of the show. Therefore,
          instead of long, text-heavy threads on social media, dynamic sponsored
          artworks may be utilised to share key takeaways with your audience
          which results in increased engagement and sponsorship revenue.
        </MotionText>
      </GridItem>
      <GridItem>
        <Image
          src={NewsroomImage}
          height="500"
          width="500"
          position="absolute"
          right="8"
          bottom={{ base: "1000", md: "750", lg: "470" }}
          zIndex="-1"
        />
      </GridItem>
    </Grid>
  );
};

export default Broadcast;
