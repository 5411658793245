import React, { useEffect, useState } from "react";
import { Link, Link as ReachLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
} from "@chakra-ui/react";
import useFetch from "../../../../actions/useFetch";

import useUrlState from "@ahooksjs/use-url-state";

// Helpers
import { removeTrailingW } from "../../../../helpers/removeTrailingW";

import useFetchFirebase from "../../../../actions/useFetchFirebase";
import LeagueInGameUpdatesArtwork from "../components/LeagueInGameUpdatesArtwork";
import axios from "axios";
import { useQuery } from "react-query";
import CustomSpinner from "../../components/CustomSpinner";
import KoGoalSummaryArtwork from "../../soccer/components/soccerLaduma/KoGoalSummaryArtwork";
import GoalSummaryArtwork from "../../soccer/components/GoalSummaryArtwork";

// const fetchLiveFixturesData = (leagueId) => {
//   return axios.get(
//     `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/${leagueId}?date=01.10.2023&json=1`
//   );
// };

const fetchLiveFixturesData = (leagueId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/${leagueId}.xml?json=1`
  );
};

const LeagueInGameUpdates = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const [activeFixture, setActiveFixture] = useUrlState({
    leagueId: "",
    fixtureId: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setActiveFixture((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const {
    data: fixturesData,
    isFetching: isFetchingFixturesData,
    error: fixturesListError,
    refetch: refetchLeagueData,
  } = useQuery("liveFixtures", () =>
    fetchLiveFixturesData(activeFixture.leagueId)
  );

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("soccer");

  useEffect(() => {
    if (activeFixture.leagueId != "") {
      refetchLeagueData();
    }
  }, [activeFixture.leagueId]);

  const [activeFixtures, setActiveFixtures] = useState([""]);
  useEffect(() => {
    if (fixturesData) {
      setActiveFixtures(fixturesData?.data?.commentaries?.tournament.match);
    }
  }, [fixturesData]);

  const [activeFixtureData, setActiveFixtureData] = useState("");

  useEffect(() => {
    if (
      activeFixtures !== null &&
      fixturesData !== null &&
      activeFixture !== null
    ) {
      if (Array.isArray(activeFixtures)) {
        const matchingFixture = activeFixtures.find(
          (item) => item["@id"] === activeFixture.id
        );
        setActiveFixtureData(matchingFixture);
      } else {
        setActiveFixtureData(activeFixtures);
      }
    }
  }, [activeFixtures, fixturesData, activeFixture]);

  // Set Graphics
  const [activeTeams, setActiveTeams] = useState({
    homeTeamId: "",
    awayTeamId: "",
  });

  // fetch active team IDs
  useEffect(() => {
    if (activeFixtureData && activeFixtureData !== "") {
      setActiveTeams({
        homeTeamId: activeFixtureData.localteam["@id"],
        awayTeamId: activeFixtureData.visitorteam["@id"],
      });
    }
  }, [activeFixtureData]);

  // Set active logo
  const [activeFlags, setActiveFlags] = useState({
    home: "",
    away: "",
  });

  useEffect(() => {
    if (graphicData && activeTeams && activeFixture.leagueId != "") {
      const matchingHomeClub = graphicData?.[
        activeFixture.leagueId
      ]?.clubs.find((item) => item.id == activeTeams?.homeTeamId);

      const matchingAwayClub = graphicData?.[
        activeFixture.leagueId
      ]?.clubs.find((item) => item.id == activeTeams.awayTeamId);

      if (matchingHomeClub && matchingAwayClub) {
        setActiveFlags({
          home: matchingHomeClub.flag,
          away: matchingAwayClub.flag,
        });
      }
    }
  }, [
    activeTeams.homeTeamId,
    activeTeams.awayTeamId,
    graphicData,
    activeFixture.leagueId,
  ]);

  // Declare artwork variables
  const [inGameStats, setInGameStats] = useState({
    stadiumName: "",
    date: "",
    kickOffTime: "",
    timer: "",
    homeTeamName: "",
    homeTeamId: "",
    awayTeamName: "",
    homeTeamLogo: "",
    awayTeamLogo: "",
    homeTeamScore: "",
    awayTeamScore: "",
    homePossession: "",
    awayPossession: "",
    homePasses: "",
    awayPasses: "",
    homeShots: "",
    awayShots: "",
    homeShotsOnTarget: "",
    awayShotsOnTarget: "",
  });

  useEffect(() => {
    if (
      (activeFlags.home !== "" &&
        activeFlags.away !== "" &&
        activeFixture.leagueId != "",
      activeFixtureData)
    ) {
      setInGameStats({
        league: activeFixture.leagueId,
        stadiumName: activeFixtureData?.matchinfo?.stadium["@name"],
        kickOffTime: activeFixtureData?.["@time"],
        timer: activeFixture?.["@timer"],
        date: activeFixtureData?.["@formatted_date"],
        homeTeamName: activeFixtureData?.localteam?.["@name"],
        awayTeamName: activeFixtureData?.visitorteam?.["@name"],
        homeTeamLogo: activeFlags?.home,
        awayTeamLogo: activeFlags?.away,
        homeTeamScore: activeFixtureData.localteam?.["@goals"],
        awayTeamScore: activeFixtureData.visitorteam?.["@goals"],
        homePossession:
          activeFixtureData.stats?.localteam?.possestiontime["@total"],
        awayPossession:
          activeFixtureData?.stats?.visitorteam?.possestiontime["@total"],
        homePasses: activeFixtureData?.stats?.localteam?.passes?.["@total"],
        awayPasses: activeFixtureData?.stats?.visitorteam?.passes?.["@total"],
        homeShots: activeFixtureData?.stats?.localteam?.shots?.["@total"],
        awayShots: activeFixtureData?.stats?.visitorteam?.shots?.["@total"],
        homeShotsOnTarget:
          activeFixtureData?.stats?.localteam?.shots?.["@ongoal"],
        awayShotsOnTarget:
          activeFixtureData?.stats?.visitorteam?.shots?.["@ongoal"],
      });
    }
  }, [activeFixtureData, activeFlags]);

  // Extract and define Match Events
  const [homeEvents, setHomeEvents] = useState({
    minute: 0,
    name: "",
    ownGoal: false,
    penalty: false,
    varCancelled: false,
  });

  useEffect(() => {
    if (activeFixtureData !== null && typeof activeFixtureData === "object") {
      const homeGoalsData = activeFixtureData.summary?.localteam?.goals?.player;

      if (Array.isArray(homeGoalsData)) {
        setHomeEvents(
          homeGoalsData.map((homeGoal) => ({
            minute: homeGoal["@minute"],
            name: homeGoal["@name"],
            penalty: homeGoal["@penalty"],
            ownGoal: homeGoal["@owngoal"],
            varCancelled: homeGoal["@var_cancelled"],
          }))
        );
      } else if (typeof homeGoalsData === "object") {
        setHomeEvents({
          minute: homeGoalsData["@minute"],
          name: homeGoalsData["@name"],
          penalty: homeGoalsData["@penalty"],
          ownGoal: homeGoalsData["@owngoal"],
          varCancelled: homeGoalsData["@var_cancelled"],
        });
      } else {
        setHomeEvents({
          minute: null,
          name: null,
          penalty: null,
          ownGoal: null,
          varCancelled: null,
        });
      }
    }
  }, [activeFixtureData]);

  const [awayEvents, setAwayEvents] = useState({
    minute: "",
    name: "",
    ownGoal: false,
    penalty: false,
    varCancelled: false,
  });

  useEffect(() => {
    if (activeFixtureData !== null && typeof activeFixtureData === "object") {
      const awayGoalsData =
        activeFixtureData.summary?.visitorteam?.goals?.player;

      console.log(activeFixtureData);

      if (Array.isArray(awayGoalsData)) {
        setAwayEvents(
          awayGoalsData.map((homeGoal) => ({
            minute: homeGoal["@minute"],
            name: homeGoal["@name"],
            penalty: homeGoal["@penalty"],
            ownGoal: homeGoal["@owngoal"],
            varCancelled: homeGoal["@var_cancelled"],
          }))
        );
      } else if (typeof awayGoalsData === "object") {
        setAwayEvents({
          minute: awayGoalsData["@minute"],
          name: awayGoalsData["@name"],
          penalty: awayGoalsData["@penalty"],
          ownGoal: awayGoalsData["@owngoal"],
          varCancelled: awayGoalsData["@var_cancelled"],
        });
      } else {
        setAwayEvents({
          minute: null,
          name: null,
          penalty: null,
          ownGoal: null,
          varCancelled: null,
        });
      }
    }
  }, [activeFixtureData]);

  // Download Image
  const imageFilename = "In Game Updates.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [activeFixture]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  const handleReset = () => {
    setActiveFixture((prevData) => ({
      ...prevData,
      leagueId: "",
      fixtureId: "",
    }));

    // Reset inGameStats, homeEvents, and awayEvents
    setInGameStats({
      league: "",
      stadiumName: "",
      date: "",
      kickOffTime: "",
      timer: "",
      homeTeamName: "",
      homeTeamId: "",
      awayTeamName: "",
      homeTeamLogo: "",
      awayTeamLogo: "",
      homeTeamScore: "",
      awayTeamScore: "",
      homePossession: "",
      awayPossession: "",
      homePasses: "",
      awayPasses: "",
      homeShots: "",
      awayShots: "",
      homeShotsOnTarget: "",
      awayShotsOnTarget: "",
    });
    setHomeEvents({
      minute: 0,
      name: "",
      ownGoal: false,
      penalty: false,
      varCancelled: false,
    });
    setAwayEvents({
      minute: "",
      name: "",
      ownGoal: false,
      penalty: false,
      varCancelled: false,
    });
  };

  if (isFetchingFixturesData || isGraphicDataPending) {
    return <CustomSpinner />;
  }

  return (
    <>
      {graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h3">
                Match Updates
              </Heading>
              <Flex mt={8} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="League"
                  name="leagueId"
                  onChange={handleInputChange}
                  value={activeFixture.leagueId}
                >
                  <option value="1204">English Premier League</option>
                  <option value="1394">DStv Prem</option>
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Fixture"
                  name="id"
                  onChange={handleInputChange}
                  value={activeFixture.id}
                >
                  {activeFixtures && Array.isArray(activeFixtures) ? (
                    activeFixtures?.map((dailyFixture, index) => (
                      <option key={index} value={dailyFixture["@id"]}>
                        {dailyFixture?.localteam?.["@name"]} -{" "}
                        {dailyFixture?.visitorteam?.["@name"]}
                      </option>
                    ))
                  ) : (
                    <option value={activeFixtures?.["@id"]}>
                      {activeFixtures?.localteam &&
                        activeFixtures?.localteam?.["@name"]}
                    </option>
                  )}
                </Select>
              </Flex>
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
              <Button variant="outlineButton" onClick={handleReset}>
                Reset
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              {/* <LeagueInGameUpdatesArtwork
                inGameStats={inGameStats}
                homeEvents={homeEvents}
                awayEvents={awayEvents}
              /> */}
              <KoGoalSummaryArtwork
                inGameStats={inGameStats}
                homeEvents={homeEvents}
                awayEvents={awayEvents}
              />
              {/* <GoalSummaryArtwork
                inGameStats={inGameStats}
                homeEvents={homeEvents}
                awayEvents={awayEvents}
              /> */}
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default LeagueInGameUpdates;
