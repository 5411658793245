import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, Link as ReachLink } from "react-router-dom";
import RhestSubLogo from "../assets/RHESTSub_Logo.svg";
import AccountLogo from "../assets/accountLogos/Duma-logo.svg";
import SignOut from "./SignOut";

export default function Sidebar() {
  const menuItemContainer = {
    borderBottom: "1px solid #e5a428",
  };

  const menuItemButton = {
    display: "flex",
    justifyContent: "space-between",
    border: "none",

    _hover: {
      bord: "#e5a428",
    },
  };

  const menuItem = {
    fontSize: "1rem",

    _hover: {
      textDecor: "none",
      color: "#e5a428",
    },
    _active: {
      color: "#e5a428",
    },
  };

  const menuItemHeading = {
    fontSize: "1rem",
    _hover: {
      textDecor: "none",
      color: "#e5a428",
    },
    _active: {
      color: "#e5a428",
    },
  };

  const subMenuItem = {
    paddingLeft: "0.5rem",
    fontSize: "1.1rem",
  };

  const subMenuItemSecond = {
    paddingLeft: "1rem",
    paddingTop: "0.25rem",
  };

  const subMenuItemThird = {
    paddingLeft: "1.75rem",

    _hover: {
      textDecor: "none",
      color: "#e5a428",
    },
    _active: {
      color: "#e5a428",
    },
  };

  const linkStyling = {
    _hover: {
      textDecor: "none",
      color: "#e5a428",
    },
    _active: {
      color: "#e5a428",
    },
  };

  return (
    <Box
      mt="2.5vh"
      ml="2vh"
      h="95vh"
      width="18%"
      bg="#fbe1bf"
      overflow="auto"
      paddingTop="2rem"
      flexShrink="0"
      float="left"
      borderRadius="2rem"
      zIndex="1"
      position="relative"
    >
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        marginX="2rem"
        height="100%"
      >
        <Image src={RhestSubLogo} width="210px" />
        {/* <Avatar
          mt={3}
          size="2xl"
          name="Duma Collective"
          src={AccountLogo}
        />{" "} */}
        <Accordion mt={3} allowMultiple width="100%">
          <AccordionItem
            sx={menuItemContainer}
            theme="none"
            className="accordion-container"
          >
            <AccordionButton sx={menuItemButton}>
              <Heading sx={menuItemHeading}>Pre-Game</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Link to="soccer/head-to-head">
                <Text variant="p" sx={menuItem}>
                  Head to Head
                </Text>
              </Link>
              <Link to="/soccer/head-to-head-results">
                <Text variant="p" sx={menuItem}>
                  Head to Head Results
                </Text>
              </Link>
              <Link to="/dstv-prem/score-prediction">
                <Text variant="p" sx={menuItem}>
                  Score Prediction
                </Text>
              </Link>
              {/* <Link to="/dstv-prem/starting-11">
                <Text variant="p" sx={menuItem}>
                  Starting 11
                </Text>
              </Link> */}

              {/* <Link to="/dstv-prem/kick-off">
                <Text variant="p" sx={menuItem}>
                  Kick-Off Time
                </Text>
              </Link> */}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem sx={menuItemContainer}>
            <AccordionButton sx={menuItemButton}>
              <Heading sx={menuItemHeading}>In Game</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {/* <Link>
                <Text variant="p" sx={menuItem}>
                  Kick-Off
                </Text>
              </Link> */}
              <Link to="/dstv-prem/score-update">
                <Text variant="p" sx={menuItem}>
                  Score Update
                </Text>
              </Link>
              <Link to="dstv-prem/match-stats">
                <Text variant="p" sx={menuItem}>
                  Match Stats
                </Text>
              </Link>
              {/* <Link>
                <Text variant="p" sx={menuItem}>
                  Substitutes
                </Text>
              </Link> */}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem sx={menuItemContainer}>
            <AccordionButton sx={menuItemButton}>
              <Heading sx={menuItemHeading}>Post Game</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Link to="/dstv-prem/player-stats">
                <Text variant="p" sx={menuItem}>
                  Player Stats
                </Text>
              </Link>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem sx={menuItemContainer}>
            <AccordionButton sx={menuItemButton}>
              <Heading sx={menuItemHeading}>League Updates</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Link to="dstv-prem/team-profile">
                <Text variant="p" sx={menuItem}>
                  Team Profile
                </Text>
              </Link>
              {/* <Link to="dstv-prem/squad">
                <Text variant="p" sx={menuItem}>
                  Squads
                </Text>
              </Link> */}

              <Link to="dstv-prem/standings">
                <Text variant="p" sx={menuItem}>
                  Standings
                </Text>
              </Link>
              <Link to="dstv-prem/fixtures">
                <Text variant="p" sx={menuItem}>
                  Fixtures
                </Text>
              </Link>
              <Link to="dstv-prem/live-fixtures">
                <Text variant="p" sx={menuItem}>
                  Live Fixtures
                </Text>
              </Link>
              <Link to="/soccer/top-goal-scorer">
                <Text variant="p" sx={menuItem}>
                  Top Goal Scorer
                </Text>
              </Link>
              <Link to="/soccer/top-assists">
                <Text variant="p" sx={menuItem}>
                  Top Assists
                </Text>
              </Link>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <SignOut />
      </Flex>
    </Box>
  );
}
