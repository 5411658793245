import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// Animation
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import { useInView } from "react-intersection-observer";

const HomeAbout = () => {
  const valuePrepositions = [
    "Generate images in seconds",
    "Increased engagement",
    "Up to the minute sponsored content",
  ];

  // Framer Motion

  const MotionText = motion(Text);
  const MotionHeading = motion(Heading);
  const MotionBox = motion(Box);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: -20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1,
        duration: 1,
        when: "beforeChildren",
        staggerChildren: 0.4,
      },
    },
    exit: {
      opacity: 0,
      transition: { ease: "easeInOut" },
    },
  };

  const childVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  const textVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 1, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { ease: "easeInOut" },
    },
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [typewriterStarted, setTypewriterStarted] = useState(false);

  useEffect(() => {
    if (inView && !typewriterStarted) {
      setTypewriterStarted(true);
    }
  }, [inView, typewriterStarted]);

  return (
    <>
      <Box margin="4rem 2rem" ref={ref}>
        <Heading variant="h2" textAlign="center">
          {typewriterStarted && (
            <Typewriter
              options={{
                strings: "Why?",
                autoStart: true,
                delay: 75,
                pauseFor: 1000000,
                cursor: "",
              }}
            />
          )}
        </Heading>
        <MotionText
          variants={textVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          variant="p"
          as="p"
          textAlign="center"
          paddingBottom="1rem"
          margin="auto"
        >
          RHESTSub strives to increase your brand's commercial revenue and brand
          equity.
        </MotionText>

        <Flex
          justifyContent=" space-between"
          flexDir={{ base: "column", lg: "row" }}
        >
          {valuePrepositions.map((benefit, index) => (
            <MotionBox
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              key={index}
              width={{ base: "inherit", lg: "400px" }}
              margin={{ base: "1rem 2rem", lg: "0" }}
            >
              <MotionHeading
                variant="h4"
                marginBottom="0.5rem"
                textAlign="center"
              >
                {benefit}
              </MotionHeading>
            </MotionBox>
          ))}
        </Flex>
      </Box>
    </>
  );
};

export default HomeAbout;
