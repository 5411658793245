import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

const PageIntro = () => {
  const location = useLocation();
  const pageIntroCopy = [
    {
      url: "/contact",
      heading:
        "Our Data-driven generated artworks are tailored to your brand's needs. If you are interested in a consultation or a product demo and detailed insights your brand can take advantage of utilising RHESTSub. Feel free to contact us or follow us on social media.",
      subHeading:
        "Although we have stats for all broadcasted DStv Premierships fixtures. We cover select DStv Premiership games on social media, live feeds are posted throughout the game on Twitter, while the summary is posted on Instagram upon the completion of the game. Additional artworks and stats generated in between matchdays.",
    },
    {
      url: "/about",
      heading:
        "Founded by RHEST, RHESTSub aims to exponentially decrease the lead time of creating repetitive artwork. Utilisng AI We translate your brand's digital assets designed by your design professionals into templates which will be populated by live data.",
      subHeading:
        "RHESTSub consults with your brand's marketing team and designers to craft a meticulous content strategy tailored to your industry, key performance indicators and stakeholder needs. No two brands are the same therefore RHESTSub does not deploy a one size fits all product and pricing strategy.",
    },
    {
      url: "/how-it-works",
      heading:
        "Upon consulting with RHESTSub, your brand's digital assets will be powered by an up to the minute data feed throughout the duration of the season. Our AI design tools enable your brand to generate data-driven artworks in seconds resulting in increased social-media engagement and sponsorship revenue.",
      subHeading:
        "During the consultation, your social media marketing strategy will be dissected and translated into various digital assets. These digital assets will be broken down into various sections which can be edited by a click of a button. In sports, an artwork can be broken down into defensive and attacking stats, as well as paired with an accompanying image. Whereas news can be broken down into breaking news, sports and weather.",
    },
  ];

  // Framer Motion

  const MotionText = motion(Text);

  const pageIntroVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 4.5, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 1, duration: 1 },
    },
  };

  // Active Page Intro
  const [activePageIntro, setActivePageIntro] = useState("");
  useEffect(() => {
    const matchingPage = pageIntroCopy.find(
      (item) => item.url === location.pathname
    );
    if (matchingPage != null) {
      setActivePageIntro(matchingPage);
    }
  }, []);

  // TypeWriter Anmimation
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setStartAnimation(true);
    }, 1500);

    return () => clearTimeout(delayTimeout);
  }, []);

  return (
    <Flex
      flexDir={{ base: "column", lg: "row" }}
      justifyContent="flex-end"
      alignItems="flex-end"
      margin={{ base: "4rem 2rem", lg: "10rem 2rem" }}
      gap="8rem"
      height="auto"
    >
      <Box
        paddingLeft={{ base: "1rem", lg: "2rem" }}
        width={{ base: "90%", sm: "90%", md: "90%", lg: "60%", xl: "50%" }}
        display="flex"
        alignItems="flex-end"
        // height="100%"
      >
        <Heading variant="h5" height={{ base: "465px", lg: "175px" }}>
          {startAnimation && (
            <Typewriter
              options={{
                strings: [activePageIntro.heading],
                autoStart: true,
                delay: 15,
                pauseFor: 1000000,
              }}
            />
          )}
        </Heading>
      </Box>
      <MotionText
        variants={pageIntroVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        variant="p"
        textAlign="right"
        width={{ base: "90%", sm: "90%", md: "90%", lg: "40%", xl: "40%" }}
      >
        {activePageIntro.subHeading}
      </MotionText>
    </Flex>
  );
};

export default PageIntro;
