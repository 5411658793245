import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  SlSocialLinkedin,
  SlSocialTwitter,
  SlSocialInstagram,
} from "react-icons/sl";
import React, { useEffect, useState, useRef } from "react";
import CapeTown from "../../../assets/frontendImages/Cape Town.jpg";
// Animation
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Power2, gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { TimelineLite } from "gsap/gsap-core";
// Email
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const inputContainer = {
    mt: "1rem",
    mb: "1.5rem",
  };

  const linkStyling = {
    _hover: { color: "#e5a428" },
  };

  // TypeWriter Anmimation
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [typewriterStarted, setTypewriterStarted] = useState(false);

  useEffect(() => {
    if (inView && !typewriterStarted) {
      const timeoutId = setTimeout(() => {
        setTypewriterStarted(true);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inView, typewriterStarted]);

  // Framer Motion

  const MotionBox = motion(Box);
  const MotionFlex = motion(Flex);
  const MotionText = motion(Text);

  const boxVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 1, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 1, duration: 1 },
    },
  };

  const iconVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 1.5, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 1.5, duration: 1 },
    },
  };

  const textVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 1.5, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 1, duration: 1 },
    },
  };

  // GSAP
  let container = useRef(null);
  let image = useRef(null);
  let imageReveal = CSSRulePlugin.getRule(".img-contact-container");

  const tl = new TimelineLite();

  useEffect(() => {
    tl.to(container, 1, { css: { visibility: "visible" } })
      .to(imageReveal, 3.5, {
        height: "0%",
        ease: Power2.easeInOut,
      })
      .from(image, 1.4, { scale: 1.6, ease: Power2.easeInOut, delay: 0 });
  });

  // Contact Form
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    email: "",
    subject: "",
    message: "",
  });

  const form = useRef();

  const sendEmail = (e) => {
    // e.preventDefault();

    e.preventDefault();

    emailjs
      .sendForm(
        "service_yr0qvoe",
        "template_ua0ewqq",
        form.current,
        "54DMJLKarlFKah0TK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <>
      <Grid
        gridTemplateColumns={{ base: "100%", lg: "30% 40% 30%" }}
        margin="4rem 2rem 5rem 2rem"
        ref={ref}
        className="contact-form-container"
      >
        <GridItem display={{ base: "none", lg: "block" }}>
          <Heading variant="h4" marginBottom="0.5rem">
            {typewriterStarted && (
              <Typewriter
                options={{
                  strings: "Query?",
                  autoStart: true,
                  delay: 30,
                  pauseFor: 1000000,
                  cursor: "",
                }}
              />
            )}
          </Heading>
          <MotionText
            variants={textVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            variant="p"
            marginBottom="0.5rem"
          >
            05 Wessel Road Edenburg, <br />
            Rivonia, Johannesburg <br />
            Gauteng <br />
            South Africa <br />
            2128
          </MotionText>
          <Link to={"mailto:info@rhestsub.com"}>
            <MotionText
              sx={linkStyling}
              variant="p"
              variants={textVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              info@rhestsub.com
            </MotionText>
          </Link>
        </GridItem>
        <GridItem>
          <MotionBox
            variants={boxVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <form ref={form}>
              <Flex mb={2}>
                <Input
                  sx={selectStyle}
                  placeholder="Full Name"
                  name="from_name"
                />
              </Flex>
              <Flex sx={inputContainer} gap="2rem">
                <Input
                  sx={selectStyle}
                  placeholder="Email Address"
                  name="email"
                />
                <Input
                  sx={selectStyle}
                  placeholder="Contact Number"
                  type="number"
                  name="number"
                />
              </Flex>
              <Flex sx={inputContainer}>
                <Input sx={selectStyle} placeholder="Subject" name="{subject" />
              </Flex>
              <Flex sx={inputContainer}>
                <Textarea
                  _placeholder={{ color: "#000" }}
                  sx={selectStyle}
                  placeholder="Message"
                  name="message"
                />
              </Flex>
              <Button
                // isLoading={isImageDataPending ? true : false}
                loadingText="Submit"
                spinnerPlacement="end"
                variant="yellowButton"
                onClick={sendEmail}
              >
                Submit
              </Button>
            </form>
          </MotionBox>
          <MotionFlex
            variants={iconVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            margin="3rem 0"
            justifyContent="center"
            gap=" 4rem"
          >
            <Link
              target="_blank"
              to="https://www.linkedin.com/company/rhestsub/"
            >
              <SlSocialLinkedin className="contact-icon" />
            </Link>

            <Link target="_blank" to="https://twitter.com/_rhestsub">
              <SlSocialTwitter className="contact-icon" />
            </Link>
            <Link
              target="_blank"
              to="https://www.instagram.com/rhestsub/"
              _hover={{ color: "pink" }}
            >
              <SlSocialInstagram className="contact-icon" />
            </Link>
          </MotionFlex>
        </GridItem>
        <GridItem>
          <Image
            src={CapeTown}
            ref={(el) => (image = el)}
            height={{ base: "500", sm: "600" }}
            width={{ base: "333", sm: "400" }}
            position="absolute"
            right="8"
            bottom={{ base: "720", md: "740", lg: "280" }}
            zIndex="-1"
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default ContactForm;
