import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import HolyWoodBetsStrap from "../../../psl/components/UxComponents/HolyWoodBetsStrap";
import ArgentinaImage from "../../../../../assets/psl/flags/Kaizer Chiefs.png";
import PlayerImage from "../../../../../assets//soccer/1204/squadImages/Brentford/C. Norgaard Third.png";
import { formatPlayerName } from "../../../../../helpers/FormatPlayerName";

const KoGoalSummaryArtwork = ({ homeEvents, awayEvents, inGameStats }) => {
  const singleTeamScoreContainer = {
    // justifyContent: "center",
    flexDir: "column",
    // minHeight: "450px",
  };

  const flagSize = {
    height: "auto",
    width: "100px",
  };

  const scoreNumber = {
    color: "#fff",
    height: "85px",
  };

  const bodyTextStyling = {
    fontSize: "1.1rem",
    letterSpacing: "1px",
    color: "#fff",
  };

  const awayBodyTextStyling = {
    fontSize: "1.1rem",

    letterSpacing: "1px",
    color: "#fff",
    textAlign: "right",
  };

  const OgContainer = {
    color: "#DE1F26",
    marginLeft: "0.5rem",
    textAlign: "right",
    fontSize: "1.1rem",
    letterSpacing: "1px",
  };

  const awayBodyMinuteTextStyling = {
    fontSize: "1.1rem",
    letterSpacing: "1px",
    textAlign: "right",
    color: "#fff",
  };

  const homeBodyMinuteTextStyling = {
    fontSize: "1.1rem",
    letterSpacing: "1px",
    color: "#fff",
  };

  const clubHeadingStyling = {
    color: "#fff",
    width: "220px",
    height: "70px",
  };

  return (
    <Box
      overflow="hidden"
      height="700px"
      width="700px"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="artwork-container"
      backgroundColor="kickOffBackground.900"
      position="relative"
      zIndex="1"
    >
      <Box className="artwork-header" zIndex="1">
        <Heading
          textTransform="uppercase"
          className="fixture-heading__kick-off"
          variant="h1"
          fontSize="7rem"
          fontFamily="Neue Power Ultra"
          textAlign="left"
          marginLeft="1.5rem"
          height="167px"
        >
          Updates
        </Heading>
        <Flex
          className="artwork-heading"
          justifyContent="space-between"
          alignItems="center"
          margin="-9rem 2rem 0 2rem"
          backgroundSize="300px 100px"
        >
          <Box zIndex="1">
            <Heading
              variant="h5"
              fontFamily="Neue Power Ultra"
              textTransform="uppercase"
              lineHeight="1.5rem"
              fontWeight="800"
              color="#fff"
            >
              Full
              <Box color="#fff">Time</Box>
              Updates
            </Heading>
          </Box>
          <Box>
            <Image
              src={require("../../../../../assets/clients/kickOff/KO Icon BW.png")}
              height="90px"
            />
          </Box>
        </Flex>
      </Box>

      <Box className="artwork-body" marginX="2rem" zIndex="1">
        <Grid gridTemplateColumns="65% 35%" zIndex="100">
          <GridItem display="flex">
            <Flex
              className="local-team-container"
              sx={singleTeamScoreContainer}
            >
              <Image
                src={
                  inGameStats?.homeTeamLogo !== ""
                    ? require(`../../../../../assets/soccer/${inGameStats?.league}/flags/${inGameStats?.homeTeamLogo}`)
                    : ArgentinaImage
                }
                sx={flagSize}
              />
              <Heading variant="h4" sx={clubHeadingStyling}>
                {inGameStats?.homeTeamName !== ""
                  ? inGameStats?.homeTeamName
                  : "Mamelodi Sundowns"}
              </Heading>
              <Heading variant="h1" sx={scoreNumber}>
                {inGameStats?.homeTeamScore !== ""
                  ? inGameStats?.homeTeamScore
                  : "1"}
              </Heading>
              {homeEvents && Array.isArray(homeEvents) ? (
                homeEvents.map((homeEvent, index) => (
                  <Flex key={index}>
                    <Text sx={homeBodyMinuteTextStyling} width="40px">
                      {homeEvent.minute}'
                    </Text>
                    <Text sx={bodyTextStyling}>
                      {formatPlayerName(homeEvent.name)}
                    </Text>
                    {homeEvent.ownGoal === "True" ? (
                      <Text sx={OgContainer}>OG</Text>
                    ) : null}
                    {homeEvent.penalty === "True" ? (
                      <Text sx={OgContainer}>Pen</Text>
                    ) : null}
                  </Flex>
                ))
              ) : homeEvents.minute && !Array.isArray(homeEvents) ? (
                <Flex>
                  <Text sx={homeBodyMinuteTextStyling} width="40px">
                    {homeEvents.minute}'
                  </Text>
                  <Text sx={bodyTextStyling}>
                    {formatPlayerName(homeEvents.name)}
                  </Text>
                  {homeEvents.ownGoal === "True" ? (
                    <Text sx={OgContainer}>OG</Text>
                  ) : null}
                  {homeEvents.penalty === "True" ? (
                    <Text sx={OgContainer}>Pen</Text>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>

            <Flex
              className="visitor-team-container"
              sx={singleTeamScoreContainer}
              alignItems="flex-end"
            >
              <Image
                src={
                  inGameStats.awayTeamLogo != ""
                    ? require(`../../../../../assets/soccer/${inGameStats?.league}/flags/${inGameStats?.awayTeamLogo}`)
                    : ArgentinaImage
                }
                sx={flagSize}
              />
              <Heading variant="h4" sx={clubHeadingStyling} textAlign="right">
                {inGameStats?.awayTeamName !== ""
                  ? inGameStats?.awayTeamName
                  : "Kaizer Chiefs"}
              </Heading>
              <Heading variant="h1" sx={scoreNumber}>
                {inGameStats?.awayTeamScore !== ""
                  ? inGameStats?.awayTeamScore
                  : "2"}
              </Heading>
              {awayEvents && Array.isArray(awayEvents) ? (
                awayEvents.map((awayEvent, index) => (
                  <Flex key={index} justifyContent="flex-end">
                    {awayEvent.ownGoal === "True" ? (
                      <Text sx={OgContainer}>OG</Text>
                    ) : null}
                    {awayEvent.penalty === "True" ? (
                      <Text sx={OgContainer}>Pen</Text>
                    ) : null}
                    <Text sx={awayBodyTextStyling}>
                      {formatPlayerName(awayEvent.name)}
                    </Text>
                    <Text sx={awayBodyMinuteTextStyling} width="40px">
                      {awayEvent.minute}'
                    </Text>
                  </Flex>
                ))
              ) : awayEvents.minute != null && !Array.isArray(awayEvents) ? (
                <Flex justifyContent="flex-end">
                  {awayEvents.ownGoal === "True" ? (
                    <Text sx={OgContainer}>OG</Text>
                  ) : null}
                  {awayEvents.penalty === "True" ? (
                    <Text sx={OgContainer}>Pen</Text>
                  ) : null}
                  <Text sx={awayBodyTextStyling}>
                    {formatPlayerName(awayEvents.name)}
                  </Text>
                  <Text
                    sx={awayBodyMinuteTextStyling}
                    width="40px"
                    alignText="right"
                  >
                    {awayEvents.minute}'
                  </Text>
                </Flex>
              ) : null}
            </Flex>
          </GridItem>
          <GridItem>
            <Image
              position="fixed"
              src={PlayerImage}
              height="550px"
              bottom="20"
              right="0"
              zIndex="1"
            />
          </GridItem>
        </Grid>
      </Box>

      <HolyWoodBetsStrap />
    </Box>
  );
};

export default KoGoalSummaryArtwork;
