import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyA-LW7lH2jwYwy-BC3h-UmI8uf5ufmPi_4",
  authDomain: "rhest-sub.firebaseapp.com",
  projectId: "rhest-sub",
  storageBucket: "rhest-sub.appspot.com",
  messagingSenderId: "271835124465",
  appId: "1:271835124465:web:8321f055157222c786cf50",
  measurementId: "G-K5MNYWPL2W",
};

firebase.initializeApp(firebaseConfig);

const database = firebase.database();
export const auth = getAuth();

const useFetchFirebase = (collectionName) => {
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const ref = database.ref(collectionName);

    const fetchData = () => {
      ref.on(
        "value",
        (snapshot) => {
          const fetchedData = snapshot.val();
          setData(fetchedData);
          setIsPending(false);
          setError(null);
        },
        (error) => {
          setIsPending(false);
          setError(error.message);
          console.error("Error fetching data:", error);
        }
      );
    };

    fetchData();

    return () => {
      ref.off();
    };
  }, [collectionName]);

  return { data, isPending, error };
};

export default useFetchFirebase;
