import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";

import ArgentinaImage from "../../../../../assets/womensWorldCup/flags/South-Africa.png";
import SquadPlayer from "../../../../../assets/soccer/1204/squadImages/Brentford/N. Collins.png";
import { SaTimeHelper } from "../../../../../helpers/SaTimeHelper";
import HolyWoodBetsStrap from "../UxComponents/HolyWoodBetsStrap";
import homeFlag from "../../../../../assets/soccer/1204/flags/Nottingham Forest.png";
import visitorFlag from "../../../../../assets/soccer/1204/flags/Brentford.png";
// import Image from "../../../../../assets/soccer/1204/flags/";
import KickOffLogo from "../../../../../assets/clients/kickOff/KO Icon BW.png";

const KoTeamMatchStatsArtwork = ({ activeMatchStats }) => {
  // Styling

  const headingTextStyling = {
    fontFamily:
      "'Neue Power Ultra', Arial, 'Helvetica Neue', Helvetica, sans-serif",
    textTransform: "uppercase",
    lineHeight: "1.5rem",
    fontWeight: "800",
    color: "#fff",
  };

  const introTextStyling = {
    fontSize: "1rem",
    color: "#fff",
    textAlign: "center",
  };

  const flagSize = {
    height: "auto",
    width: "80px",
  };

  const scoreNumber = {
    paddingTop: "1.2rem",
    color: "#fff",
  };

  const singleTeamScoreContainer = {
    gap: "1rem",
    height: "90px",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "wrap",
  };

  const singleStatContainer = {
    padding: " 0.25rem 0.5rem",
    justifyContent: "space-between",
    alignItems: "baseline",
    color: "#fff",
  };

  const matchStatDescription = {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "1rem",
    color: "#fff",
  };

  return (
    <Box
      overflow="hidden"
      height="700px"
      width="700px"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="artwork-container"
      backgroundColor="kickOffBackground.900"
    >
      <Heading
        textTransform="uppercase"
        className="fixture-heading__kick-off"
        variant="h1"
        fontSize="5.7rem"
        fontFamily="Neue Power Ultra"
        zIndex="1"
        position="relative"
        marginTop="1rem"
        marginLeft="1.5rem"
      >
        Statistics
      </Heading>
      <Flex
        className="artwork-heading"
        justifyContent="space-between"
        alignItems="center"
        margin="-7.25rem 2rem 0 2rem"
        backgroundSize="contain"
        zIndex="1"
      >
        <Box>
          <Heading variant="h5" sx={headingTextStyling}>
            {activeMatchStats.status != ""
              ? activeMatchStats.status
              : "Full - Time"}
            <Box color="#fff">Match</Box>
            Stats
          </Heading>
        </Box>
        <Box>
          <Image
            src={require("../../../../../assets/clients/kickOff/KO Icon BW.png")}
            height="90px"
            width="90px"
          />
        </Box>
      </Flex>
      <Flex className="artwork-body" flexDirection="column" height="100%">
        <Grid gridTemplateColumns="40% 60%">
          <GridItem>
            <Image
              src={
                activeMatchStats?.image && activeMatchStats?.teamName !== ""
                  ? require(`../../../../../assets/soccer/${activeMatchStats.leagueId}/squadImages/${activeMatchStats.teamName}/${activeMatchStats.image}`)
                  : SquadPlayer
              }
              height="550px"
              position="fixed"
              bottom="90px"
              right="380px"
              zIndex="5"
            />
          </GridItem>
          <GridItem
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="495px"
            marginTop="-0.5rem"
          >
            <Box className="fixture-description-container">
              <Text variant="p" sx={introTextStyling}>
                {activeMatchStats.stadiumName !== ""
                  ? activeMatchStats.stadiumName
                  : " Johannesburg Stadium, Johannesburg"}
              </Text>

              {activeMatchStats.date !== "" ? (
                <Text sx={introTextStyling}>
                  {activeMatchStats.date},{" "}
                  {SaTimeHelper(activeMatchStats.time, 2)}
                </Text>
              ) : (
                <Text sx={introTextStyling}>31.07.2023, 17:00</Text>
              )}
            </Box>

            <Flex flexDirection="column">
              <Flex
                className="team-container"
                justifyContent="space-between"
                borderBottom="1px solid #d2232a"
              >
                <Flex
                  className="single-team-container"
                  sx={singleTeamScoreContainer}
                  paddingRight="4rem"
                >
                  <Image
                    src={
                      activeMatchStats?.homeTeam?.logo !== ""
                        ? require(`../../../../../assets/soccer/${activeMatchStats.leagueId}/flags/${activeMatchStats.homeTeam.logo}`)
                        : homeFlag
                    }
                    sx={flagSize}
                  />

                  <Heading variant="h1" sx={scoreNumber}>
                    {activeMatchStats.homeTeam.score !== ""
                      ? activeMatchStats.homeTeam.score
                      : 3}
                  </Heading>
                </Flex>
                <Flex
                  className="single-team-container"
                  sx={singleTeamScoreContainer}
                >
                  <Image
                    src={
                      activeMatchStats?.awayTeam?.logo !== ""
                        ? require(`../../../../../assets/soccer/${activeMatchStats.leagueId}/flags/${activeMatchStats.awayTeam.logo}`)
                        : visitorFlag
                    }
                    sx={flagSize}
                  />
                  <Heading variant="h1" sx={scoreNumber}>
                    {activeMatchStats.awayTeam.score !== ""
                      ? activeMatchStats.awayTeam.score
                      : 0}
                  </Heading>
                </Flex>
              </Flex>

              <Box
                className="stats-container"
                paddingY="0.5rem"
                marginBottom="0.25rem"
                borderBottom="1px solid #d2232a"
              >
                <Flex
                  className="single-stat-container"
                  sx={singleStatContainer}
                >
                  <Heading variant="h6">
                    {activeMatchStats.homeTeam.stats.possession !== ""
                      ? activeMatchStats.homeTeam.stats.possession
                      : "50%"}
                  </Heading>
                  <Heading variant="h6" sx={matchStatDescription}>
                    Possession
                  </Heading>
                  <Heading variant="h6">
                    {activeMatchStats.awayTeam.stats.possession !== ""
                      ? activeMatchStats.awayTeam.stats.possession
                      : "50%"}
                  </Heading>
                </Flex>

                <Flex
                  className="single-stat-container"
                  sx={singleStatContainer}
                >
                  {activeMatchStats.homeTeam.stats.shots !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.homeTeam.stats.shots}
                    </Heading>
                  ) : (
                    <Heading variant="h6">5</Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Shots
                  </Heading>
                  {activeMatchStats.awayTeam.stats.shots !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.awayTeam.stats.shots}
                    </Heading>
                  ) : (
                    <Heading variant="h6">5</Heading>
                  )}
                </Flex>

                <Flex
                  className="single-stat-container"
                  sx={singleStatContainer}
                >
                  {activeMatchStats.homeTeam.stats.shotsOnTarget !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.homeTeam.stats.shotsOnTarget}
                    </Heading>
                  ) : (
                    <Heading variant="h6">5</Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Shots on target
                  </Heading>
                  {activeMatchStats.awayTeam.stats.shotsOnTarget !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.awayTeam.stats.shotsOnTarget}
                    </Heading>
                  ) : (
                    <Heading variant="h6">5</Heading>
                  )}
                </Flex>

                <Flex
                  className="single-stat-container"
                  sx={singleStatContainer}
                >
                  {activeMatchStats.homeTeam.stats.passes !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.homeTeam.stats.passes}
                    </Heading>
                  ) : (
                    <Heading variant="h6">120</Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Passes
                  </Heading>
                  {activeMatchStats.awayTeam.stats.passes !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.awayTeam.stats.passes}
                    </Heading>
                  ) : (
                    <Heading variant="h6">120</Heading>
                  )}
                </Flex>

                <Flex
                  className="single-stat-container"
                  sx={singleStatContainer}
                >
                  {activeMatchStats.homeTeam.stats.passesComplete !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.homeTeam.stats.passesComplete}
                    </Heading>
                  ) : (
                    <Heading variant="h6">120</Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Passes Complete
                  </Heading>
                  {activeMatchStats.awayTeam.stats.passesComplete !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.awayTeam.stats.passesComplete}
                    </Heading>
                  ) : (
                    <Heading variant="h6">120</Heading>
                  )}
                </Flex>

                <Flex
                  className="single-stat-container"
                  sx={singleStatContainer}
                >
                  {activeMatchStats.homeTeam.stats.corners !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.homeTeam.stats.corners}
                    </Heading>
                  ) : (
                    <Heading variant="h6">6</Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Corners
                  </Heading>
                  {activeMatchStats.awayTeam.stats.corners !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.awayTeam.stats.corners}
                    </Heading>
                  ) : (
                    <Heading variant="h6">6</Heading>
                  )}
                </Flex>

                <Flex
                  className="single-stat-container"
                  sx={singleStatContainer}
                >
                  {activeMatchStats.homeTeam.stats.fouls !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.homeTeam.stats.fouls}
                    </Heading>
                  ) : (
                    <Heading variant="h6">10</Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Fouls
                  </Heading>
                  {activeMatchStats.awayTeam.stats.fouls !== "" ? (
                    <Heading variant="h6">
                      {activeMatchStats.awayTeam.stats.fouls}
                    </Heading>
                  ) : (
                    <Heading variant="h6">10</Heading>
                  )}
                </Flex>

                <Flex
                  className="single-stat-container"
                  sx={singleStatContainer}
                >
                  <Heading variant="h6">
                    {activeMatchStats.homeTeam.stats.yellowCards !== ""
                      ? activeMatchStats.homeTeam.stats.yellowCards
                      : "0"}
                  </Heading>
                  <Heading variant="h6" sx={matchStatDescription}>
                    Yellow Cards
                  </Heading>
                  <Heading variant="h6">
                    {activeMatchStats.awayTeam.stats.yellowCards !== ""
                      ? activeMatchStats.awayTeam.stats.yellowCards
                      : "0"}
                  </Heading>
                </Flex>

                <Flex
                  className="single-stat-container"
                  sx={singleStatContainer}
                >
                  <Heading variant="h6">
                    {activeMatchStats.homeTeam.stats.redCards !== ""
                      ? activeMatchStats.homeTeam.stats.redCards
                      : "0"}
                  </Heading>
                  <Heading variant="h6" sx={matchStatDescription}>
                    Red Cards
                  </Heading>
                  <Heading variant="h6">
                    {activeMatchStats.awayTeam.stats.redCards !== ""
                      ? activeMatchStats.awayTeam.stats.redCards
                      : "0"}
                  </Heading>
                </Flex>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
        <HolyWoodBetsStrap />
      </Flex>
    </Box>
  );
};

export default KoTeamMatchStatsArtwork;
