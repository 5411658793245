import React, { useEffect, useState } from "react";
import { Link, Link as ReachLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Spinner,
} from "@chakra-ui/react";
import useUrlState from "@ahooksjs/use-url-state";
import axios from "axios";
import { useQuery } from "react-query";

// Components
import RugbyPoolFixturesArtwork from "../components/RugbyPoolFixturesArtwork";
import CustomSpinner from "../../../components/CustomSpinner";
// Helpers
import { poolFormatter } from "../../../../../helpers/PoolFormatter";
// Hooks
import useFetchFirebase from "../../../../../actions/useFetchFirebase";

const fetchRugbyTableData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/1106_table?json=1"
  );
};

const fetchRugbyFixturesData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/1106?json=1"
  );
};

const RugbyPoolFixtures = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const [activeGroup, setActiveGroup] = useUrlState({
    name: "",
    team: "",
    image: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setActiveGroup((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  // Fetch Data
  const {
    isLoading: isTableDataLoading,
    data: rugbyTableData,
    isError,
  } = useQuery("group-standings", fetchRugbyTableData);

  const { isLoading: isFixtureDataLoading, data: rugbyFixtureData } = useQuery(
    "group-fixtures",
    fetchRugbyFixturesData
  );

  const [rugbyStandings, setRugbyStandings] = useState("");

  useEffect(() => {
    if (rugbyTableData != undefined) {
      setRugbyStandings(rugbyTableData?.data.standings?.category.league);
    }
  }, [rugbyTableData]);

  // Retrieve Teams from groups
  const [activeGroupData, setActiveGroupData] = useState("");
  useEffect(() => {
    if (activeGroup.name !== "" && rugbyStandings?.length > 0) {
      const matchingGroup = rugbyStandings.find(
        (item) => item.name === activeGroup.name
      );

      // console.log(matchingGroup);
      if (matchingGroup) {
        setActiveGroupData(matchingGroup);
      }
    }
  }, [activeGroup.name, rugbyStandings]);

  // Set Graphic Data
  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("rugby");

  const [imageList, setImageList] = useState("");

  useEffect(() => {
    if (activeGroup.team != "") {
      const matchingTeam = graphicData?.international?.find(
        (item) => item.name === activeGroup?.team
      );

      if (matchingTeam) {
        setImageList(matchingTeam.images);
      }
    }
  }, [activeGroup.team, graphicData.international]);

  // Retrieve Active Fixtures
  const [activeFixturesData, setActiveFixturesData] = useState({});

  useEffect(() => {
    if (activeGroup.team !== "" && rugbyFixtureData !== undefined) {
      const fixtureList = rugbyFixtureData.data?.results?.tournament?.match;

      if (fixtureList) {
        const activeFixtureList = fixtureList.filter(
          (fixture) =>
            fixture.localteam?.name === activeGroup.team ||
            fixture.awayteam?.name === activeGroup.team
        );

        setActiveFixturesData({
          pool: activeGroup.name,
          team: activeGroup.team,
          image: activeGroup.image,
          fixtures: activeFixtureList,
        });
      }
    }
  }, [activeGroup, rugbyFixtureData?.data]);

  const [formattedFixtureData, setFormattedFixtureData] = useState(undefined);

  useEffect(() => {
    if (activeFixturesData.fixtures?.length > 0 && graphicData != null) {
      const updatedActiveFixturesData = [...activeFixturesData.fixtures];

      activeFixturesData.fixtures.forEach((fixture, index) => {
        const matchingLocalTeams = graphicData?.international?.find(
          (item) => item.name === fixture.localteam.name
        );

        const matchingAwayTeams = graphicData?.international?.find(
          (item) => item.name === fixture.awayteam.name
        );

        if (matchingLocalTeams != null && matchingAwayTeams != null) {
          updatedActiveFixturesData[index] = {
            ...fixture,
            localteam: {
              ...fixture.localteam,
              flag: matchingLocalTeams.flag,
            },
            awayteam: {
              ...fixture.awayteam,
              flag: matchingAwayTeams.flag,
            },
          };
        }
      });

      // Set the updated activeFixturesData
      // setFormattedFixtureData(updatedActiveFixturesData);
      setFormattedFixtureData({
        image: activeFixturesData.image,
        pool: activeFixturesData.pool,
        team: activeFixturesData.team,
        fixtures: updatedActiveFixturesData,
      });
    }
  }, [graphicData, activeFixturesData]);

  // Download Image
  const imageFilename = "Fixtures.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [activeGroup]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  if (isTableDataLoading || isFixtureDataLoading) {
    return <CustomSpinner />;
  }

  return (
    <>
      {rugbyTableData && rugbyFixtureData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h4">
                Fixtures
              </Heading>
              <Flex mt={8} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Pools"
                  name="name"
                  onChange={handleInputChange}
                  value={activeGroup.name}
                >
                  {rugbyStandings &&
                    rugbyStandings.map((group, index) => (
                      <option key={index} value={group.name}>
                        {poolFormatter(group.name)}
                      </option>
                    ))}
                </Select>
              </Flex>

              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Team"
                  name="team"
                  onChange={handleInputChange}
                  value={activeGroup.team}
                >
                  {activeGroupData?.team &&
                  activeGroupData?.team?.length > 0 ? (
                    activeGroupData?.team.map((team, index) => (
                      <option key={index} value={team.name}>
                        {team?.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Select Pool First</option>
                  )}
                </Select>
              </Flex>

              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Image"
                  name="image"
                  onChange={handleInputChange}
                  value={activeGroup.image}
                >
                  {imageList ? (
                    imageList.map((image, index) => (
                      <option key={index} value={image.image}>
                        {image.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Select team first</option>
                  )}
                </Select>
              </Flex>
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px">
              <RugbyPoolFixturesArtwork
                formattedFixtureData={formattedFixtureData}
              />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default RugbyPoolFixtures;
