import { Box, Image, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Flag from "../../../../../../assets/rugby/International/South Africa.png";
import React from "react";

const RugbyTable = ({ formattedGroupData }) => {
  const statContainer = {
    maxWidth: "3.5rem",
    fontSize: "1rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    width: "25px",
  };

  const formContainer = {
    maxWidth: "3.5rem",
    fontSize: "0.75rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    paddingY: "0.4rem",
    width: "25px",
  };

  const statPosContainer = {
    maxWidth: "3.5rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    fontFamily: "Neue Power Bold",
    fontSize: "1rem",
    width: "23px",

    paddingY: "0",
  };

  const statContainerHeading = {
    maxWidth: "3.5rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    color: "#e5a428",
    fontFamily: "Neue Power Bold",
    fontSize: "1rem",
    width: "23px",
  };

  const pointsContainerHeading = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    color: "#e5a428",
    fontFamily: "Neue Power Bold",
    fontSize: "0.85rem",
    paddingY: "0",
  };

  const clubContainer = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0",
    fontSize: "1rem",
    paddingY: "0",
  };

  const clubContainerHeading = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    color: "#e5a428",
    fontFamily: "Neue Power Bold",
    fontSize: "1rem",
  };

  const flagContainer = {
    minWidth: "60px",
    paddingY: "0",
  };

  const flagStyling = {
    width: "30px",
  };

  return (
    <>
      <Box
        className="single-fixture"
        borderRadius="1rem"
        backgroundColor="rgb(255,255,255, 0.7)"
        border="1px solid #BF1A2F"
        padding="0.5rem"
        zIndex="100"
        paddingY="1rem"
      >
        <Table size="sm" variant="unstyled">
          <Thead
            borderBottom="1px solid #BF1A2F"
            width="1rem"
            fontFamily="Neue Power Bold"
          >
            <Th sx={statContainerHeading}></Th>
            <Th sx={statContainerHeading}></Th>
            <Th sx={clubContainerHeading}>Country</Th>
            <Th sx={statContainerHeading}>GP</Th>
            <Th sx={statContainerHeading}>W</Th>
            <Th sx={statContainerHeading}>D</Th>
            <Th sx={statContainerHeading}>L</Th>
            <Th sx={statContainerHeading}>PF</Th>
            <Th sx={statContainerHeading}>PA</Th>

            <Th sx={pointsContainerHeading}>PTS</Th>
            <Th sx={pointsContainerHeading}>Form</Th>
          </Thead>
          <Tbody>
            {formattedGroupData && formattedGroupData !== undefined ? (
              formattedGroupData?.pool?.map((team, index) => (
                <Tr index={index} borderBottom="1px solid #BF1A2F">
                  <Td
                    sx={
                      index === 0 || index === 1
                        ? {
                            ...statPosContainer,
                            _first: {
                              color: "#e5a428",
                            },
                          }
                        : index === 15 || index === 14 // Updated condition
                        ? {
                            ...statPosContainer,
                            _first: {
                              color: "#FF530D",
                            },
                          }
                        : statPosContainer
                    }
                  >
                    {team?.pos}
                  </Td>
                  <Td sx={flagContainer}>
                    {team && team.flag && (
                      <Image
                        sx={flagStyling}
                        src={require(`../../../../../../assets/rugby/International/${team.flag}`)}
                      />
                    )}
                  </Td>
                  {team && (
                    <>
                      <Td sx={clubContainer} className="club-name-container">
                        {team.name}
                      </Td>
                      <Td sx={statContainer}>{team.gp}</Td>
                      <Td sx={statContainer}>{team.w}</Td>
                      <Td sx={statContainer}>{team.d}</Td>
                      <Td sx={statContainer}>{team.l}</Td>
                      <Td sx={statContainer}>{team.gs}</Td>
                      <Td sx={statContainer}>{team.ga}</Td>

                      <Td sx={statContainer}>{team.pts}</Td>
                      <Td sx={formContainer}>{team.recent_form}</Td>
                    </>
                  )}
                </Tr>
              ))
            ) : (
              <Tr borderBottom="1px solid #000">
                <Td sx={statContainer}>1</Td>
                <Td sx={flagContainer}>
                  <Image
                    sx={flagStyling}
                    src={require("../../../../../../assets/rugby/International/South Africa.png")}
                  />
                </Td>

                <Td sx={clubContainer} className="club-name-container">
                  South Africa
                </Td>
                <Td sx={statContainer}>1</Td>
                <Td sx={statContainer}>3</Td>
                <Td sx={statContainer}>1</Td>
                <Td sx={statContainer}>0</Td>
                <Td sx={statContainer}>0</Td>
                <Td sx={statContainer}>0</Td>
                <Td sx={statContainer}>0</Td>
                <Td sx={statContainer}>0</Td>
                <Td sx={formContainer}>WWW</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default RugbyTable;
