import useUrlState from "@ahooksjs/use-url-state";
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useFetchFirebase from "../../../../../actions/useFetchFirebase";
// Helpers
import { ActiveFixtureDataHelper } from "../../../../../helpers/rugby/ActiveFixtureDataHelper";
import useFetch from "../../../../../actions/useFetch";
import { SetLocalTeamMatchEvents } from "../../../../../helpers/rugby/SetLocalTeamMatchEvents";
import { SetVisitorTeamMatchEvents } from "../../../../../helpers/rugby/SetVisitorTeamMatchEvents";
import { ConsolidatedMatchEvents } from "../../../../../helpers/rugby/ConsolidatedMatchEvents";
import RugbyInGameUpdatesArtwork from "../components/RugbyInGameUpdatesArtwork";
import CustomSpinner from "../../../components/CustomSpinner";

// const fetchRugbyLiveScoreData = () => {
//   return axios.get(
//     "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/live_stats?date=23.09.2023&json=1"
//   );
// };
const fetchRugbyLiveScoreData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/live_stats?json=1"
  );
};

const LiveRugbyScore = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  // Fetch Data
  const {
    isLoading: isFixturesDataLoading,
    data: fixturesData,
    isError,
  } = useQuery("liveStats", fetchRugbyLiveScoreData);

  // const {
  //   isLoading: isFixturesDataLoading,
  //   data: fixturesData,
  //   isError,
  // } = useFetch(
  //   "http://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/live_stats?json=1"
  // );

  const [activeFixture, setActiveFixture] = useUrlState({
    id: "",
  });

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("rugby");

  const handleSquadInputChange = (e) => {
    const { name, value } = e.target;

    setActiveFixture((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const [activeFixtures, setActiveFixtures] = useState([""]);
  useEffect(() => {
    if (fixturesData !== null) {
      setActiveFixtures(fixturesData?.data?.commentaries?.tournament.match);
    }
  }, [fixturesData]);

  // Set Active Fixture Data
  const [activeFixtureData, setActiveFixtureData] = useState(undefined);

  useEffect(() => {
    ActiveFixtureDataHelper(
      activeFixtures,
      fixturesData,
      activeFixture,
      setActiveFixtureData
    );
  }, [activeFixtures, fixturesData, activeFixture.id]);

  const [extractedMatchEvents, setExtractedMatchEvents] = useState(undefined);

  // Define Local Team Match Events
  useEffect(() => {
    const formattedEvents = SetLocalTeamMatchEvents(activeFixtureData);
    setExtractedMatchEvents((prevState) => ({
      ...prevState,
      localteam: formattedEvents,
    }));
  }, [activeFixtureData, activeFixture.id]);

  // Define Visitor Team Events
  useEffect(() => {
    const formattedEvents = SetVisitorTeamMatchEvents(activeFixtureData);

    setExtractedMatchEvents((prevState) => ({
      ...prevState,
      visitorteam: formattedEvents,
    }));
  }, [activeFixtureData, activeFixture.id]);

  const [formattedMatchEvents, setFormattedMatchEvents] = useState([]);

  useEffect(() => {
    const consolidatedEvents = ConsolidatedMatchEvents(extractedMatchEvents);
    setFormattedMatchEvents(consolidatedEvents);
  }, [extractedMatchEvents, activeFixture.id]);

  const [activeMatchEvents, setAciveMatchEvents] = useState({
    venue: "",
    city: "",
    time: "",
    date: "",
    localTeam: {
      name: "",
      score: "",
      flag: "",
      events: "",
      stats: {
        possession: "",
        passess: "",
        metres: "",
      },
    },
    visitorTeam: {
      name: "",
      score: "",
      flag: "",
      events: "",
      stats: {
        possession: "",
        passess: "",
        metres: "",
      },
    },
    date: "",
    venue: "",
    city: "",
    homeEvents: [],
    visitorTeamEvents: [],
  });

  useEffect(() => {
    if (activeFixtureData && graphicData?.international) {
      const matchingLocalTeam = graphicData?.international?.find(
        (item) => item.name === activeFixtureData?.localteam?.name
      );

      const matchingVisitorTeam = graphicData?.international?.find(
        (item) => item.name === activeFixtureData?.visitorteam?.name
      );

      if (matchingLocalTeam != undefined && matchingVisitorTeam != undefined) {
        setAciveMatchEvents({
          localTeam: {
            flag: matchingLocalTeam?.flag,
          },
          visitorTeam: {
            flag: matchingVisitorTeam?.flag,
          },
        });
      }
    }
  }, [graphicData?.international, activeFixtureData, activeFixture.id]);

  useEffect(() => {
    if (activeFixtureData && formattedMatchEvents) {
      setAciveMatchEvents((prevData) => ({
        date: activeFixtureData?.date,
        time: activeFixtureData?.time,
        venue: activeFixtureData?.venue?.name,
        city: activeFixtureData?.venue?.city,
        localTeam: {
          ...prevData.localTeam,
          name: activeFixtureData?.localteam?.name,
          score: activeFixtureData?.localteam?.score,
          events: formattedMatchEvents?.localteam,
          stats: {
            possession: activeFixtureData?.stats?.localteam?.possession.value,
            territory: activeFixtureData?.stats?.localteam?.territory.value,
            defendersBeaten:
              activeFixtureData?.stats?.localteam?.defenders_beaten.value,
            tackles: activeFixtureData?.stats?.localteam?.tackles.value,
          },
        },
        visitorTeam: {
          ...prevData.visitorTeam,
          name: activeFixtureData?.visitorteam?.name,
          score: activeFixtureData?.visitorteam?.score,
          events: formattedMatchEvents?.visitorteam,
          stats: {
            possession: activeFixtureData?.stats?.visitorteam?.possession.value,
            territory: activeFixtureData?.stats?.visitorteam?.territory.value,
            defendersBeaten:
              activeFixtureData?.stats?.visitorteam?.defenders_beaten.value,
            tackles: activeFixtureData?.stats?.visitorteam?.tackles.value,
          },
        },
      }));
    }
  }, [activeFixtureData, formattedMatchEvents]);

  // Download Image
  const imageFilename = "Score Update.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [activeFixture.id]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  if (isFixturesDataLoading) {
    return <CustomSpinner />;
  }
  return (
    <>
      {fixturesData && graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h3">
                Match Live Updates
              </Heading>
              <Flex mt={8} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Fixture"
                  name="id"
                  onChange={handleSquadInputChange}
                  value={activeFixture.id}
                >
                  {activeFixtures != undefined &&
                  Array.isArray(activeFixtures) ? (
                    activeFixtures.map((dailyFixture, index) => (
                      <option key={index} value={dailyFixture.id}>
                        {dailyFixture?.localteam?.name} -{" "}
                        {dailyFixture?.visitorteam?.name}
                      </option>
                    ))
                  ) : (
                    <option value={activeFixtures?.id}>
                      {`${activeFixtures?.localteam?.name} -
                        ${activeFixtures?.visitorteam?.name}`}
                    </option>
                  )}
                </Select>
              </Flex>
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              <RugbyInGameUpdatesArtwork
                activeMatchEvents={activeMatchEvents}
              />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default LiveRugbyScore;
