import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Pattern from "../../../../../assets/Horizontal pattern.svg";
import NoUnder18 from "../../../../../assets/clients/genericaClientAssets/No Under 18 Light.svg";
import HollyWoodBetsLogo from "../../../../../assets/clients/soccerLaduma/clientLogos/Holywood Bets Logo Light.svg";
import React from "react";

const HolyWoodBetsStrap = () => {
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        overflow="hidden"
        height="100px"
        backgroundColor="#5C2D91"
        zIndex="10"
      >
        <Image marginTop="0.5rem" src={HollyWoodBetsLogo} width="170px" />
        <Flex
          alignItems="center"
          width="100%"
          zIndex="100"
          backgroundColor="#5C2D91"
          fontSize="0.55rem"
          textAlign="center"
          height="50px"
          padding="1rem"
        >
          <Text
            fontSize="0.6rem"
            lineHeight="0.7rem"
            letterSpacing="0.3px"
            textAlign="center"
            textTransform="uppercase"
            fontFamily="Neue Power Bold"
            color="#fff"
          >
            Hollywood sportsbook is a licensed betting operator. Hollywoodbets
            supports responsible gambling. No persons under the age of 18 years
            are permitted to gamble. Winners know when to stop. South African
            responsible gaming foundation toll free counselling line 0800 006
            008 or WhatsApp help to 076 675 0710.
          </Text>
          <Image src={NoUnder18} paddingTop="0.5rem" height="35px" />
        </Flex>
      </Flex>

      {/* <Image position="relative" zIndex="100" src={Pattern} /> */}
    </>
  );
};

export default HolyWoodBetsStrap;
