import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

import PlaceHolderImage from "../../../../../assets/psl/squadImages/Sekhukhune United/V. Ohizu.png";
import SaImage from "../../../../../assets/womensWorldCup/flags/South-Africa.png";

import DstvPremLogo from "../../../../../assets/psl/DStv-Prem-logo.svg";
import KickoffLogo from "../../../../../assets/clients/soccerLaduma/Soccer Laduma Logo.png";
// Helpers
import { FormatLeagueYears } from "../../../../../helpers/FormatLeagueYears";
import HolyWoodBetsStrap from "../UxComponents/HolyWoodBetsStrap";

const StandingsArtwork = ({ activeClubStats }) => {
  const flagSize = {
    height: "auto",
    width: "80px",
    paddingBottom: "0.25rem",
  };

  const singleFixtureTime = {
    display: "flex",
    alignItems: "flex-end",
  };

  const singleStatContainer = {
    paddingY: "0.1rem",
  };

  const matchStatDescription = {
    // textAlign: "center",
    fontSize: "1rem",
  };

  const matchStatHeading = {
    textAlign: "center",
    fontSize: "1.1rem",
  };

  return (
    <>
      <Box
        overflow="hidden"
        height="700px"
        width="700px"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="artwork-container"
        backgroundColor="#1B1B1B"
      >
        <Heading
          textTransform="uppercase"
          className="fixture-heading__kick-off"
          variant="h1"
          fontSize="7.5rem"
          fontFamily="Neue Power Ultra"
          // marginTop="-0.5rem"
          marginLeft="1.5rem"
        >
          Profile
        </Heading>
        <Flex
          className="artwork-header"
          justifyContent="space-between"
          alignItems="center"
          margin="-11.25rem 2rem 0 2rem"
        >
          <Box zIndex="1">
            <Heading
              variant="h5"
              fontFamily="Neue Power Ultra"
              textTransform="uppercase"
              lineHeight="1.5rem"
              fontWeight="800"
              color="#fff"
            >
              {activeClubStats.name ? activeClubStats.name : ""}
              <Box color="#fff"> Profile</Box>
            </Heading>
          </Box>
          <Box>
            <Image
              src={require("../../../../../assets/clients/kickOff/KO Icon BW.png")}
              height="90px"
              width="90px"
            />
          </Box>
        </Flex>
        <Box
          className="artwork-body"
          display="flex"
          flexDir="column"
          // alignContent="center"
          flexWrap="wrap"
          height="400px"
          justifyContent="center"
          paddingX="2rem"
        >
          <Box
            className="stats container"
            borderRadius="1rem"
            backgroundColor="rgb(255,255,255, 0.7)"
            width="65%"
            border="1px solid #d2232a"
            padding="0.5rem"
            zIndex="100"
          >
            <Box className="trophy wins" borderBottom="1px solid #d2232a">
              <Flex
                className="flag-container"
                justifyContent="center"
                alignItems="center"
                flexDir="column"
              >
                <Image
                  src={
                    activeClubStats.flag !== ""
                      ? require(`../../../../../assets/soccer/${activeClubStats?.league}/flags/${activeClubStats.flag}`)
                      : SaImage
                  }
                  sx={flagSize}
                />
              </Flex>
              <Heading variant="h6" sx={matchStatHeading} textAlign="center">
                Premiership Trophies
              </Heading>
              <Heading
                variant="h6"
                sx={matchStatDescription}
                textAlign="center"
              >
                {activeClubStats?.trophies?.dstvPrem != undefined &&
                activeClubStats?.trophies?.dstvPrem["@count"]
                  ? activeClubStats?.trophies?.dstvPrem["@count"]
                  : "0"}
              </Heading>
              <Text
                textSize="0.8rem"
                lineHeight="1rem"
                variant="p"
                textAlign="center"
                letterSpacing="1px"
              >
                {activeClubStats?.trophies?.dstvPrem != undefined &&
                activeClubStats?.trophies?.dstvPrem["@seasons"]
                  ? FormatLeagueYears(
                      activeClubStats?.trophies?.dstvPrem["@seasons"]
                    )
                  : null}
              </Text>

              <Flex justifyContent="space-between" sx={singleStatContainer}>
                <Heading variant="h6" sx={matchStatHeading}>
                  Coach
                </Heading>

                <Heading variant="h6" sx={matchStatDescription}>
                  {activeClubStats.coach && activeClubStats.coach !== ""
                    ? activeClubStats.coach
                    : "Coach Name"}
                </Heading>
              </Flex>
            </Box>

            <Box className="head-to-head__total-games" paddingTop="0.5rem">
              <Flex justifyContent="space-between" sx={singleStatContainer}>
                <Heading variant="h6" sx={matchStatHeading}>
                  Clean sheets
                </Heading>

                <Heading variant="h6" sx={matchStatDescription}>
                  {activeClubStats.stats.cleanSheets &&
                  activeClubStats.stats.cleanSheets !== ""
                    ? activeClubStats.stats.cleanSheets
                    : "0"}
                </Heading>
              </Flex>

              <Flex justifyContent="space-between" sx={singleStatContainer}>
                <Heading variant="h6" sx={matchStatHeading}>
                  Games failed to score
                </Heading>

                <Heading variant="h6" sx={matchStatDescription}>
                  {activeClubStats.stats.failedToScore &&
                  activeClubStats.stats.failedToScore !== ""
                    ? activeClubStats.stats.failedToScore
                    : "0"}
                </Heading>
              </Flex>

              <Flex justifyContent="space-between" sx={singleStatContainer}>
                <Heading variant="h6" sx={matchStatHeading}>
                  Average goals Conceded
                </Heading>

                <Heading variant="h6" sx={matchStatDescription}>
                  {activeClubStats.stats.avgGoalsConceded &&
                  activeClubStats.stats.avgGoalsConceded !== ""
                    ? activeClubStats.stats.avgGoalsConceded
                    : "0"}
                </Heading>
              </Flex>

              <Flex justifyContent="space-between" sx={singleStatContainer}>
                <Heading variant="h6" sx={matchStatHeading}>
                  Average goals scored
                </Heading>

                <Heading variant="h6" sx={matchStatDescription}>
                  {activeClubStats.stats.avgGoalsScored &&
                  activeClubStats.stats.avgGoalsScored !== ""
                    ? activeClubStats.stats.avgGoalsScored
                    : "0"}
                </Heading>
              </Flex>

              <Flex
                justifyContent="center"
                sx={singleStatContainer}
                flexDir="column"
              >
                <Heading variant="h6" sx={matchStatHeading}>
                  Goals scored per minute
                </Heading>
                <Box>
                  {activeClubStats !== "" &&
                    activeClubStats?.stats?.scoringMinutes?.map(
                      (minute, index) => (
                        <Flex
                          key={index}
                          justifyContent="space-between"
                          gap="0.25rem"
                          color={index % 2 === 0 ? "#000" : "kickOffRed.900"}
                        >
                          <Box width="75px">
                            <Heading variant="h6" sx={matchStatDescription}>
                              {minute["@min"] !== undefined
                                ? minute["@min"]
                                : 0}
                            </Heading>
                          </Box>
                          <Heading
                            variant="h6"
                            sx={matchStatDescription}
                            textAlign="left"
                          >
                            {minute["@pct"] !== undefined ? minute["@pct"] : 0}
                          </Heading>
                          <Heading variant="h6" sx={matchStatDescription}>
                            {minute["@count"] !== undefined
                              ? minute["@count"]
                              : 0}
                          </Heading>
                        </Flex>
                      )
                    )}
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
        <HolyWoodBetsStrap />

        <Image
          src={
            activeClubStats.playerImage != ""
              ? require(`../../../../../assets/soccer/${activeClubStats?.league}/squadImages/${activeClubStats.name}/${activeClubStats.playerImage}`)
              : PlaceHolderImage
          }
          marginBottom="120px"
          position="fixed"
          bottom="-50"
          right="0"
          height="550px"
          zIndex="1"
        />
      </Box>
    </>
  );
};

export default StandingsArtwork;
