import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ConditionalSidebar = ({ children }) => {
  const location = useLocation();

  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    setShowNavbar(location.pathname === "/account" ? false : true);
  }, [location]);

  return <div>{showNavbar && children}</div>;
};

export default ConditionalSidebar;
