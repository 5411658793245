import React, { useEffect, useState } from "react";
import Logo from "../../../assets/RHESTSub_Logo.svg";
import {
  Box,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link, Link as RouterLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const Navbar = () => {
  const menuItemStyling = {
    background: "#fff8ef",
  };

  const menuItemNameStyling = {
    fontFamily:
      "'Neue Power Bold', Arial, 'Helvetica Neue', Helvetica, sans-serif",
    fontWeight: "bold",
    fontSize: { base: "3.05rem", md: "3.81rem" },
    textTransform: "uppercase",
    textAlign: { base: "left", md: "center" },
    paddingLeft: { base: "2rem", md: "0" },
  };

  const menuItemInlineContainer = {
    justifyContent: "center",
    alignItems: "center",
    width: { base: "", md: "100%" },
    gap: "1rem",
  };

  const inlineTextDescription = {
    width: "10rem",
    marginBottom: "-0.5rem",
    display: { base: "none", md: "block" },
  };

  // Framer Motion
  const MotionBox = motion(Box);
  const MotionMenuButton = motion(MenuButton);
  const MotionImage = motion(Image);

  const borderVariants = {
    hidden: {
      width: 0,
    },
    visible: {
      width: "100%",
      transition: { delay: 0.5, duration: 1 },
    },
  };

  const menuVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.25, duration: 1 },
    },
  };

  const menuItems = [
    {
      name: "Home",
      url: "/",
      description: "Brief </br> Introduction",
    },
    {
      name: "About",
      url: "/about",
      description: "Company Background",
    },
    {
      name: "How It Works",
      url: "/how-it-works",
      description: "Data feeds & artwork generation",
    },
    {
      name: "Contact",
      url: "/contact",
      description: "Queries & social media",
    },
  ];

  // Active Location state for styling
  const location = useLocation();

  // Prevent Scroll when open
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    document.body.style.overflow = "auto";
  };

  // Set Dashboard url
  const [dashboardUrl, setdashboardUrl] = useState("");

  useEffect(() => {
    const currentProtocol = window.location.protocol;
    const currentHost = window.location.host;
    const subdomainUrl = `${currentProtocol}//dashboard.${currentHost}`;

    setdashboardUrl(subdomainUrl);
  }, []);

  return (
    <>
      <Flex
        justifyContent="space-between"
        padding={{ base: "1rem", md: "1rem 2rem" }}
        alignItems="center"
      >
        <MotionImage
          variants={menuVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          src={Logo}
          width="210px"
        />
        <Menu closeOnSelect isOpen={menuOpen} onClose={handleMenuClose}>
          <MotionMenuButton
            onClick={handleMenuOpen}
            fontFamily="'Neue Power Bold', Arial, 'Helvetica Neue', Helvetica, sans-serif"
            fontWeight="bold"
            fontSize="1.56rem"
            textTransform="uppercase"
            letterSpacing="1.5px"
            variants={menuVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            Menu
          </MotionMenuButton>
          <MenuList
            width="100vw"
            height={{ base: "86vh", md: "95vh", lg: "92vh" }}
            marginTop="1vh"
            background="#fff8ef"
            borderTop="1px solid #e5a329"
            display="flex"
            flexDir="column"
            justifyContent="center"
            marginBottom="0"
          >
            <Box>
              {menuItems.map((menuItem, index) => (
                <Box key={index} className="menu-item-container">
                  <Link to={menuItem.url}>
                    <MenuItem sx={menuItemStyling}>
                      <Flex sx={menuItemInlineContainer}>
                        <Heading sx={menuItemNameStyling}>
                          {menuItem.name}
                        </Heading>
                        <Text
                          className={`menu-item__description ${
                            location.pathname === menuItem.url ? "active" : ""
                          }`}
                          variant="p"
                          as="p"
                          sx={inlineTextDescription}
                          dangerouslySetInnerHTML={{
                            __html: menuItem.description,
                          }}
                        />
                      </Flex>
                    </MenuItem>
                  </Link>
                </Box>
              ))}
            </Box>
          </MenuList>
        </Menu>
      </Flex>
      <MotionBox
        variants={borderVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        borderBottom="1px solid #e5a329"
      />
    </>
  );
};

export default Navbar;
