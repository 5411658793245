import { Box, Flex, Grid, GridItem, Heading, Image } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router";
import ClubFlag from "../../../../../assets/psl/flags/Kaizer Chiefs.png";
// Logos
import DstvPremLogo from "../../../../../assets/psl/DStv-Prem-logo.svg";
import RugbyWorldCupLogo from "../../../../../assets/rugby/Competitions/Rugby World Cup Logo.svg";
import KickoffLogo from "../../../../../assets/clients/soccerLaduma/Soccer Laduma Logo.png";
// Images
import RugbyHomePlayer from "../../../../../assets/rugby/International/Players/South Africa/F. de Klerk 2.png";
import soccerHomePlayer from "../../../../../assets/soccer/1204/squadImages/Manchester United/R. Varane.png";
import soccerAwayPlayer from "../../../../../assets/soccer/1204/squadImages/Brentford/A. Hickey.png";
import SoccerPlayer from "../../../../../assets/psl/squadImages/Orlando Pirates/M. Saleng.png";
import HolyWoodBetsStrap from "../UxComponents/HolyWoodBetsStrap";
// import flags from "../../../../../assets/soccer/1394/flags/";

const HeadToHeadResultsArtworkSl = ({ activeHeadToHeadResults }) => {
  // Styling
  const flagSize = {
    height: "auto",
    width: "65px",
  };
  const rugbyflagSize = {
    height: "auto",
    width: "70px",
  };

  const scoreStyling = {
    textAlign: "center",
    paddingTop: "0.25rem",
  };
  const location = useLocation();
  return (
    <Box
      overflow="hidden"
      height="700px"
      width="700px"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="artwork-container"
      backgroundColor="kickOffBackground.900"
    >
      <Heading
        textTransform="uppercase"
        className="fixture-heading__kick-off"
        variant="h1"
        marginTop="1rem"
        fontSize="4.3rem"
        fontFamily="Neue Power Ultra"
        // textAlign="center"
        marginLeft="1.5rem"
        height="120px"
        paddingBottom={
          location.pathname == "/rugby/world-cup/head-to-head-results"
            ? "0"
            : "1rem"
        }
      >
        Head To Head
      </Heading>
      <Flex
        className="artwork-header"
        justifyContent="space-between"
        alignItems="center"
        margin={
          location.pathname == "/rugby/world-cup/head-to-head-results"
            ? "-8.75rem 2rem 0 2rem"
            : "-7.25rem 2rem 0 2rem"
        }
      >
        <Box zIndex="1" className="title-container">
          <Heading
            variant="h5"
            fontFamily="Neue Power Ultra"
            textTransform="uppercase"
            lineHeight="1.5rem"
            fontWeight="800"
            color="#fff"
          >
            {activeHeadToHeadResults?.teamOneName
              ? activeHeadToHeadResults?.teamOneName
              : "Team One"}
            <br />
            {activeHeadToHeadResults?.teamTwoName
              ? activeHeadToHeadResults?.teamTwoName
              : "Team Two"}
            <Box color="#fff">Head To Head</Box>
          </Heading>
        </Box>
        <Box>
          <Image
            src={require("../../../../../assets/clients/kickOff/KO Icon BW.png")}
            height="90px"
            width="90px"
          />
        </Box>
      </Flex>

      <Flex
        flexDir="column"
        className="artwork-container"
        margin="0 2rem 0 2rem"
        justifyContent="center"
        // flexWrap="wrap"
        height="530px"
      >
        <Grid gridTemplateColumns="25% 50% 25%">
          <GridItem>
            <Image
              src={
                location.pathname === "/rugby/world-cup/head-to-head"
                  ? RugbyHomePlayer
                  : activeHeadToHeadResults?.teamOneName &&
                    activeHeadToHeadResults?.teamOnePlayerImage
                  ? require(`../../../../../assets/soccer/${activeHeadToHeadResults?.league}/squadImages/${activeHeadToHeadResults.teamOneName}/${activeHeadToHeadResults.teamOnePlayerImage}`)
                  : soccerHomePlayer
              }
              marginBottom="100px"
              position="fixed"
              bottom="-30"
              right="380"
              height="550px"
              zIndex="0"
            />
          </GridItem>
          <GridItem
            className="head-to-head"
            borderRadius="1rem"
            backgroundColor="rgb(255,255,255, 0.7)"
            border={
              location.pathname == "/rugby/world-cup/head-to-head-results"
                ? "1px solid #BF1A2F"
                : "1px solid #d2232a"
            }
            padding="0.5rem"
            zIndex="100"
            position="relative"
            display="flex"
            flexDir="column"
            height="100%"
            alignContent="center"
          >
            {activeHeadToHeadResults?.results &&
              activeHeadToHeadResults?.results?.length > 0 &&
              activeHeadToHeadResults?.results?.map((fixture, index) => (
                <Box
                  className="head-to-head__fixture"
                  key={index}
                  // marginY="0.25rem"
                >
                  <Flex
                    className="head-to-head__score"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Flex className="home-team-container" flexDir="column">
                      <Image
                        src={
                          fixture?.teamOneFlag
                            ? require(`../../../../../assets/soccer/${activeHeadToHeadResults?.league}/flags/${fixture?.teamOneFlag}`)
                            : ClubFlag
                        }
                        sx={flagSize}
                      />
                      <Heading variant="h5" sx={scoreStyling}>
                        {fixture?.["@team1_score"]
                          ? fixture?.["@team1_score"]
                          : "0"}
                      </Heading>
                    </Flex>
                    <Box date-container>
                      <Heading
                        variant="h6"
                        fontSize="1rem"
                        textAlign="center"
                        color="#000"
                      >
                        {fixture?.["@date"] ? fixture?.["@date"] : "13.08.2022"}
                      </Heading>
                    </Box>
                    <Flex className="away-team-container" flexDir="column">
                      <Image
                        src={
                          fixture?.teamTwoFlag
                            ? require(`../../../../../assets/soccer/${activeHeadToHeadResults?.league}/flags/${fixture?.teamTwoFlag}`)
                            : ClubFlag
                        }
                        sx={flagSize}
                      />
                      <Heading variant="h5" sx={scoreStyling}>
                        {fixture?.["@team2_score"]
                          ? fixture?.["@team2_score"]
                          : "0"}
                      </Heading>
                    </Flex>
                  </Flex>
                </Box>
              ))}
          </GridItem>
          <GridItem>
            <Image
              src={
                location.pathname === "/rugby/world-cup/head-to-head"
                  ? RugbyHomePlayer
                  : activeHeadToHeadResults?.teamTwoName &&
                    activeHeadToHeadResults?.teamTwoPlayerImage &&
                    activeHeadToHeadResults?.league != ""
                  ? require(`../../../../../assets/soccer/${activeHeadToHeadResults?.league}/squadImages/${activeHeadToHeadResults?.teamTwoName}/${activeHeadToHeadResults?.teamTwoPlayerImage}`)
                  : soccerAwayPlayer
              }
              marginBottom="110px"
              position="fixed"
              bottom="-30"
              right="-15"
              height="550px"
              zIndex="0"
            />
          </GridItem>
        </Grid>
      </Flex>
      <HolyWoodBetsStrap />
    </Box>
  );
};

export default HeadToHeadResultsArtworkSl;
