import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme ({
    
    fonts : {
    body: "'Neue Power Medium', Arial, 'Helvetica Neue', Helvetica, sans-serif",
    heading: "'Neue Power Bold', Arial, 'Helvetica Neue', Helvetica, sans-serif"
    },
    colors : {
      brand: {
        900: "#E4A428",
        500: "#fbe1bf",
        100: "#fff8ef"
      },
      rhestsubRugbyMaroon: {
        900: "#BF1A2F"
      },
      rhestsubRed: {
        900: "#FF530D"
      },
      hollywoodBetsPurple: {
        900: "#5C2D91"
      },
      hollywoodBetsYellow: {
        900: "#FFDE17"
      },
      kickOffRed: {
        900: "#d2232a"
      },
      kickOffBackground: {
        900: "#1B1B1B"
        
      }
  },
  Flex: {
    variants: {
      buttonContainer: {
        mt:"2rem", 
        mb: "2rem"
      }
    },
  },
    components : {
        Heading: {
          variants: {
            h1: {
              fontSize: "3.81rem",
              fontWeight:"bold",
              letterSpacing: "1.5px",
              paddingBottom: "1rem",
            },
            h2: {
              fontSize: "3.05rem",
              fontWeight:"bold",
              letterSpacing: "1.5px",
              paddingBottom: "0.5rem",
            },
            h3: {
              fontSize: "2.44rem",
              fontWeight:"bold",
             
              letterSpacing: "1.5px",
              paddingBottom: "0.25rem",
             
            },
            h4: {
              fontSize: "1.95rem",
              fontWeight:"bold",
              letterSpacing: "1.5px",
              // paddingBottom: "1rem",
            },
            h5: {
              fontSize: "1.56rem",
              fontWeight:"bold",
              letterSpacing: "1.5px",
              // paddingBottom: "1rem",
            },
            h6: {
              fontSize: "1.24rem",
              fontWeight:"bold",
              letterSpacing: "1.5px",
              // paddingBottom: "1rem",
            },
            KoHwbScoreTitle : {
              color: "#fff",
              fontSize: "3.81rem",
              fontWeight:"bold",
              letterSpacing: "1.5px",
              paddingBottom: "1rem",
              textAlign: "center"
            },
            KoHwbMatchStats : {
              textAlign: "center",
              textTransform: "uppercase",
              fontSize: "1rem",
              color: "#fff",
            },
            
          },  
        },
        Text: {
          p: {
              fontSize: "1rem", 
              fontWeight: "normal",
              letterSpacing: "2px"
          }
        },
        Button: {
          variants: {
            outlineButton: {
              width:"100%",
              mt:5, 
              border: "1px solid #e5a428",
              fontFamily: "'Neue Power Bold', Arial, 'Helvetica Neue', Helvetica, sans-serif",
              letterSpacing: "1.5px",
              fontSize:"0.9rem",
              background: "none",
              borderRadius: "20px",
              _hover: {
              background: "#e5a428", 
              border: "1px solid #e5a428",
              } 
            },
            yellowButton: {
              width:"100%",
              mt:5, 
              border: "1px solid #e5a428",
              fontFamily: "'Neue Power Bold', Arial, 'Helvetica Neue', Helvetica, sans-serif",
              letterSpacing: "1.5px",
              fontSize:"0.9rem",
              background: "#e5a428", 
              borderRadius: "20px",
              _hover: {
                  background: "none",
                  border: "1px solid #e5a428",
              }
            },
          }
        },
        Textarea: {
          variants: {
            outlineTextArea: {
              width:"100%",
              h:"30px",
              fontSize: "0.85rem",
              borderTop:'none',
              borderLeft:'none',
              borderRight:'none',
              borderBottom: '1px solid #e5a428',
              borderTop: '1px solid #e5a428',
              borderRadius: "0",
              background:"none",
              p: "0",
              color:"#000"
            }
          }
        },
        Select: {
          variants: {
            outlineSelect: {
              width: "100%",
              h: "30px",
              fontSize: "0.85rem",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid #e5a428",
              borderRadius: "0",
              p: "0",
              color: "#000",
            }
          }
        },
        Image: {
          variants: {
            kickOffLogo: {
              height: "90px",
              width: "90px",
            }
          }
        },
        Input: {
          variants: {
            outlineInput: {
              width:"100%",
              fontSize: "0.85rem",
              border:'none',
              borderBottom: '1px solid #e5a428',
              borderRadius: "0",
              color:"#000",
              outline: "none",
              p: "0",
              h:"30px",
                '_active' :{
                  outline: "none",
               }
            }
          }
        }, 
    }
})

export default theme