import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// Animation
import { useInView } from "react-intersection-observer";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

const DataFeeds = () => {
  const accordionButtonStyling = {
    display: "flex",
    justifyContent: "space-between",

    _hover: { background: "#e5a428" },
    _expanded: {
      borderBottom: "1px solid #e5a428",
    },
  };

  const dataFeedList = [
    {
      name: "Soccer",
      competitions: [
        "CAF Champions League",
        "AFCON",
        "DStvPrem",
        "International Qualifiers",
      ],
      comingSoon: "",
      description: [
        "Live scores",
        "Live goals, cards and subs",
        "Fixtures and results",
        "Standings",
        "Team profiles",
        "Player profiles",
        "historical results",
      ],
    },
    {
      name: "Rugby",
      competitions: [
        "World Cup",
        "Sevens World Cup",
        "Test Rugby",
        "United Rugby Championships",
      ],
      comingSoon: "",
      description: ["Live scores", "Live game stats", "Standings", "Fixtures"],
    },
    {
      name: "Cricket",
      competitions: [
        "World Cup",
        "International ODI's",
        "SA 20",
        "Momentum One Day Cup",
        "4 Day Series",
        "T20 Challenge",
      ],
      comingSoon: "Coming soon",
      description: [
        "Live scorecards",
        "Ball by ball",
        " Lineups",
        "Standings",
        "Series schedules",
        "Player profiles",
        "Team squads",
        "historical results",
      ],
    },
  ];

  // TypeWriter Anmimation
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [typewriterStarted, setTypewriterStarted] = useState(false);

  useEffect(() => {
    if (inView && !typewriterStarted) {
      const timeoutId = setTimeout(() => {
        setTypewriterStarted(true);
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inView, typewriterStarted]);

  // Framer Motion
  const MotionText = motion(Text);
  const MotionAccordion = motion(Accordion);

  const textVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 2, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 2, duration: 1 },
    },
  };

  const accordionVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 2.5, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 2.5, duration: 1 },
    },
  };

  return (
    <>
      <Grid
        gridTemplateColumns={{ base: "100%", lg: "40% 60%" }}
        margin="4rem 2rem 5rem 2rem"
        height={{ base: "90vh", md: "70vh", xl: "60vh" }}
        ref={ref}
      >
        <GridItem flexDir="column" display="flex" justifyContent="center">
          <Box>
            <Heading variant="h2">
              <Typewriter
                options={{
                  strings: "Data Feeds",
                  autoStart: typewriterStarted,
                  delay: 30,
                  pauseFor: 1000000,
                  cursor: "",
                }}
              />
            </Heading>
            <MotionText
              variant="p"
              as="p"
              paddingBottom="1rem"
              margin="auto"
              variants={textVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              All sporting artworks are powered by data feeds timeously updated
              throughout the season. Additionally, depending on the competition,
              historic data feeds can be utilised in your social media content
              strategy. For example, historic head to head and team profile
              stats, which include number of trophies won as well as seasonal
              performances can be generated during the build up to a game.
            </MotionText>
          </Box>
        </GridItem>
        <GridItem
          margin={{ base: "1rem 0", lg: "0 0 0 2rem" }}
          display="flex"
          alignItems="center"
        >
          <MotionAccordion
            allowToggle
            width="100%"
            variants={accordionVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {dataFeedList.map((feed, index) => (
              <AccordionItem
                key={index}
                borderBottom="1px solid #e5a428"
                _first={{
                  borderTop: "1px solid #e5a428",
                }}
              >
                <AccordionButton sx={accordionButtonStyling}>
                  <Flex alignItems="flex-end">
                    <Heading textAlign="left" width="8rem" variant="h4">
                      {feed.name}
                    </Heading>
                    <Text variant="p" marginBottom="0.1rem">
                      {feed.comingSoon}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Flex
                    justifyContent="space-between"
                    gap="1rem"
                    paddingTop="0.5rem"
                    paddingBottom="0.75rem"
                    borderBottom="1px solid #e5a428"
                    flexWrap="wrap"
                  >
                    {feed.competitions.map((competition, index) => (
                      <Heading key={index} fontSize="1rem" variant="h6">
                        {competition}
                      </Heading>
                    ))}
                  </Flex>
                  <Grid
                    paddingTop="0.75rem"
                    gridTemplateColumns={{
                      base: "repeat(3, 1fr)",
                      sm: "repeat(4, 1fr)",
                    }}
                  >
                    {feed.description.map((description, index) => (
                      <GridItem key={index}>
                        <Text
                          textAlign="center"
                          lineHeight="1rem"
                          paddingY="0.5rem"
                        >
                          {description}
                        </Text>
                      </GridItem>
                    ))}
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </MotionAccordion>
        </GridItem>
      </Grid>
    </>
  );
};

export default DataFeeds;
