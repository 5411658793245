// Create a helper function as an arrow function
export const SetVisitorTeamMatchEvents = (activeFixtureData) => {
  if (activeFixtureData == null) {
    return [];
  }

  const visitorTeamMatchEvents = activeFixtureData?.summary?.visitorteam;

  const formatEvent = (eventData, category) => {
    if (eventData == null) {
      return [];
    }

    const events = Array.isArray(eventData.player)
      ? eventData.player.map((item) => ({
          ...item,
          category,
          minute: parseInt(item.minute, 10),
        }))
      : [
          {
            category,
            minute: parseInt(eventData.player?.minute, 10),
            name: eventData.player?.name,
          },
        ];

    return events;
  };

  const formattedVisitorTeamConversions = formatEvent(
    visitorTeamMatchEvents.conversions,
    "Conversion"
  );

  const formattedVisitorTeamTries = formatEvent(
    visitorTeamMatchEvents?.tries,
    "Try"
  );

  const formattedVisitorTeamPenaltyGoals = formatEvent(
    visitorTeamMatchEvents?.penaltyGoals,
    "Penalty"
  );

  const formattedVisitorTeamPenaltyTries = formatEvent(
    visitorTeamMatchEvents?.tries?.penaltyTries,
    "Penalty Try"
  );

  const formattedVisitorTeamYelowCards = formatEvent(
    visitorTeamMatchEvents?.yellowcards,
    "Yellow Card"
  );

  const combinedFormattedEvents = [
    ...formattedVisitorTeamConversions,
    ...formattedVisitorTeamTries,
    ...formattedVisitorTeamPenaltyGoals,
    ...formattedVisitorTeamPenaltyTries,
    ...formattedVisitorTeamYelowCards,
  ];

  combinedFormattedEvents.sort((a, b) => a.minute - b.minute);

  return combinedFormattedEvents;
};
