export const formatPlayerName = (name) => {
  if (typeof name !== "string" || name.trim() === "") {
    return name; // Return the name itself if it's empty or not a valid string.
  }

  const parts = name.split(" ");

  // Check if the name has at least two parts before proceeding.
  if (parts.length < 2) {
    return name; // Return the name itself if it doesn't have at least two parts.
  }

  const initials = parts[0][0] + ".";
  let lastName = parts[parts.length - 1];

  // Check if the last name has multiple parts.
  if (parts.length > 2) {
    lastName = parts.slice(1, parts.length - 1).join(" ") + " " + lastName;
  }

  return initials + " " + lastName;
};
