import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import ArgentinaImage from "../../../../../assets/rugby/International/South Africa.png";
import DstvLogo from "../../../../../assets/rugby/Competitions/Rugby World Cup Logo.png";
import { SaTimeHelper } from "../../../../../helpers/SaTimeHelper";
import Pattern from "../../../../../assets/rugby/Patterns/Rugby Horizontal pattern.svg";
import { formatPlayerName } from "../../../../../helpers/FormatPlayerName";
import React from "react";

const RugbyInGameUpdatesArtwork = ({ activeMatchEvents }) => {
  const flagSize = {
    height: "auto",
    width: "80px",
  };

  const bodyTextStyling = {
    fontSize: "1rem",
    lineHeight: "1.25rem",
    letterSpacing: "1px",
  };

  const bodyScoreTextStyling = {
    fontSize: "0.9rem",
    color: "rhestsubRugbyMaroon.900",
    // lineHeight: "1.25rem",
    letterSpacing: "1px",
  };

  const playerNameStyling = {
    fontSize: "0.9rem",
    // lineHeight: "1.25rem",
    letterSpacing: "1px",
    minWidth: "100px",
  };

  const categoryStyling = {
    fontSize: "0.9rem",
    lineHeight: "1.25rem",
    letterSpacing: "1px",

    width: "100px",
  };

  const introTextStyling = {
    fontSize: "1rem",
  };

  const subHeadingStyle = {
    fontSize: "1rem",
    TextAlign: "center",
  };

  const OgContainer = {
    color: "rhestsubRed.900",
    width: "30px",
    textAlign: "right",
    marginRight: "5px",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    letterSpacing: "1px",
  };

  return (
    <Box
      overflow="hidden"
      height="700px"
      width="700px"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="artwork-container"
    >
      <Box className="artwork-header">
        {/* <Image src={HeadingBackground} /> */}
        <Heading
          textTransform="uppercase"
          className="fixture-heading"
          variant="h1"
          fontSize="8.5rem"
          fontFamily="Neue Power Ultra"
          textAlign="center"
        >
          Updates
        </Heading>
        <Flex
          className="artwork-heading"
          justifyContent="space-between"
          alignItems="center"
          margin="-9rem 2rem 0 2rem"
          // background={`url(${HeadingBackground})`}
          backgroundSize="300px 100px"
        >
          <Box zIndex="1">
            <Heading
              variant="h5"
              fontFamily="Neue Power Ultra"
              textTransform="uppercase"
              lineHeight="1.5rem"
              fontWeight="800"
            >
              In
              <Box color="rhestsubRugbyMaroon.900">Game</Box>
              Updates
            </Heading>
          </Box>
          <Box zIndex="1">
            <Image src={DstvLogo} height="90px" />
          </Box>
        </Flex>
      </Box>
      <Box
        className="goal-container"
        display="flex"
        flexDir="column"
        paddingX="2rem"
        justifyContent="center"
      >
        <Flex justifyContent="space-between">
          <Box marginBottom="1rem">
            <Heading variant="h6">
              {activeMatchEvents?.venue
                ? `${activeMatchEvents?.venue}, ${activeMatchEvents?.city}`
                : "Cape Town Stadium"}
            </Heading>
          </Box>
          <Box marginBottom="1rem">
            <Heading
              variant="h6"
              color="rhestsubRugbyMaroon.900"
              textAlign="right"
            >
              {activeMatchEvents?.date
                ? `${activeMatchEvents?.date}, ${SaTimeHelper(
                    activeMatchEvents?.time,
                    2
                  )}`
                : "2 12 2023 17:00"}
            </Heading>
          </Box>
        </Flex>
        <Box
          className="score-update-container"
          borderRadius="1rem"
          backgroundColor="rgb(255,255,255, 0.7)"
          width="100%"
          border="1px solid #BF1A2F"
          padding="0.5rem"
          zIndex="100"
        >
          <Flex className="logo-container" justifyContent="space-between">
            <Image
              src={
                activeMatchEvents?.localTeam?.flag !== "" &&
                activeMatchEvents?.localTeam?.flag
                  ? require(`../../../../../assets/rugby/International/${activeMatchEvents?.localTeam?.flag}`)
                  : ArgentinaImage
              }
              sx={flagSize}
            />
            <Image
              src={
                activeMatchEvents?.visitorTeam?.flag
                  ? require(`../../../../../assets/rugby/International/${activeMatchEvents?.visitorTeam?.flag}`)
                  : ArgentinaImage
              }
              sx={flagSize}
            />
          </Flex>

          <Box
            className="stats-container"
            marginX="0.5rem"
            paddingY="0.5rem"
            borderBottom="1px solid #BF1A2F"
          >
            <Flex justifyContent="space-between">
              <Heading variant="h6">
                {activeMatchEvents?.localTeam?.stats?.possession != ""
                  ? activeMatchEvents?.localTeam?.stats?.possession
                  : "50"}
              </Heading>
              <Heading variant="h6">Possession</Heading>
              <Heading variant="h6">
                {activeMatchEvents?.visitorTeam?.stats?.possession != ""
                  ? activeMatchEvents?.visitorTeam?.stats?.possession
                  : "50"}
              </Heading>
            </Flex>
            <Flex justifyContent="space-between">
              <Heading variant="h6">
                {activeMatchEvents?.localTeam?.stats?.territory != ""
                  ? activeMatchEvents?.localTeam?.stats?.territory
                  : "50"}
              </Heading>
              <Heading variant="h6">Territory</Heading>
              <Heading variant="h6">
                {activeMatchEvents?.visitorTeam?.stats?.territory != ""
                  ? activeMatchEvents?.visitorTeam?.stats?.territory
                  : "50"}
              </Heading>
            </Flex>
            <Flex justifyContent="space-between">
              <Heading variant="h6">
                {activeMatchEvents?.localTeam?.stats?.defendersBeaten != ""
                  ? activeMatchEvents?.localTeam?.stats?.defendersBeaten
                  : "50"}
              </Heading>
              <Heading variant="h6">Defenders Beaten</Heading>
              <Heading variant="h6">
                {activeMatchEvents?.visitorTeam?.stats?.defendersBeaten != ""
                  ? activeMatchEvents?.visitorTeam?.stats?.defendersBeaten
                  : "50"}
              </Heading>
            </Flex>
            <Flex justifyContent="space-between">
              <Heading variant="h6">
                {activeMatchEvents?.localTeam?.stats?.tackles != ""
                  ? activeMatchEvents?.localTeam?.stats?.tackles
                  : "50"}
              </Heading>
              <Heading variant="h6">Tackles</Heading>
              <Heading variant="h6">
                {activeMatchEvents?.visitorTeam?.stats?.tackles != ""
                  ? activeMatchEvents?.visitorTeam?.stats?.tackles
                  : "50"}
              </Heading>
            </Flex>
          </Box>
          <Grid gridTemplateColumns="50% 50%" marginX="0.5rem">
            <GridItem>
              <Flex flexDir="column">
                <Heading variant="h1" paddingBottom="0">
                  {activeMatchEvents?.localTeam?.score !== ""
                    ? activeMatchEvents?.localTeam?.score
                    : "0"}
                </Heading>

                <Flex flexDir="column">
                  {activeMatchEvents.localTeam?.events?.length > 0 ? (
                    activeMatchEvents.localTeam?.events?.map(
                      (homeEvent, index) =>
                        homeEvent.minute !== 0 ? (
                          <Flex key={index}>
                            <Text sx={categoryStyling} paddingRight="0.5rem">
                              {homeEvent.category}
                            </Text>
                            <Text sx={playerNameStyling} paddingRight="0.5rem">
                              {formatPlayerName(homeEvent.name)}
                            </Text>
                            {homeEvent.minutes.map((homeEventMinute, index) => (
                              <Flex key={index}>
                                {index > 0 && ", "}{" "}
                                <Text sx={bodyScoreTextStyling}>
                                  {" "}
                                  {homeEventMinute}'
                                </Text>
                              </Flex>
                            ))}
                          </Flex>
                        ) : null
                    )
                  ) : (
                    <></>
                  )}
                </Flex>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex
                flexDir="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                width="100%"
                className="away-team-score-container"
              >
                <Heading variant="h1" paddingBottom="0">
                  {activeMatchEvents?.visitorTeam?.score !== ""
                    ? activeMatchEvents?.visitorTeam?.score
                    : "0"}
                </Heading>

                {activeMatchEvents.visitorTeam?.events?.length > 0 ? (
                  activeMatchEvents.visitorTeam?.events.map(
                    (visitorEvent, index) =>
                      visitorEvent.minute !== 0 ? (
                        <Flex key={index}>
                          {visitorEvent.minutes.map(
                            (visitorEventMinute, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && ", "}{" "}
                                <Text sx={bodyScoreTextStyling}>
                                  {" "}
                                  {visitorEventMinute}'
                                </Text>
                              </React.Fragment>
                            )
                          )}
                          <Text sx={playerNameStyling} paddingLeft="0.5rem">
                            {formatPlayerName(visitorEvent.name)}
                          </Text>
                          <Text sx={categoryStyling} textAlign="right">
                            {formatPlayerName(visitorEvent.category)}
                          </Text>
                        </Flex>
                      ) : null
                  )
                ) : (
                  <></>
                )}
              </Flex>
            </GridItem>
          </Grid>
        </Box>
      </Box>

      <Image src={Pattern} />
    </Box>
  );
};

export default RugbyInGameUpdatesArtwork;
