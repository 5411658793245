import React from "react";
import SubHeader from "../components/SubHeader";
import PageIntro from "../components/PageIntro";
import { Box } from "@chakra-ui/react";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <Box>
      <SubHeader />
      <PageIntro />
      <ContactForm />
    </Box>
  );
};

export default Contact;
