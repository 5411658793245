import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import SoccerPlayer from "../../../assets/frontendImages/Soccer Duel.jpg";
import Typewriter from "typewriter-effect";
import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Power2, gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { TimelineLite } from "gsap/gsap-core";
import useFetch from "../../../actions/useFetch";

function HomeHeader() {
  // GSAP
  let container = useRef(null);
  let image = useRef(null);
  let imageReveal = CSSRulePlugin.getRule(".img-container");

  const tl = new TimelineLite();

  useEffect(() => {
    tl.to(container, 1, { css: { visibility: "visible" } })
      .to(imageReveal, 3.5, {
        height: "0%",
        ease: Power2.easeInOut,
      })
      .from(image, 1.4, { scale: 1.6, ease: Power2.easeInOut, delay: 0 });
  });
  // Framer Motion
  const MotionBox = motion(Box);
  const borderVariants = {
    hidden: {
      width: 0,
    },
    visible: {
      width: "100%",
      transition: { delay: 0.5, duration: 1 },
    },
  };

  // TypeWriter Anmimation
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setStartAnimation(true);
    }, 1500);

    return () => clearTimeout(delayTimeout);
  }, []);

  return (
    <>
      <Flex
        flexDir="column"
        padding="2rem"
        justifyContent={{ base: "center", md: "flex-end" }}
        alignContent="center"
        height={{ base: "700px", md: "600px", lg: "90vh" }}
        className="container"
      >
        <Heading
          variant={{ base: "h2", md: "h1" }}
          marginBottom={{ base: "0", lg: "2.4rem" }}
          height={{ base: "602px", lg: "295px" }}
          lineHeight={{ base: "3.5rem", lg: "4.5rem" }}
        >
          {startAnimation && (
            <Typewriter
              options={{
                strings:
                  "Optimizing live sports, events and broadcast's second screen experience by utilising AI to generate data-driven design assets in seconds.",
                autoStart: true,
                delay: 25,
                pauseFor: 10000000,
                cursor: "",
              }}
            />
          )}
        </Heading>

        <Box className="img-container" ref={(el) => (container = el)} />
        <Image
          className="img"
          ref={(el) => (image = el)}
          src={SoccerPlayer}
          position="absolute"
          height={{ base: "570px", lg: "600px" }}
          zIndex="-2"
          top="125px"
          right="50px"
        />
      </Flex>
      <MotionBox
        marginTop={{ base: "6rem", sm: "0" }}
        variants={borderVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        borderBottom="1px solid #e5a329"
      />
    </>
  );
}

export default HomeHeader;
