import React from "react";
import SubHeader from "../components/SubHeader";
import { Box } from "@chakra-ui/react";
import PageIntro from "../components/PageIntro";
import IndustriesServiced from "../components/IndustriesServiced";
import DataFeeds from "../components/DataFeeds";
import Broadcast from "../components/Broadcast";

const HowItWorks = () => {
  return (
    <Box>
      <SubHeader />
      <PageIntro />
      <IndustriesServiced />
      <DataFeeds />
      <Broadcast />
    </Box>
  );
};

export default HowItWorks;
