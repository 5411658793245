import React, { useEffect, useState } from "react";
import { Link, Link as ReachLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { removeTrailingW } from "../../../../helpers/removeTrailingW";
import useFetch from "../../../../actions/useFetch";
// Helpers
import { RemoveTrailingW } from "../../../../helpers/removeTrailingW";

import useUrlState from "@ahooksjs/use-url-state";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
import LeagueStartingElevenArtwork from "../components/StartingEleventArtwork";

const LeagueStartingEleven = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  // Fetch Data
  const {
    data: fixturesData,
    isPending: isFixtureDataPending,
    error: fixtureDataError,
  } = useFetch(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/1394.xml?json=1"
  );

  const [activeFixture, setActiveFixture] = useUrlState({
    id: null,
    team: null,
    images: null,
  });

  const handleFixtureInputChange = (e) => {
    const { name, value } = e.target;

    setActiveFixture((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const [activeFixtures, setActiveFixtures] = useState([""]);
  useEffect(() => {
    if (fixturesData !== null) {
      setActiveFixtures(fixturesData.commentaries.tournament.match);
    }
  }, [fixturesData]);

  const [activeFixtureData, setActiveFixtureData] = useState("");

  // // Set Active Fixture Data
  useEffect(() => {
    if (
      activeFixtures !== null &&
      fixturesData !== null &&
      activeFixture !== null
    ) {
      if (Array.isArray(activeFixtures)) {
        const matchingFixture = activeFixtures.find(
          (item) => item["@id"] === activeFixture.id
        );
        setActiveFixtureData(matchingFixture);
      } else {
        setActiveFixtureData(activeFixtures);
      }
    }
  }, [activeFixtures, fixturesData, activeFixture]);
  // Set Active Teams
  const [activeTeams, setActiveTeams] = useState([]);

  useEffect(() => {
    if (activeFixtureData && activeFixtureData !== "") {
      const newActiveTeams = [
        {
          id: activeFixtureData?.localteam["@id"],
          name: activeFixtureData?.localteam["@name"],
          status: "localteam",
        },
        {
          id: activeFixtureData?.visitorteam["@id"],
          name: activeFixtureData?.visitorteam["@name"],
          status: "visitorteam",
        },
      ];

      setActiveTeams(newActiveTeams);
    }
  }, [activeFixtureData]);

  const [activeFixtureInfo, setActiveFixtureInfo] = useState({
    localTeamId: "",
    localTeamFlag: "",
    visitorTeamFlag: "",
    visitorTeamId: "",
    stadiumName: "",
    time: "",
    date: "",
    group: "",
  });

  useEffect(() => {
    if (activeFixtureData !== undefined) {
      setActiveFixtureInfo((prevStats) => ({
        ...prevStats,
        localTeamId: activeFixtureData?.localteam?.["@id"],
        localTeamName: activeFixtureData?.localteam?.["@name"],
        visitorTeamId: activeFixtureData?.visitorteam?.["@id"],
        visitorTeamName: activeFixtureData?.visitorteam?.["@name"],
        time: activeFixtureData["@time"],
        date: activeFixtureData["@formatted_date"],
        stadiumName: activeFixtureData?.matchinfo?.stadium["@name"],
      }));
    }
  }, [activeFixtureData]);

  const [activeMatchdaySquad, setActiveMatchDaySquad] = useState({
    startingEleven: null,
    name: null,
    image: null,
    formation: null,
    subs: null,
    coach: null,
  });

  useEffect(() => {
    if (
      activeFixtureData !== undefined &&
      activeFixture.team !== undefined &&
      activeFixtureData.localteam != null
    ) {
      if (activeFixture.team == "localteam") {
        setActiveMatchDaySquad((prevSquad) => ({
          ...prevSquad,
          name: activeFixtureData?.localteam?.["@name"],
          startingEleven: activeFixtureData?.teams?.localteam?.player,
          formation: activeFixtureData?.teams?.localteam?.["@formation"],
          subs: activeFixtureData?.substitutes?.localteam?.player,
          coach: activeFixtureData?.coaches?.localteam?.coach?.["@name"],
        }));
      } else if (activeFixture.team == "visitorteam") {
        setActiveMatchDaySquad((prevSquad) => ({
          ...prevSquad,
          name: activeFixtureData?.visitorteam?.["@name"],
          startingEleven: activeFixtureData?.teams?.visitorteam?.player,
          formation: activeFixtureData?.teams?.visitorteam?.["@formation"],
          subs: activeFixtureData?.substitutes?.visitorteam?.player,
          coach: activeFixtureData?.coaches?.visitorteam?.coach?.["@name"],
        }));
      }
    }
  }, [activeFixtureData, activeFixture.team]);

  // Fetch Graphics database
  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("psl");
  // console.log(
  //   "Graphic Data:",
  //   graphicData
  //   "Graphic Data Pending status",
  //   isGraphicDataPending,
  //   "Graphic Data error",
  //   graphicDataError
  // );

  //  Set Graphic Info
  const [imageList, setImageList] = useState(null);

  useEffect(() => {
    // if (graphicData & (activeMatchdaySquad.name != "")) {
    const matchingImages = graphicData?.clubs?.find(
      (item) => item.name == activeMatchdaySquad.name
    );

    if (matchingImages != null) setImageList(matchingImages.images);

    // }
  }, [graphicData, activeMatchdaySquad.name]);

  useEffect(() => {
    if (graphicData && graphicData.clubs != undefined) {
      const matchingLocalLogo = graphicData?.clubs?.find(
        (item) => item.id == activeFixtureInfo?.localTeamId
      );

      const matchingVisitorLogo = graphicData.clubs.find(
        (item) => item.id == activeFixtureInfo.visitorTeamId
      );

      if (matchingLocalLogo && matchingVisitorLogo) {
        setActiveFixtureInfo((prevStats) => ({
          ...prevStats,
          localTeamFlag: matchingLocalLogo.flag,
          visitorTeamFlag: matchingVisitorLogo.flag,
          group: matchingLocalLogo.group,
        }));

        setActiveMatchDaySquad((prevSquad) => ({
          ...prevSquad,
          image: activeFixture.images,
        }));
      }
    }
  }, [
    graphicData,
    activeFixtureInfo.localTeamId,
    activeFixtureInfo.visitorTeamId,
    activeFixture.images,
  ]);

  // Download Image
  const imageFilename = "Starting 11.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [activeFixture]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  return (
    <>
      {fixturesData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
          overflow="hidden"
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h4">
                Starting 11
              </Heading>
              <Flex mt={8} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Fixtures"
                  name="id"
                  onChange={handleFixtureInputChange}
                  value={activeFixture.id}
                >
                  {activeFixtures &&
                    (Array.isArray(activeFixtures) ? (
                      activeFixtures?.map((dailyFixture, index) => (
                        <option key={index} value={dailyFixture["@id"]}>
                          {dailyFixture?.localteam &&
                            dailyFixture?.localteam?.["@name"]}
                        </option>
                      ))
                    ) : (
                      <option value={activeFixtures?.["@id"]}>
                        {activeFixtures?.localteam?.["@name"]} -{" "}
                        {activeFixtures?.visitorteam?.["@name"]}
                      </option>
                    ))}
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Team"
                  name="team"
                  onChange={handleFixtureInputChange}
                  value={activeFixture.team}
                >
                  {activeTeams && activeTeams.length > 0 ? (
                    activeTeams.map((activeTeam, index) => (
                      <option key={index} value={activeTeam.status}>
                        {removeTrailingW(activeTeam.name)}
                      </option>
                    ))
                  ) : (
                    <option value="">Select Fixture First</option>
                  )}
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Images"
                  name="images"
                  onChange={handleFixtureInputChange}
                  value={activeFixture.images}
                >
                  {imageList && imageList.length > 0 ? (
                    imageList.map((image, index) => (
                      <option key={index} value={image.image}>
                        {image.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Select Fixture First</option>
                  )}
                </Select>
              </Flex>
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              <LeagueStartingElevenArtwork
                activeFixtureInfo={activeFixtureInfo}
                activeMatchdaySquad={activeMatchdaySquad}
              />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default LeagueStartingEleven;
