import { Box, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import NewsroomImage from "../../assets/dashboardImages/News Room.png";
import DataFeedAccordion from "../uxComponents/DataFeedAccordion";
import FullWidthSpinner from "./FullWidthSpinner";
// Auth
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../actions/useFetchFirebase";
import { useNavigate } from "react-router";

const LandingDashboard = () => {
  const broadcastContainer = {
    display: "flex",
    border: "1px solid #e5a428",
    borderRadius: "1rem",
    height: "8rem",
    // padding: "1rem",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    width: "140px",
  };

  const subHeader = {
    marginBottom: "0.5rem",
  };

  const subsectionHeading = {
    fontSize: "1rem",
    marginBottom: "0.25rem",
    color: "#e5a428",
  };

  // User Management
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Initialize the unsubscribe function outside the callback.
    let unsubscribe;

    // Attach the listener and store the unsubscribe function.
    unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);

      // Check if user is authenticated and redirect if not.
      if (!currentUser) {
        navigate("/account");
      }

      setLoading(false);
    });

    // Return the unsubscribe function from this effect.
    return () => {
      // Unsubscribe when the component unmounts.
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [navigate]);

  if (loading) {
    return <FullWidthSpinner />;
  }

  if (!user) {
    return null;
  }

  console.log("user", user);

  return (
    <Flex
      className="body-container"
      height="100vh"
      //   width="82%"
      paddingTop="9.3rem"
      borderRadius={10}
    >
      <Flex
        width="100%"
        gridTemplateColumns="50% 50%"
        margin="1rem 1rem 1.2rem 1rem"
        gap="1rem"
      >
        <Box
          border="1px solid #e5a428"
          borderRadius="2rem"
          width="50%"
          height="100%"
          padding="1rem"
          display="flex"
          flexDir="column"
          justifyContent="flex-end"
        >
          <Heading variant="h2">RHESTSub</Heading>
          <Box marginY="1rem">
            <Heading variant="h5" sx={subHeader}>
              Contact Details
            </Heading>
            <Text>
              6 Umnga Street Atteridgeville 0008
              <br />
              South Africa
              <br />
              admin@rhest.co.za <br />
              079 915 7052 <br />
            </Text>
          </Box>
          <Box marginY="1rem">
            <Heading variant="h5" sx={subHeader}>
              {" "}
              Industries
            </Heading>
            <Grid gridTemplateColumns="repeat(3, 1fr)" gap="0.5rem">
              <GridItem
                backgroundImage={NewsroomImage}
                height="100%"
                backgroundSize="contain"
                sx={broadcastContainer}
                fontSize="1rem"
              >
                <Heading variant="h6" textAlign="center">
                  News Network
                </Heading>
              </GridItem>
              <GridItem sx={broadcastContainer}>
                <Heading variant="h6">Broadcast</Heading>
              </GridItem>
              <GridItem sx={broadcastContainer}>
                <Heading variant="h6">Sport</Heading>
              </GridItem>
            </Grid>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDir="column"
          height="100%"
          width="100%"
          overflow="hidden"
          overflowY="scroll"
        >
          <Box
            border="1px solid #e5a428"
            height="100%"
            borderRadius="1rem"
            padding="1rem"
            className="data-feeds-container"
          >
            <Heading variant="h5" sx={subHeader}>
              Data Feeds
            </Heading>
            <DataFeedAccordion />
          </Box>
          <Box height="100%" borderRadius="1rem" marginTop="1rem">
            <Flex gap="1rem" height="100%">
              <Box
                border="1px solid #e5a428"
                backgroundColor="#fff8ef"
                borderRadius="1rem"
                width="60%"
                height="310px"
                maxHeight=""
                padding="1rem"
                overflowY="scroll"
                // overflow="hidden"
              >
                <Heading variant="h5" sx={subHeader}>
                  Artworks
                </Heading>

                <Box marginBottom="0.5rem">
                  <Heading variant="h6" sx={subsectionHeading}>
                    Pre-Game
                  </Heading>
                  <Flex justifyContent="space-between" flexWrap="wrap">
                    <Box width="80px">
                      <Text variant="p" textAlign="center" lineHeight="1.25rem">
                        Head to head
                      </Text>
                    </Box>
                    <Box width="80px">
                      <Text variant="p" textAlign="center">
                        Interview
                      </Text>
                    </Box>
                    <Box width="80px">
                      <Text variant="p" textAlign="center">
                        Starting 11
                      </Text>
                    </Box>
                    <Box width="80px">
                      <Text variant="p" textAlign="center">
                        Kick-off
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Box marginBottom="0.5rem">
                  <Heading variant="h6" sx={subsectionHeading}>
                    Pre-Game
                  </Heading>
                  <Flex justifyContent="space-between" flexWrap="wrap">
                    <Box width="80px">
                      <Text variant="p" textAlign="center" lineHeight="1.25rem">
                        Head to head
                      </Text>
                    </Box>
                    <Box width="80px">
                      <Text variant="p" textAlign="center">
                        Interview
                      </Text>
                    </Box>
                    <Box width="80px">
                      <Text variant="p" textAlign="center">
                        Starting 11
                      </Text>
                    </Box>
                    <Box width="80px">
                      <Text variant="p" textAlign="center">
                        Kick-off
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Box marginBottom="0.5rem">
                  <Heading variant="h6" sx={subsectionHeading}>
                    Pre-Game
                  </Heading>
                  <Flex justifyContent="space-between" flexWrap="wrap">
                    <Box width="80px">
                      <Text variant="p" textAlign="center" lineHeight="1.25rem">
                        Head to head
                      </Text>
                    </Box>
                    <Box width="80px">
                      <Text variant="p" textAlign="center">
                        Interview
                      </Text>
                    </Box>
                    <Box width="80px">
                      <Text variant="p" textAlign="center">
                        Starting 11
                      </Text>
                    </Box>
                    <Box width="80px">
                      <Text variant="p" textAlign="center">
                        Kick-off
                      </Text>
                    </Box>
                    {/* <Text variant="p">Live Stats</Text>
                    <Text variant="p">Fixtures and Results</Text>
                    <Text variant="p">Player Profiles</Text>
                    <Text variant="p">Team Profiles</Text> */}
                  </Flex>
                </Box>
              </Box>
              <Box
                border="1px solid #e5a428"
                borderRadius="1rem"
                width="40%"
                height="100%"
                padding="1rem"
              >
                <Heading variant="h5" sx={subHeader}>
                  Accounts
                </Heading>
                <Heading variant="h6" sx={subsectionHeading}>
                  DStv Prem Stats & 10 Artworks
                  <br />
                </Heading>
                <Text variant="p" as="p">
                  R30 000 per month <br />
                  Next Payment: 12/02/2025 <br />
                  Duration: <br /> 12/02/23 - 12/02/24
                </Text>
                <Heading variant="h6" sx={subsectionHeading}>
                  Invoices
                  <br />
                </Heading>
                <Text variant="p" as="p">
                  Inv 001
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default LandingDashboard;
