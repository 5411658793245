import React, { useEffect, useState } from "react";
import { Link, Link as ReachLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
} from "@chakra-ui/react";
import useFetch from "../../../../actions/useFetch";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
// Helpers
import { removeTrailingW } from "../../../../helpers/removeTrailingW";
import useUrlState from "@ahooksjs/use-url-state";
// Components
import TeamMatchStatsArtwork from "../components/TeamMatchStatsArtwork";
import axios from "axios";
import { useQuery } from "react-query";
import CustomSpinner from "../../components/CustomSpinner";
import KoTeamMatchStatsArtwork from "../components/kickOff/KoTeamMatchStatsArtwork";

// const fetchLiveFixturesData = (leagueId) => {
//   return axios.get(
//     `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/${leagueId}?date=01.10.2023&json=1`
//   );
// };
const fetchLiveFixturesData = (leagueId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/commentaries/${leagueId}.xml?json=1`
  );
};

const fetchAfconData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/standings/1059?season=2021-2022?json=1"
  );
};

const LeagueTeamMatchStats = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const [activeFixture, setActiveFixture] = useUrlState({
    leagueId: "",
    fixtureId: "",
    teamId: "",
    image: "",
  });

  // console.log(activeFixture);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setActiveFixture((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const {
    data: fixturesData,
    isFetching: isFetchingFixturesData,
    isLoading: isLoadingFixturesData,
    error: fixturesListError,
    refetch: refetchLeagueData,
  } = useQuery("liveFixtures", () =>
    fetchLiveFixturesData(activeFixture.leagueId)
  );

  const { data: afocnData } = useQuery("afconData", fetchAfconData);

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("soccer");

  useEffect(() => {
    if (activeFixture.leagueId != "") {
      refetchLeagueData();
    }
  }, [activeFixture.leagueId]);

  const [activeFixtures, setActiveFixtures] = useState([""]);
  useEffect(() => {
    if (fixturesData) {
      setActiveFixtures(fixturesData?.data?.commentaries?.tournament.match);
    }
  }, [fixturesData]);

  const [activeFixtureData, setActiveFixtureData] = useState("");

  // Set Active Fixture Data
  useEffect(() => {
    if (
      activeFixtures !== null &&
      fixturesData !== null &&
      activeFixture !== null
    ) {
      if (Array.isArray(activeFixtures)) {
        const matchingFixture = activeFixtures.find(
          (item) => item["@id"] === activeFixture.fixtureId
        );
        setActiveFixtureData(matchingFixture);
      } else {
        setActiveFixtureData(activeFixtures);
      }
    }
  }, [activeFixtures, fixturesData, activeFixture]);

  // Set Graphics
  const [activeTeams, setActiveTeams] = useState({
    homeTeamId: "",
    awayTeamId: "",
  });

  // fetch active team IDs
  useEffect(() => {
    if (activeFixtureData && activeFixtureData !== "") {
      setActiveTeams({
        homeTeamId: activeFixtureData.localteam["@id"],
        awayTeamId: activeFixtureData.visitorteam["@id"],
      });
    }
  }, [activeFixtureData]);

  // Set active Graphics
  const [activeLogo, setActiveLogo] = useState({
    home: "",
    away: "",
  });

  const [imageList, setImageList] = useState([""]);
  const [activeImageTeam, setActiveImageTeam] = useState("");

  useEffect(() => {
    if (activeFixture.leagueId !== "") {
      const matchingTeam = graphicData?.[activeFixture?.leagueId]?.clubs?.find(
        (item) => item.id == activeFixture?.teamId
      );
      setImageList(matchingTeam?.images);
      setActiveImageTeam(matchingTeam?.name);
    }
  }, [activeFixture.teamId, activeFixture.leagueId, graphicData]);

  useEffect(() => {
    if (activeFixture.leagueId !== "" && graphicData) {
      const matchingHomeLogo = graphicData?.[
        activeFixture?.leagueId
      ]?.clubs?.find((item) => item.id == activeTeams.homeTeamId);

      const matchingAwayLogo = graphicData?.[
        activeFixture?.leagueId
      ]?.clubs?.find((item) => item.id == activeTeams.awayTeamId);

      if (matchingHomeLogo && matchingAwayLogo) {
        setActiveLogo({
          home: matchingHomeLogo.flag,
          away: matchingAwayLogo.flag,
        });
      }
    }
  }, [
    activeTeams.homeTeamId,
    activeTeams.awayTeamId,
    graphicData,
    activeFixture?.teamId,
    activeFixture?.leagueId,
  ]);

  const [playerList, setPlayerList] = useState([]);

  useEffect(() => {
    const matchingTeam = graphicData?.[activeFixture?.leagueId]?.clubs?.find(
      (item) => item.id === activeFixture.teamId
    );

    if (matchingTeam != undefined) {
      setPlayerList(matchingTeam.images);
    }
  }, [graphicData, activeFixture.teamId]);

  // Define Active Match Stats
  const [activeMatchStats, setActiveMatchStats] = useState({
    stadiumName: "",
    date: "",
    time: "",
    image: "",
    teamName: "",
    status: "",
    timer: "",
    leagueId: "",
    homeTeam: {
      name: "",
      logo: "",
      score: "",
      stats: {
        possession: 0,
        shots: 0,
        shotsOnTarget: 0,
        passes: 0,
        passesComplete: 0,
        corners: 0,
        offSide: 0,
        fouls: 0,
        yellowCards: 0,
        redCards: 0,
      },
    },
    awayTeam: {
      name: "",
      logo: "",
      score: "",
      stats: {
        possession: 0,
        shots: 0,
        shotsOnTarget: 0,
        passes: 0,
        passesComplete: 0,
        corners: 0,
        offSide: 0,
        fouls: 0,
        yellowCards: 0,
        redCards: 0,
      },
    },
  });

  useEffect(() => {
    const homeFixtureData = activeFixtureData?.stats?.localteam;
    const awayFixtureData = activeFixtureData?.stats?.visitorteam;

    if (
      (homeFixtureData && awayFixtureData && activeImageTeam, activeFixtureData)
    ) {
      // Update the fullTime state with data from homeFixtureData
      setActiveMatchStats((prevStats) => ({
        ...prevStats,
        date: activeFixtureData?.["@formatted_date"],
        time: activeFixtureData?.["@time"],
        timer: activeFixtureData?.["@timer"],
        stadiumName: activeFixtureData?.matchinfo?.stadium["@name"],
        status: activeFixtureData["@status"],
        teamName: activeImageTeam,
        image: activeFixture.image,
        leagueId: activeFixture.leagueId,
        homeTeam: {
          logo: activeLogo?.home,
          name: activeFixtureData?.localteam?.["@name"],
          score: activeFixtureData?.localteam?.["@goals"],
          stats: {
            possession: homeFixtureData?.possestiontime?.["@total"],
            shots: homeFixtureData?.shots?.["@total"],
            shotsOnTarget: homeFixtureData?.shots?.["@ongoal"],
            passes: homeFixtureData?.passes?.["@total"],
            passesComplete: homeFixtureData?.passes?.["@accurate"],
            corners: homeFixtureData?.corners?.["@total"],
            fouls: homeFixtureData?.fouls?.["@total"],
            yellowCards: homeFixtureData?.yellowcards?.["@total"],
            redCards: homeFixtureData?.redcards?.["@total"],
          },
        },
        awayTeam: {
          logo: activeLogo?.away,
          name: activeFixtureData?.visitorteam?.["@name"],
          score: activeFixtureData?.visitorteam?.["@goals"],
          stats: {
            possession: awayFixtureData?.possestiontime?.["@total"],
            shots: awayFixtureData?.shots?.["@total"],
            shotsOnTarget: awayFixtureData?.shots?.["@ongoal"],
            passes: awayFixtureData?.passes?.["@total"],
            passesComplete: awayFixtureData?.passes?.["@accurate"],
            corners: awayFixtureData?.corners?.["@total"],
            fouls: awayFixtureData?.fouls?.["@total"],
            yellowCards: awayFixtureData?.yellowcards?.["@total"],
            redCards: awayFixtureData?.redcards?.["@total"],
          },
        },
      }));
    }
  }, [
    activeFixtureData,
    activeLogo,
    activeImageTeam,
    activeFixture.image,
    activeFixture.leagueId,
  ]);

  // Download Image
  const imageFilename = "Team Match Stats.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [activeFixture]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  const handleReset = () => {
    // Reset activeFixture Input
    setActiveFixture({
      leagueId: "",
      fixtureId: "",
      teamId: "",
      image: "",
    });

    // Use a setTimeout to reset activeMatchStats after a brief delay
    setTimeout(() => {
      setActiveMatchStats({
        leagueId: "",
        stadiumName: "",
        date: "",
        time: "",
        image: "",
        teamName: "",
        status: "",
        timer: "",
        homeTeam: {
          name: "",
          logo: "",
          score: "",
          stats: {
            possession: 0,
            shots: 0,
            shotsOnTarget: 0,
            passes: 0,
            passesComplete: 0,
            corners: 0,
            offSide: 0,
            fouls: 0,
            yellowCards: 0,
            redCards: 0,
          },
        },
        awayTeam: {
          name: "",
          logo: "",
          score: "",
          stats: {
            possession: 0,
            shots: 0,
            shotsOnTarget: 0,
            passes: 0,
            passesComplete: 0,
            corners: 0,
            offSide: 0,
            fouls: 0,
            yellowCards: 0,
            redCards: 0,
          },
        },
      });
    }, 0); // Delay of 0 milliseconds to ensure it runs after the next render
  };

  if (isLoadingFixturesData || isGraphicDataPending) {
    return <CustomSpinner />;
  }

  return (
    <>
      {graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h4">
                Match Stats
              </Heading>
              <Flex mt={8} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="League"
                  name="leagueId"
                  onChange={handleInputChange}
                  value={activeFixture.leagueId}
                >
                  <option value="1204">English Premier League</option>
                  <option value="1394">DStv Prem</option>
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Fixtures"
                  name="fixtureId"
                  onChange={handleInputChange}
                  value={activeFixture.fixtureId}
                >
                  {activeFixtures && Array.isArray(activeFixtures) ? (
                    activeFixtures?.map((dailyFixture, index) => (
                      <option key={index} value={dailyFixture["@id"]}>
                        {dailyFixture?.localteam?.["@name"]} -{" "}
                        {dailyFixture?.visitorteam?.["@name"]}
                      </option>
                    ))
                  ) : (
                    <option value={activeFixtures?.["@id"]}>
                      {activeFixtures?.localteam &&
                        activeFixtures?.localteam?.["@name"]}
                    </option>
                  )}
                </Select>
              </Flex>

              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Team"
                  name="teamId"
                  onChange={handleInputChange}
                  value={activeFixture.teamId}
                >
                  {activeMatchStats && activeTeams ? (
                    <>
                      <option value={activeTeams.homeTeamId}>
                        {removeTrailingW(activeMatchStats.homeTeam.name)}
                      </option>
                      <option value={activeTeams.awayTeamId}>
                        {removeTrailingW(activeMatchStats.awayTeam.name)}
                      </option>
                    </>
                  ) : (
                    <option value="">Select fixture first</option>
                  )}
                </Select>
              </Flex>
              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Player Image"
                  name="image"
                  onChange={handleInputChange}
                  value={activeFixture.image}
                >
                  {imageList && imageList.length > 0 ? (
                    imageList.map((image, index) => (
                      <option key={index} value={image.image}>
                        {image.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Select team first</option>
                  )}
                </Select>
              </Flex>
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
              <Button variant="outlineButton" onClick={handleReset}>
                Reset
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              {/* <TeamMatchStatsArtwork activeMatchStats={activeMatchStats} /> */}
              <KoTeamMatchStatsArtwork activeMatchStats={activeMatchStats} />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default LeagueTeamMatchStats;
