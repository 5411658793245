import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useFetch from "../../../../../actions/useFetch";
import useFetchFirebase from "../../../../../actions/useFetchFirebase";
import CustomSpinner from "../../../components/CustomSpinner";
import { Button, Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
import LiveFixturesArtwork from "../../../psl/components/LiveFixturesArtwork";

// const fetchRugbyLiveFixturesData = () => {
//   return axios.get(
//     "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/live_stats?json=1"
//   );
// };

const fetchRugbyLiveFixturesData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/live_stats?date=23.09.2023&json=1"
  );
};
const RugbyLiveFixtures = () => {
  const {
    isLoading: isFixturesDataLoading,
    data: fixturesData,
    isError,
  } = useQuery("liveRugbyFixtures", fetchRugbyLiveFixturesData);

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("rugby");

  const [activeFixturesData, setActiveFixturesData] = useState([]);

  useEffect(() => {
    if (fixturesData != null && graphicData?.international != null) {
      const fixtureList = fixturesData?.data?.commentaries?.tournament?.match;

      if (fixtureList?.length > 0) {
        const updatedActiveFixturesData = fixtureList?.map((fixture) => {
          const matchingHomeClub = graphicData.international.find(
            (item) => item.id === fixture.localteam?.id
          );
          const matchingVisitorClub = graphicData?.international?.find(
            (item) => item.id === fixture.visitorteam?.id
          );

          return {
            time: fixture?.time,
            localTeam: {
              name: fixture.localteam?.name,
              id: fixture.localteam?.id,
              score: fixture.localteam?.score,
              flag: matchingHomeClub?.flag,
            },
            visitorTeam: {
              name: fixture.visitorteam?.name,
              id: fixture.visitorteam?.id,
              score: fixture.visitorteam?.score,
              flag: matchingVisitorClub?.flag,
            },
          };
        });

        setActiveFixturesData({
          date: fixtureList?.[0]?.date,
          matches: updatedActiveFixturesData,
        });
      } else if (!Array.isArray(fixtureList)) {
        const matchingHomeClub = graphicData.international.find(
          (item) => item.id === fixtureList.localteam?.id
        );
        const matchingVisitorClub = graphicData.international.find(
          (item) => item.id === fixtureList.visitorteam?.id
        );

        setActiveFixturesData((prevData) => ({
          ...prevData,
          time: fixtureList?.time,
          localTeam: {
            name: fixtureList.localteam?.name,
            id: fixtureList.localteam?.id,
            score: fixtureList.localteam?.score,
            flag: matchingHomeClub?.flag,
          },
          visitorTeam: {
            name: fixtureList.visitorteam?.name,
            id: fixtureList.visitorteam?.id,
            score: fixtureList.visitorteam?.score,
            flag: matchingVisitorClub?.flag,
          },
        }));
      }
    }
  }, [fixturesData, graphicData]);

  const [displayedFixtures, setDisplayedFixtures] = useState(undefined);

  useEffect(() => {
    if (activeFixturesData?.matches?.length > 0) {
      // Sort matches by time
      const sortedMatches = activeFixturesData.matches.sort((a, b) =>
        a.time.localeCompare(b.time)
      );

      const separatedMatchesByTime = sortedMatches.reduce((acc, fixture) => {
        const existingGroup = acc.find((group) => group.time === fixture.time);

        if (existingGroup) {
          existingGroup.matches.push(fixture);
        } else {
          acc.push({ time: fixture.time, matches: [fixture] });
        }

        return acc;
      }, []);

      setDisplayedFixtures(separatedMatchesByTime);
    } else if (!Array.isArray(activeFixturesData)) {
      setDisplayedFixtures(activeFixturesData);
    }
  }, [activeFixturesData]);

  // console.log("active fixtures data", activeFixturesData);

  // Download Image
  const imageFilename = "Live Fixtures.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, []);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  if (isFixturesDataLoading) {
    return <CustomSpinner />;
  }

  return (
    <>
      {fixturesData && graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h3">
                Live Fixtures
              </Heading>
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              <LiveFixturesArtwork
                displayedFixtures={displayedFixtures}
                activeFixturesData={activeFixturesData.date}
              />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default RugbyLiveFixtures;
