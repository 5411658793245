import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useLocation } from "react-router";

const DataFeedAccordion = () => {
  const accordionButtonStyling = {
    display: "flex",
    justifyContent: "space-between",

    _hover: { background: "#e5a428" },
    _expanded: {
      borderBottom: "1px solid #e5a428",
    },
  };

  const dataFeedList = [
    {
      name: "Soccer",
      competitions: [
        "CAF Champions League",
        "AFCON",
        "DStvPrem",
        "International Qualifiers",
      ],
      comingSoon: "",
      description: [
        "Live scores",
        "Live goals, cards and subs",
        "Fixtures and results",
        "Standings",
        "Team profiles",
        "Player profiles",
        "historical results",
      ],
    },
    {
      name: "Rugby",
      competitions: [
        "World Cup",
        "Sevens World Cup",
        "Test Rugby",
        "United Rugby Championships",
      ],
      comingSoon: "",
      description: ["Live scores", "Live game stats", "Standings", "Fixtures"],
    },
    {
      name: "Cricket",
      competitions: [
        "World Cup",
        "International ODI's",
        "SA 20",
        "Momentum One Day Cup",
        "4 Day Series",
        "T20 Challenge",
      ],
      comingSoon: "Coming soon",
      description: [
        "Live scorecards",
        "Ball by ball",
        " Lineups",
        "Standings",
        "Series schedules",
        "Player profiles",
        "Team squads",
        "historical results",
      ],
    },
  ];

  //   Framer Motion
  const MotionAccordion = motion(Accordion);
  const accordionVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 2.5, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 2.5, duration: 1 },
    },
  };

  const location = useLocation();
  console.log(location);

  return (
    <>
      <MotionAccordion
        allowToggle
        width="100%"
        variants={accordionVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {dataFeedList.map((feed, index) => (
          <AccordionItem
            key={index}
            borderBottom="1px solid #e5a428"
            _first={{
              borderTop: "1px solid #e5a428",
            }}
          >
            <AccordionButton sx={accordionButtonStyling}>
              <Flex alignItems="flex-end">
                <Heading
                  textAlign="left"
                  width="8rem"
                  variant="h4"
                  style={{
                    fontSize: location === "/" ? "1.25rem" : "inherit",
                  }}
                >
                  {feed.name}
                </Heading>
                <Text variant="p" marginBottom="0.1rem">
                  {feed.comingSoon}
                </Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Flex
                justifyContent="space-between"
                gap="1rem"
                paddingTop="0.5rem"
                paddingBottom="0.75rem"
                borderBottom="1px solid #e5a428"
                flexWrap="wrap"
              >
                {feed.competitions.map((competition, index) => (
                  <Heading key={index} fontSize="1rem" variant="h6">
                    {competition}
                  </Heading>
                ))}
              </Flex>
              <Grid
                paddingTop="0.75rem"
                gridTemplateColumns={{
                  base: "repeat(3, 1fr)",
                  sm: "repeat(4, 1fr)",
                }}
              >
                {feed.description.map((description, index) => (
                  <GridItem key={index}>
                    <Text
                      textAlign="center"
                      lineHeight="1rem"
                      paddingY="0.5rem"
                    >
                      {description}
                    </Text>
                  </GridItem>
                ))}
              </Grid>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </MotionAccordion>
    </>
  );
};

export default DataFeedAccordion;
