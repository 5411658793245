import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import ArgentinaImage from "../../../../../assets/rugby/International/South Africa.png";
import WcLogo from "../../../../../assets/rugby/Competitions/Rugby World Cup Logo.svg";
import Pattern from "../../../../../assets/rugby/Patterns/Rugby Horizontal pattern.svg";
import PlaceHolderImage from "../../../../../assets/rugby/International/Players/Ireland/Group 1.png";
import HeadingBackground from "../../../../../assets/headings/Fixtures Heading.png";
// Helpers
import { SaTimeHelper } from "../../../../../helpers/SaTimeHelper";
import { poolFormatter } from "../../../../../helpers/PoolFormatter";

const RugbyPoolFixturesArtwork = ({ formattedFixtureData }) => {
  const singleClubContainer = {
    paddingBottom: "0.25rem",
    gap: "0.5rem",
    alignItems: "flex-end",
  };

  const singleClubName = {
    fontSize: "1rem",
  };

  const flagSize = {
    height: "25px",
  };

  const singleFixtureTime = {
    display: "flex",
    alignItems: "flex-end",
  };

  console.log(formattedFixtureData);
  return (
    <>
      <Box
        overflow="hidden"
        height="700px"
        width="700px"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="artwork-container"
        backgroundColor="#fff8ef"
      >
        <Heading
          textTransform="uppercase"
          className="fixture-heading"
          variant="h1"
          fontSize="8rem"
          fontFamily="Neue Power Ultra"
          textAlign="center"
        >
          Fixtures
        </Heading>
        <Flex
          className="artwork-header"
          justifyContent="space-between"
          alignItems="center"
          margin="-9rem 2rem 0 2rem"
          background={`url(${HeadingBackground})`}
          backgroundSize="contain"
        >
          <Box zIndex="1">
            {formattedFixtureData?.team != undefined ? (
              <Heading
                variant="h5"
                fontFamily="Neue Power Ultra"
                textTransform="uppercase"
                lineHeight="1.5rem"
                fontWeight="800"
              >
                {formattedFixtureData.team}
                <Box color="#e5a428">Pool</Box>
                Fixtures
              </Heading>
            ) : (
              <Heading
                variant="h5"
                fontFamily="Neue Power Ultra"
                textTransform="uppercase"
                lineHeight="1.5rem"
                fontWeight="800"
              >
                South Africa
                <Box color="#e5a428">Pool</Box>
                Fixtures
              </Heading>
            )}
          </Box>
          <Box zIndex="1">
            <Image src={WcLogo} height="100px" />
          </Box>
        </Flex>
        <Box
          className="artwork-body"
          display="flex"
          flexDir="column"
          height="490px"
          alignItems="space-between"
          justifyContent="space-between"
          marginBottom="1rem"
          marginTop="1rem"
          paddingX="2rem"
        >
          {formattedFixtureData?.fixtures != undefined &&
            formattedFixtureData?.fixtures?.map((fixture, index) => (
              <Box
                key={index}
                className="single-fixture"
                borderRadius="1rem"
                backgroundColor="rgb(255,255,255, 0.7)"
                width="65%"
                border="1px solid #BF1A2F"
                padding="0.5rem"
                zIndex="100"
              >
                <Flex
                  className="single-fixture__fixture"
                  justifyContent="space-between"
                >
                  <Box>
                    {formattedFixtureData?.fixtures != undefined ? (
                      <Heading variant="h6">
                        {poolFormatter(formattedFixtureData.pool)}
                      </Heading>
                    ) : (
                      <Heading variant="h6">Pool B</Heading>
                    )}

                    {fixture.venue !== undefined ? (
                      <Text variant="p" fontSize="0.85rem">
                        {fixture.venue}
                      </Text>
                    ) : (
                      <Text variant="p" fontSize="0.85rem">
                        Melbourne Rectangular Stadium, Melbourne
                      </Text>
                    )}
                  </Box>
                  <Box>
                    {fixture && fixture.date !== "" ? (
                      <Text variant="p" fontSize="0.85rem">
                        {fixture.date}
                      </Text>
                    ) : (
                      <Text variant="p" fontSize="0.85rem">
                        21.07.2023
                      </Text>
                    )}
                  </Box>
                </Flex>
                <Flex
                  className="single-fixture__info"
                  justifyContent="space-between"
                  paddingTop="0.5rem"
                >
                  <Box display="flex" gap="2rem">
                    <Flex sx={singleClubContainer}>
                      {fixture && fixture.localteam?.flag !== undefined ? (
                        <Image
                          src={require(`../../../../../assets/rugby/International/${fixture.localteam?.flag}`)}
                          sx={flagSize}
                        />
                      ) : (
                        <Image src={ArgentinaImage} sx={flagSize} />
                      )}
                      {fixture && fixture.localteam?.name !== undefined ? (
                        <>
                          <Heading variant="h6" sx={singleClubName}>
                            {fixture.localteam?.name}
                          </Heading>
                          <Heading variant="h6" sx={singleClubName}>
                            {fixture.localteam?.totalscore}
                          </Heading>
                        </>
                      ) : (
                        <Heading variant="h6" sx={singleClubName}>
                          Canada
                        </Heading>
                      )}
                    </Flex>
                    <Flex sx={singleClubContainer}>
                      {fixture && fixture.awayteam?.flag !== undefined ? (
                        <Image
                          src={require(`../../../../../assets/rugby/International/${fixture.awayteam?.flag}`)}
                          sx={flagSize}
                        />
                      ) : (
                        <Image src={ArgentinaImage} sx={flagSize} />
                      )}
                      {fixture && fixture.awayteam?.name != undefined ? (
                        <>
                          <Heading variant="h6" sx={singleClubName}>
                            {fixture.awayteam?.name}
                          </Heading>
                          <Heading variant="h6" sx={singleClubName}>
                            {fixture.awayteam?.totalscore}
                          </Heading>
                        </>
                      ) : (
                        <Heading variant="h6" sx={singleClubName}>
                          Canada
                        </Heading>
                      )}
                    </Flex>
                  </Box>
                  <Box sx={singleFixtureTime}>
                    {fixture && fixture.time != undefined ? (
                      <Text variant="p" fontSize="0.85rem">
                        {SaTimeHelper(fixture.time, 2)}
                      </Text>
                    ) : (
                      <Text variant="p" fontSize="0.85rem">
                        16:00
                      </Text>
                    )}
                  </Box>
                </Flex>
              </Box>
            ))}

          {/* ))} */}
        </Box>
        {formattedFixtureData &&
        formattedFixtureData?.image !== "" &&
        formattedFixtureData?.team !== undefined ? (
          <Image
            src={require(`../../../../../assets/rugby/International/Players/${formattedFixtureData?.team}/${formattedFixtureData?.image}`)}
            marginBottom="100px"
            position="fixed"
            bottom="-120"
            right="-10"
            height="650px"
            zIndex="1"
          />
        ) : (
          <Image
            src={PlaceHolderImage}
            marginBottom="100px"
            position="fixed"
            bottom="-120"
            right="-10"
            height="650px"
            zIndex="1"
          />
        )}

        <Image src={Pattern} />
      </Box>
    </>
  );
};

export default RugbyPoolFixturesArtwork;
