import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import WcLogo from "../../../../../assets/rugby/Competitions/Rugby World Cup Logo.svg";
import Pattern from "../../../../../assets/rugby/Patterns/Rugby Horizontal pattern.svg";
import PlaceHolderImage from "../../../../../assets/rugby/International/Players/Ireland/Group 1.png";
import { poolFormatter } from "../../../../../helpers/PoolFormatter";
import RugbyTable from "./uxComponents/RugbyTable";
// Helpers

const RugbyStandingsArtwork = ({ formattedGroupData }) => {
  return (
    <>
      <Box
        overflow="hidden"
        height="700px"
        width="700px"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="artwork-container"
        backgroundColor="#fff8ef"
      >
        <Heading
          className="fixture-heading"
          textTransform="uppercase"
          fontFamily="Neue Power Ultra"
          fontSize="110px"
          textAlign="center"
          position="relative"
          zIndex="1"
        >
          Standings
        </Heading>
        <Flex
          className="artwork-header"
          justifyContent="space-between"
          alignItems="center"
          margin="-7.25rem 2rem 1rem 2rem"
        >
          <Box zIndex="1">
            {formattedGroupData ? (
              <Heading
                variant="h5"
                fontFamily="Neue Power Ultra"
                textTransform="uppercase"
                lineHeight="1.5rem"
                fontWeight="800"
              >
                {poolFormatter(formattedGroupData.name)}
                <Box color="#e5a428">Group Stage</Box>
                Standings
              </Heading>
            ) : (
              <Heading
                variant="h5"
                fontFamily="Neue Power Ultra"
                textTransform="uppercase"
                lineHeight="1.5rem"
                fontWeight="800"
              >
                Group D<Box color="#e5a428">Group Stage</Box>
                Standings
              </Heading>
            )}
          </Box>
          <Box zIndex="1">
            <Image src={WcLogo} height="100px" />
          </Box>
        </Flex>
        <Box
          className="artwork-body"
          display="flex"
          flexDir="column"
          height="490px"
          justifyContent="center"
          marginBottom="2rem"
          marginTop="0.5rem"
          paddingX="2rem"
        >
          <RugbyTable formattedGroupData={formattedGroupData} />
        </Box>
        {formattedGroupData?.playerImage != undefined &&
        formattedGroupData?.playerImageTeam != "" ? (
          <Image
            src={require(`../../../../../assets/rugby/International/Players/${formattedGroupData.playerImageTeam}/${formattedGroupData.playerImage}`)}
            marginBottom="100px"
            position="fixed"
            bottom="-120"
            right="-10"
            height="650px"
            zIndex="1"
          />
        ) : (
          <Image
            src={PlaceHolderImage}
            marginBottom="100px"
            position="fixed"
            bottom="-120"
            right="-10"
            height="650px"
            zIndex="1"
          />
        )}
        <Image src={Pattern} />
      </Box>
    </>
  );
};

export default RugbyStandingsArtwork;
