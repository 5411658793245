export const ActiveFixtureDataHelper = (
  activeFixtures,
  fixturesData,
  activeFixture,
  setActiveFixtureData
) => {
  if (
    activeFixtures !== null &&
    fixturesData !== null &&
    activeFixture !== null
  ) {
    if (Array.isArray(activeFixtures)) {
      const matchingFixture = activeFixtures.find(
        (item) => item.id === activeFixture.id
      );
      setActiveFixtureData(matchingFixture);
    } else {
      setActiveFixtureData(activeFixtures);
    }
  }
};
