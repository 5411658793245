import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import CustomSpinner from "../../../components/CustomSpinner";
import { Button, Flex, Grid, GridItem, Select } from "@chakra-ui/react";
import useUrlState from "@ahooksjs/use-url-state";
import ArtWorkSpinner from "../../../components/ArtworkSpinner";
import useFetchFirebase from "../../../../../actions/useFetchFirebase";
import HeadToHeadArtwork from "../../../psl/components/HeadToHeadArtwork";

const fetchRugbyData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/1106_table?json=1"
  );
};

const fetchHeadToHeadData = (localTeamId, visitorTeamId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/h2h_${localTeamId}-${visitorTeamId}?json=1`
  );
};

const RugbyHeadToHead = () => {
  // Styly inputs
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const [headToHeadInput, setHeadToHeadInput] = useUrlState({
    localTeam: "",
    visitorTeam: "",
    selectedTeam: "",
    selectedImage: "",
  });

  const handleHeadToHeadInputChange = (e) => {
    const { name, value } = e.target;

    setHeadToHeadInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  // Fetch Data
  const {
    isLoading,
    data: rugbyData,
    isError,
    error,
  } = useQuery("group-standings", fetchRugbyData);

  const {
    isLoading: isHeadToHeadDataLoading,
    data: headToHeadData,
    isError: isHeadToHeadDataError,
    error: headToHeadDataError,
    refetch: refetchHeadToHeadData,
    isFetching: isHeadToHeadDataFetching,
  } = useQuery(
    "head-to-head",
    () =>
      fetchHeadToHeadData(
        headToHeadInput.localTeam,
        headToHeadInput.visitorTeam
      ),
    { enabled: false }
  );

  // Fetch Data
  const [rugbyStandings, setRugbyStandings] = useState([]);

  useEffect(() => {
    if (rugbyData?.data?.standings?.category?.league) {
      setRugbyStandings(rugbyData.data.standings.category.league);
    }
  }, [rugbyData]);

  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    if (rugbyStandings.length > 0) {
      const extractedTeamInfo = rugbyStandings.flatMap((group) => {
        return group.team.map((team) => ({
          id: team.id,
          name: team.name,
        }));
      });

      setTeamList(extractedTeamInfo);
    }
  }, [rugbyStandings]);

  useEffect(() => {
    if (headToHeadInput.homeTeam !== "" && headToHeadInput.visitorTeam !== "") {
      refetchHeadToHeadData();
    }
  }, [
    headToHeadInput.homeTeam,
    headToHeadInput.visitorTeam,
    refetchHeadToHeadData,
  ]);

  const [headToHeadStats, setHeadToHeadStats] = useState("");

  useEffect(() => {
    if (headToHeadData != undefined) {
      setHeadToHeadStats(headToHeadData?.data.h2h);
    }
  }, [headToHeadData, refetchHeadToHeadData]);

  // set Active Teams
  const initializeTeamStats = () => ({
    id: "",
    name: "",
    flag: "",
    biggestVictory: "",
    gamesWon: "",
    gamesLost: "",
    gamesDrawn: "",
    goalsScored: "",
    goalsConceded: "",
  });

  const [activeHeadToHeadStats, setActiveHeadToHeadStats] = useState({
    teamOne: initializeTeamStats(),
    teamTwo: initializeTeamStats(),
  });

  useEffect(() => {
    if (headToHeadStats != undefined) {
      setActiveHeadToHeadStats((prevStats) => ({
        ...prevStats,
        teamOne: {
          id: headToHeadStats?.["team1"],
          biggestVictory:
            (headToHeadStats?.biggest_victory?.team1?.match?.["team1_score"] ||
              "") +
            " - " +
            (headToHeadStats?.biggest_victory?.team1?.match?.["team2_score"] ||
              ""),
          gamesTotal: headToHeadStats?.overall?.total?.total?.[0]["games"],
          gamesWon: headToHeadStats?.overall?.total?.total?.[1]["team1_won"],
          gamesLost: headToHeadStats?.overall?.total?.total?.[2]["team2_won"],
          gamesDrawn: headToHeadStats?.overall?.total?.total?.[3]["draws"],
          goalsScored:
            headToHeadStats?.goals?.total?.total?.[0]["team1_scored"],
          goalsConceded:
            headToHeadStats?.goals?.total?.total?.[1]["team1_conceded"],
        },
        teamTwo: {
          id: headToHeadStats?.["team2"],
          biggestVictory:
            (headToHeadStats?.biggest_victory?.team2?.match?.["team1_score"] ||
              "") +
            " - " +
            (headToHeadStats?.biggest_victory?.team2?.match?.["team2_score"] ||
              ""),
          gamesTotal: headToHeadStats?.overall?.total?.total?.[0]["games"],
          gamesWon: headToHeadStats?.overall?.total?.total?.[2]["team2_won"],
          gamesLost: headToHeadStats?.overall?.total?.total?.[1]["team1_won"],
          gamesDrawn: headToHeadStats?.overall?.total?.total?.[3]["draws"],
          goalsScored:
            headToHeadStats?.goals?.total?.total?.[2]["team2_scored"],
          goalsConceded:
            headToHeadStats?.goals?.total?.total?.[3]["team2_conceded"],
        },
      }));
    }
  }, [headToHeadStats]);

  // Fetch Graphic Data Data

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("rugby");

  useEffect(() => {
    // Team One Graphics
    if (activeHeadToHeadStats.teamOne.id !== "") {
      const matchingTeamOne = graphicData?.international?.find(
        (item) => item.id === activeHeadToHeadStats.teamOne.id
      );

      if (matchingTeamOne != undefined) {
        setActiveHeadToHeadStats((prevStats) => ({
          ...prevStats,
          teamOne: {
            ...prevStats.teamOne,
            flag: matchingTeamOne?.flag,
            name: matchingTeamOne?.name,
          },
        }));
      }
    }

    // Team Two Graphics
    if (activeHeadToHeadStats.teamTwo.id !== "") {
      const matchingTeamTwo = graphicData?.international?.find(
        (item) => item.id === activeHeadToHeadStats.teamTwo.id
      );

      if (matchingTeamTwo != undefined) {
        setActiveHeadToHeadStats((prevStats) => ({
          ...prevStats,
          teamTwo: {
            ...prevStats.teamTwo,
            flag: matchingTeamTwo?.flag,
            name: matchingTeamTwo?.name,
          },
        }));
      }
    }
  }, [
    graphicData,
    activeHeadToHeadStats.teamOne.id,
    activeHeadToHeadStats.teamTwo.id,
  ]);

  // Define Team Standings
  const [activeTeamStandings, setActiveTeamStandings] = useState([]);

  useEffect(() => {
    if (rugbyStandings.length > 0 && activeHeadToHeadStats) {
      const matchingLocalTeam = rugbyStandings
        .map((group) => group.team)
        .flat()
        .find((item) => item.id === activeHeadToHeadStats.teamOne.id);

      const matchingVisitorTeam = rugbyStandings
        .map((group) => group.team)
        .flat()
        .find((item) => item.id === activeHeadToHeadStats.teamTwo.id);

      if (matchingLocalTeam && matchingVisitorTeam) {
        const sortedTeams = [matchingLocalTeam, matchingVisitorTeam].sort(
          (a, b) => parseInt(a.pos, 10) - parseInt(b.pos, 10)
        );

        setActiveTeamStandings(sortedTeams);
      }
    }
  }, [rugbyStandings, activeHeadToHeadStats]);

  const [formattedGroupData, setFormattedGroupData] = useState([]);

  useEffect(() => {
    if (activeTeamStandings?.length > 0 && graphicData != null) {
      const formattedStandings = activeTeamStandings?.map((team) => {
        const matchingTeam = graphicData?.international?.find(
          (item) => item.id === team.id
        );

        if (matchingTeam != null) {
          return { ...team, flag: matchingTeam.flag };
        }

        return team;
      });

      setFormattedGroupData((prevData) => ({
        ...prevData,
        pool: formattedStandings,
      }));
    }
  }, [activeTeamStandings, graphicData]);

  // Download Image
  const imageFilename = "Head To Head.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [headToHeadInput]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  // Handle the loading state
  if (isLoading || isHeadToHeadDataFetching) {
    return <CustomSpinner />;
  }

  // Handle the error state
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  // Display the extracted team names or any other content
  return (
    <>
      {rugbyData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              {/* <Heading fontWeight="600" variant="h2">
                Head To <br />
                Head
              </Heading> */}
              <Flex mt={8} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Home Team"
                  name="localTeam"
                  onChange={handleHeadToHeadInputChange}
                  value={headToHeadInput.localTeam}
                >
                  {teamList != undefined && teamList?.length > 0
                    ? teamList.map((team, index) => (
                        <option key={index} value={team.id}>
                          {team.name}
                        </option>
                      ))
                    : "Awaiting Team List"}
                </Select>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Away Team"
                  name="visitorTeam"
                  onChange={handleHeadToHeadInputChange}
                  value={headToHeadInput.visitorTeam}
                >
                  {teamList != undefined && teamList?.length > 0
                    ? teamList.map((team, index) => (
                        <option key={index} value={team.id}>
                          {team.name}
                        </option>
                      ))
                    : "Awaiting Team List"}
                </Select>
              </Flex>

              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              {isHeadToHeadDataFetching ?? <ArtWorkSpinner />}
              <HeadToHeadArtwork
                activeHeadToHeadStats={activeHeadToHeadStats}
                formattedGroupData={formattedGroupData}
              />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default RugbyHeadToHead;
