import { createStandaloneToast, useToast } from "@chakra-ui/react";

const Toast = ({ activeComponent }) => {
  const { toast } = createStandaloneToast();
  const id = "success-toast";

  if (!toast.isActive(id)) {
    toast({
      id,
      title:
        activeComponent === "signIn"
          ? "Sign in successful"
          : "Sign Up Successful",
      status: "success",
      containerStyle: {
        width: "100%",
      },
    });
  }

  return;
};

export default Toast;
