import { Box, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// Animation
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import { useInView } from "react-intersection-observer";

const AboutSection = () => {
  const aboutBenefits = [
    {
      heading: "Preserves your Corporate Identity:",
      body: "The dedicated dashboard ensures that your corporate identity, carefully crafted by your design team, is translated flawlessly into various digital assets.",
    },
    {
      heading: "Customizable Digital Assets:",
      body: "The dashboard enables you to customize various digital assets according to your organisation's needs in seconds. Anyone without a design background can edit the digital assets.",
    },
    {
      heading: "Access to a dedicated database:",
      body: "Utilise your database of images and statistics to edit and display various imagery, names and associated statistics to an artwork instantaneously.",
    },
  ];

  // Framer
  const MotionText = motion(Text);

  const pageIntroVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: { delay: 0.5, duration: 1 },
    },
    exit: {
      opacity: 0,

      transition: { delay: 0.5, duration: 1 },
    },
  };

  const subHeadingVariants = {
    hidden: {
      opacity: 0,
      y: -20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1.5,
        duration: 1,
        when: "beforeChildren",
        staggerChildren: 0.4,
      },
    },
    exit: {
      opacity: 0,
      transition: { ease: "easeInOut" },
    },
  };

  // Typewtiter
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [typewriterStarted, setTypewriterStarted] = useState(false);

  useEffect(() => {
    if (inView && !typewriterStarted) {
      const timeoutId = setTimeout(() => {
        setTypewriterStarted(true);
      }, 100);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inView, typewriterStarted]);

  return (
    <>
      <Grid
        gridTemplateColumns={{ base: "100%", lg: "30% 40% 30%" }}
        margin="4rem 2rem 5rem 2rem"
        ref={ref}
        className="about-container"
      >
        <GridItem display={{ base: "none", lg: "block" }}></GridItem>
        <GridItem>
          <MotionText
            className="about-description"
            variant="p"
            textAlign={{ base: "left", md: "justify" }}
            variants={pageIntroVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            Developed and designed by RHEST, a youth and black owned tech and
            design driven studio with 8 years of experience. RHESTSub is
            designed to edit your organisation's digital assets, RHESTSub's is
            not designed to replace graphic designers but exponentially decrease
            the time of manipulating repetitive artworks needed during live
            broadcasting and events. <br /> <br />
            RHESTsub does not deploy any pre-made templates, the consultation
            process between RHESTsub and your brand ensures each digital is
            unique to your brand. Instead, a list of available artworks and
            related data is availed and designed by your designed. RHESTsub then
            translates the digital assets to data-driven artworks which are
            manipulated by a click of a button on your dedicated dashboard.
          </MotionText>
        </GridItem>
        <GridItem display={{ base: "none", lg: "block" }}></GridItem>
      </Grid>

      <Flex
        justifyContent=" space-between"
        margin="4rem 2rem"
        flexDir={{ base: "column", lg: "row" }}
      >
        {aboutBenefits.map((benefit, index) => (
          <Box
            key={index}
            width={{ base: "inherit", lg: "400px" }}
            margin={{ base: "1rem 2rem", lg: "0" }}
          >
            <Heading
              variant="h4"
              marginBottom="0.5rem"
              height={{ base: "auto", md: "77px" }}
              minHeight={{ base: "81px", md: "77px" }}
            >
              <Typewriter
                options={{
                  strings: benefit.heading,
                  autoStart: typewriterStarted,
                  delay: 75,
                  pauseFor: 1000000,
                  cursor: "",
                }}
              />
            </Heading>

            <MotionText
              variants={subHeadingVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              variant="p"
              as="p"
            >
              {benefit.body}
            </MotionText>
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default AboutSection;
