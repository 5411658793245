import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useFetch from "../../../../actions/useFetch";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
import useUrlState from "@ahooksjs/use-url-state";
import HeadToHeadArtworkSl from "../components/HeadToHeadArtworkSl";
import { useLocation } from "react-router";
import HeadToHeadResultsArtworkSl from "../components/kickOff/HeadToHeadResultsArtworkSl";
import { useQuery } from "react-query";
import axios from "axios";
import CustomSpinner from "../../components/CustomSpinner";

const fetchLeagueData = (leagueId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/standings/${leagueId}.xml?json=1`
  );
};

const fetchHeadToHeadData = (teamOne, teamTwo) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/h2h/${teamOne}/${teamTwo}?json=1`
  );
};

// const fetchCricketData = () => {
//   return axios.get(
//     "http://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/cricket/profile?id=2386?json=1"
//   );
// };

const HeadToHead = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  // Page Location
  const location = useLocation();

  //   Define and get inputs
  const [headToHeadInput, setHeadToHeadInput] = useUrlState({
    leagueId: "",
    homeTeam: "",
    visitorTeam: "",
    homeTeamPlayer: "",
    visitorTeamPlayer: "",
  });

  const handleHeadToHeadInputChange = (e) => {
    const { name, value } = e.target;

    setHeadToHeadInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  // Fetch Head To Data
  const {
    data: standingsData,
    isPending: isStandingsDataPending,
    isFetching: isStandingsDataFetching,
    error: standingsListError,
    refetch: refetchLeagueData,
  } = useQuery("standingsData", () =>
    fetchLeagueData(headToHeadInput.leagueId)
  );

  const {
    data: headToHeadData,
    isPending: isHeadToHeadDataPending,
    isFetching: isHeadToHeadDataFetching,
    error: headToHeadDataError,
    refetch: refetchHeadToHeadData,
  } = useQuery("standingsData", () =>
    fetchHeadToHeadData(headToHeadInput.homeTeam, headToHeadInput.visitorTeam)
  );

  // const { data: cricketData } = useQuery("cricketData", () =>
  //   fetchCricketData()
  // );

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("soccer");

  // Handle Head To Head Stats
  const [headToHeadStats, setHeadToheadStats] = useState("");

  // Fetch Team Standings Data
  useEffect(() => {
    if (headToHeadInput.leagueId != "") {
      refetchLeagueData();
    }
  }, [headToHeadInput.leagueId, refetchLeagueData]);

  const [teamList, setTeamList] = useState([]);
  useEffect(() => {
    if (standingsData) {
      setTeamList(standingsData?.data?.standings?.tournament?.team);
    }
  }, [standingsData]);

  // Fetch Head To Head Data
  useEffect(() => {
    if (headToHeadInput.homeTeam != "" && headToHeadInput.visitorTeam != "") {
      refetchHeadToHeadData();
    }
  }, [
    headToHeadInput.homeTeam,
    headToHeadInput.visitorTeam,
    refetchHeadToHeadData,
  ]);

  useEffect(() => {
    if (headToHeadData !== undefined) {
      setHeadToheadStats(headToHeadData?.data?.h2h);
    }
  }, [headToHeadData]);

  // set Active Teams
  const initializeTeamStats = () => ({
    id: "",
    name: "",
    flag: "",
    playerImage: "",
    biggestVictory: "",
    gamesWon: "",
    gamesLost: "",
    gamesDrawn: "",
    goalsScored: "",
    goalsConceded: "",
  });

  const [activeHeadToHeadStats, setActiveHeadToHeadStats] = useState({
    teamOne: initializeTeamStats(),
    teamTwo: initializeTeamStats(),
  });

  useEffect(() => {
    if (
      headToHeadData !== undefined &&
      headToHeadStats !== undefined &&
      headToHeadInput.leagueId != ""
    ) {
      setActiveHeadToHeadStats((prevStats) => ({
        ...prevStats,
        headToHeadResults: headToHeadStats?.top50?.match?.slice(0, 5),
        league: headToHeadInput.leagueId,
        teamOne: {
          id: headToHeadStats?.["@team1"],
          biggestVictory:
            (headToHeadStats?.biggest_victory?.team1?.match?.["@team1_score"] ||
              "") +
            " - " +
            (headToHeadStats?.biggest_victory?.team1?.match?.["@team2_score"] ||
              ""),
          gamesTotal: headToHeadStats?.overall?.total?.total?.[0]["@games"],
          gamesWon: headToHeadStats?.overall?.total?.total?.[1]["@team1_won"],
          gamesLost: headToHeadStats?.overall?.total?.total?.[2]["@team2_won"],
          gamesDrawn: headToHeadStats?.overall?.total?.total?.[3]["@draws"],
          goalsScored:
            headToHeadStats?.goals?.total?.total?.[0]["@team1_scored"],
          goalsConceded:
            headToHeadStats?.goals?.total?.total?.[1]["@team1_conceded"],
        },
        teamTwo: {
          id: headToHeadStats?.["@team2"],
          biggestVictory:
            (headToHeadStats?.biggest_victory?.team2?.match?.["@team1_score"] ||
              "") +
            " - " +
            (headToHeadStats?.biggest_victory?.team2?.match?.["@team2_score"] ||
              ""),
          gamesTotal: headToHeadStats?.overall?.total?.total?.[0]["@games"],
          gamesWon: headToHeadStats?.overall?.total?.total?.[2]["@team2_won"],
          gamesLost: headToHeadStats?.overall?.total?.total?.[1]["@team1_won"],
          gamesDrawn: headToHeadStats?.overall?.total?.total?.[3]["@draws"],
          goalsScored:
            headToHeadStats?.goals?.total?.total?.[2]["@team2_scored"],
          goalsConceded:
            headToHeadStats?.goals?.total?.total?.[3]["@team2_conceded"],
        },
      }));
    }
  }, [headToHeadStats, headToHeadData, headToHeadInput.leagueId]);

  // Set Active Data Graphics
  // Set Active Team Images
  const [imageList, setImageList] = useState({
    homeTeam: undefined,
    visitorTeam: undefined,
  });

  useEffect(() => {
    if (
      headToHeadInput.homeTeam !== "" &&
      headToHeadInput.visitorTeam !== "" &&
      headToHeadInput.leagueId !== "" &&
      graphicData
    ) {
      const matchingLocalTeam = graphicData[
        headToHeadInput.leagueId
      ]?.clubs?.find((item) => item?.id === headToHeadInput.homeTeam);

      if (matchingLocalTeam) {
        setImageList((prevData) => ({
          ...prevData,
          homeTeam: matchingLocalTeam.images,
        }));
      }

      const matchingVisitorTeam = graphicData[
        headToHeadInput.leagueId
      ]?.clubs?.find((item) => item?.id === headToHeadInput.visitorTeam);

      if (matchingVisitorTeam) {
        setImageList((prevData) => ({
          ...prevData,
          visitorTeam: matchingVisitorTeam.images,
        }));
      }
    }
  }, [
    headToHeadInput.homeTeam,
    headToHeadInput.visitorTeam,
    headToHeadInput.leagueId,
    graphicData,
  ]);

  // Set Active Data Flags

  useEffect(() => {
    // Team One Graphics
    if (
      activeHeadToHeadStats.teamOne.id &&
      headToHeadInput.homeTeamPlayer !== ""
    ) {
      const matchingTeamOne = graphicData?.[
        headToHeadInput.leagueId
      ]?.clubs?.find((item) => item.id === activeHeadToHeadStats.teamOne.id);

      if (matchingTeamOne != undefined) {
        setActiveHeadToHeadStats((prevStats) => ({
          ...prevStats,
          teamOne: {
            ...prevStats.teamOne,
            flag: matchingTeamOne?.flag,
            name: matchingTeamOne?.name,
            playerImage: headToHeadInput.homeTeamPlayer,
          },
        }));
      }
    }
  }, [
    graphicData,
    activeHeadToHeadStats.teamOne.id,
    headToHeadInput.homeTeamPlayer,
    headToHeadInput.leagueId,
  ]);

  useEffect(() => {
    // Team Two Graphics
    if (
      activeHeadToHeadStats.teamTwo.id !== "" &&
      headToHeadInput.visitorTeamPlayer !== ""
    ) {
      const matchingTeamTwo = graphicData?.[
        headToHeadInput.leagueId
      ]?.clubs?.find((item) => item.id === activeHeadToHeadStats.teamTwo.id);

      if (matchingTeamTwo != undefined) {
        setActiveHeadToHeadStats((prevStats) => ({
          ...prevStats,
          teamTwo: {
            ...prevStats.teamTwo,
            flag: matchingTeamTwo?.flag,
            name: matchingTeamTwo?.name,
            playerImage: headToHeadInput.visitorTeamPlayer,
          },
        }));
      }
    }
  }, [
    graphicData,
    activeHeadToHeadStats.teamTwo.id,
    headToHeadInput.visitorTeamPlayer,
    headToHeadInput.leagueId,
  ]);

  // Set Team Standings
  const [activeTeamStandings, setActiveTeamStandings] = useState([]);

  useEffect(() => {
    if (teamList?.length > 0) {
      const matchingLocalTeam = teamList?.find(
        (item) => item["@id"] === headToHeadInput.homeTeam
      );

      const matchingVisitorTeam = teamList?.find(
        (item) => item["@id"] === headToHeadInput.visitorTeam
      );

      if (matchingLocalTeam && matchingVisitorTeam) {
        const localTeamIndex = teamList.indexOf(matchingLocalTeam);
        const visitorTeamIndex = teamList.indexOf(matchingVisitorTeam);

        const sortedTeams = [matchingLocalTeam, matchingVisitorTeam].sort(
          (a, b) => {
            return teamList.indexOf(a) - teamList.indexOf(b);
          }
        );

        setActiveTeamStandings(sortedTeams);
      }
    }
  }, [headToHeadInput.homeTeam, headToHeadInput.visitorTeam, teamList]);

  // Team Head To Head Results Graphics
  const [activeHeadToHeadResults, setActiveHeadToHeadResults] =
    useState(undefined);

  useEffect(() => {
    if (activeHeadToHeadStats?.teamOne?.name && graphicData) {
      const formattedFixtures = activeHeadToHeadStats?.headToHeadResults?.map(
        (fixture) => {
          const matchingTeamOne = graphicData?.[
            headToHeadInput.leagueId
          ]?.clubs?.find((item) => item.id === fixture?.["@id1"]);

          const matchingTeamTwo = graphicData?.[
            headToHeadInput.leagueId
          ]?.clubs?.find((item) => item.id === fixture?.["@id2"]);

          if (matchingTeamOne) {
            return {
              ...fixture,
              teamOneFlag: matchingTeamOne.flag,
              teamTwoFlag: matchingTeamTwo.flag,
            };
          }
        }
      );
      if (activeHeadToHeadStats) {
        setActiveHeadToHeadResults((prevData) => ({
          ...prevData,
          league: activeHeadToHeadStats.league,
          teamOneName: activeHeadToHeadStats?.teamOne?.name,
          teamTwoName: activeHeadToHeadStats?.teamTwo?.name,
          teamOnePlayerImage: activeHeadToHeadStats?.teamOne?.playerImage,
          teamTwoPlayerImage: activeHeadToHeadStats?.teamTwo?.playerImage,
          results: formattedFixtures,
        }));
      }
    }
  }, [graphicData, activeHeadToHeadStats, headToHeadInput.leagueId]);

  const [displayedLeagueStandings, setDisplayedLeagueStandings] = useState([]);

  useEffect(() => {
    if (activeTeamStandings?.length > 0 && graphicData != null) {
      const formattedStandings = activeTeamStandings?.map((team) => {
        const matchingTeam = graphicData?.[
          headToHeadInput.leagueId
        ]?.clubs?.find((item) => item.id === team["@id"]);

        if (matchingTeam != null) {
          return { ...team, flag: matchingTeam.flag };
        }
      });

      setDisplayedLeagueStandings({
        league: headToHeadInput.leagueId,
        standings: formattedStandings,
      });
    }
  }, [activeTeamStandings, graphicData, headToHeadInput.leagueId]);

  // Download Image
  const imageFilename =
    location.pathname === "/soccer/head-to-head"
      ? "Head To Head.png"
      : "Head To Head Results.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [headToHeadInput]);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  const reset = () => {
    setHeadToHeadInput({
      leagueId: "",
      homeTeam: "",
      visitorTeam: "",
      homeTeamPlayer: "",
      visitorTeamPlayer: "",
    });

    setActiveHeadToHeadStats({
      teamOne: initializeTeamStats(),
      teamTwo: initializeTeamStats(),
    });
  };

  if (
    isGraphicDataPending ||
    isStandingsDataFetching ||
    isHeadToHeadDataFetching
  ) {
    return <CustomSpinner />;
  }

  return (
    <>
      {graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Flex mt={8} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="League"
                  name="leagueId"
                  onChange={handleHeadToHeadInputChange}
                  value={headToHeadInput.leagueId}
                >
                  <option value="1204">English Premier League</option>
                  <option value="1394">DStv Prem</option>
                </Select>
              </Flex>
              <Flex mt={3} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Home Team"
                  name="homeTeam"
                  onChange={handleHeadToHeadInputChange}
                  value={headToHeadInput.homeTeam}
                >
                  {teamList != undefined && teamList?.length > 0
                    ? teamList.map((team, index) => (
                        <option key={index} value={team["@id"]}>
                          {team["@name"]}
                        </option>
                      ))
                    : "Awaiting Team List"}
                </Select>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Away Team"
                  name="visitorTeam"
                  onChange={handleHeadToHeadInputChange}
                  value={headToHeadInput.visitorTeam}
                >
                  {teamList != undefined && teamList?.length > 0
                    ? teamList.map((team, index) => (
                        <option key={index} value={team["@id"]}>
                          {team["@name"]}
                        </option>
                      ))
                    : "Awaiting Team List"}
                </Select>
              </Flex>

              <Flex mt={3} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Home Team Player"
                  name="homeTeamPlayer"
                  onChange={handleHeadToHeadInputChange}
                  value={headToHeadInput.homeTeamPlayer}
                >
                  {imageList.homeTeam && imageList.homeTeam?.length > 0
                    ? imageList.homeTeam.map((player, index) => (
                        <option key={index} value={player.image}>
                          {player.name}
                        </option>
                      ))
                    : "Select Team First"}
                </Select>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Away Team Player"
                  name="visitorTeamPlayer"
                  onChange={handleHeadToHeadInputChange}
                  value={headToHeadInput.visitorTeamPlayer}
                >
                  {imageList.visitorTeam && imageList.visitorTeam?.length > 0
                    ? imageList.visitorTeam.map((player, index) => (
                        <option key={index} value={player.image}>
                          {player.name}
                        </option>
                      ))
                    : "Select Team First"}
                </Select>
              </Flex>

              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
              <Button
                variant="outlineButton"
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              {location.pathname === "/soccer/head-to-head" ? (
                <HeadToHeadArtworkSl
                  activeHeadToHeadStats={activeHeadToHeadStats}
                  displayedLeagueStandings={displayedLeagueStandings}
                />
              ) : (
                // <HeadToHeadArtwork
                //   activeHeadToHeadStats={activeHeadToHeadStats}
                //   displayedLeagueStandings={displayedLeagueStandings}
                // />
                // <HeadToHeadResultsArtwork
                //   activeHeadToHeadResults={activeHeadToHeadResults}
                //   displayedLeagueStandings={displayedLeagueStandings}
                // />
                <HeadToHeadResultsArtworkSl
                  activeHeadToHeadResults={activeHeadToHeadResults}
                  displayedLeagueStandings={displayedLeagueStandings}
                />
              )}
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default HeadToHead;
