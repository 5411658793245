import { Box, Button, Flex, Heading, Input, Text } from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../actions/useFetchFirebase";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
// Components
import Toast from "./uxComponents/Toast";

const SignIn = () => {
  const selectStyle = {
    width: "379px",
    fontSize: "0.85rem",
    border: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    color: "#000",
    outline: "none",
    p: "0",
    h: "30px",
    _focus: {
      outline: "none",
    },
  };

  const inputContainerStyle = {
    flexDir: "column",
    gap: "0.75rem",
  };

  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setLoginInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrorMessage("");
    e.target.value = "";
  };

  const navigate = useNavigate();

  const [isAuthDataPending, setIsAuthDataPending] = useState(false);
  const [isSignInSuccess, setIsSignInSuccess] = useState(false);
  const [activeComponent, setActiveComponent] = useState("");

  const login = async () => {
    setIsAuthDataPending(true);
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginInput.email,
        loginInput.password
      );
      setIsAuthDataPending(false);
      setIsSignInSuccess(true);
    } catch (error) {
      let errorMessage = "";

      if (error.code === "auth/user-not-found") {
        errorMessage = "Account does not exist.";
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Invalid Password.";
      } else if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email address.";
      } else if (loginInput.email === "") {
        setErrorMessage("Email address is required.");
      } else if (loginInput.password === "") {
        setErrorMessage("Password is required.");
      } else {
        errorMessage = "An error occurred. Please try again.";
      }
      setIsAuthDataPending(false);

      setErrorMessage(errorMessage);
    }
  };

  useEffect(() => {
    if (isSignInSuccess) {
      navigate("/");
      setActiveComponent("signIn");
    }
  }, [isSignInSuccess, navigate]);

  return (
    <Box
      margin="4rem 2rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        width="460px"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        height="350px"
      >
        <Heading variant="h2">Sign In</Heading>

        <Flex mt={8} mb={2} sx={inputContainerStyle}>
          <Input
            isRequired
            sx={selectStyle}
            placeholder="Email Address"
            onChange={handleInputChange}
            type="email"
            name="email"
            value={loginInput.email}
          />
          {errorMessage == "Invalid email address." ||
          errorMessage == "Account does not exist." ? (
            <Text color="rhestsubRed.900">{errorMessage}</Text>
          ) : null}
        </Flex>
        <Flex mt={3} mb={2} sx={inputContainerStyle}>
          <Input
            isRequired
            sx={selectStyle}
            onChange={handleInputChange}
            placeholder="Password"
            name="password"
            type="password"
            value={loginInput.password}
          />
          {errorMessage == "Invalid Password." ||
          errorMessage == "An error occurred. Please try again." ||
          errorMessage == "Password is required." ? (
            <Text color="rhestsubRed.900">{errorMessage}</Text>
          ) : null}
        </Flex>
        <Flex mt={6} mb={2}>
          <Button
            isLoading={isAuthDataPending ? true : false}
            loadingText="Signing In"
            width="379px"
            spinnerPlacement="end"
            variant="yellowButton"
            onClick={login}
          >
            Sign In
          </Button>
        </Flex>
        {isSignInSuccess && <Toast activeComponent={activeComponent} />}
      </Flex>
    </Box>
  );
};

export default SignIn;
