import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './style/Theme';
import {ChakraProvider } from '@chakra-ui/react';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'bottom' } }}>
      <App />
    </ChakraProvider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
