import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import ArgentinaImage from "../../../../assets/womensWorldCup/flags/Argentina.png";
import WcLogo from "../../../../assets/womensWorldCup/logo/FIFA-Womens-World-Cup-2023.png";
import Pattern from "../../../../assets/Horizontal pattern.svg";
import React, { useEffect } from "react";
import PlaceHolderImage from "../../../../assets/womensWorldCup/squadImages/Switzerland/I. Grings.png";
// Helpers
import { SaTimeHelper } from "../../../../helpers/SaTimeHelper";
import { WwwcGroupsHelper } from "../../../../helpers/WwcGroupsHelper";
import useFetch from "../../../../actions/useFetch";
import useUrlState from "@ahooksjs/use-url-state";
import { removeTrailingW } from "../../../../helpers/removeTrailingW";

const StandingsArtwork = ({ activeTeam, activeFixtures }) => {
  const singleClubContainer = {
    paddingBottom: "0.25rem",
    gap: "0.5rem",
    alignItems: "flex-end",
  };

  const singleClubName = {
    fontSize: "1rem",
  };

  const flagSize = {
    height: "25px",
  };

  const singleFixtureTime = {
    display: "flex",
    alignItems: "flex-end",
  };

  console.log("active Team", activeTeam);

  return (
    <>
      <Box
        overflow="hidden"
        height="700px"
        width="700px"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="artwork-container"
        backgroundColor="#fff8ef"
      >
        <Heading
          textTransform="uppercase"
          fontFamily="Neue Power Ultra"
          position="absolute"
          bottom="-100"
          right="1"
          fontSize="135px"
          className="fixture-heading"
        >
          Fixtures
        </Heading>
        <Flex
          className="artwork-header"
          justifyContent="space-between"
          alignItems="center"
          padding="1.3rem 2rem 0 2rem"
        >
          <Box zIndex="1">
            <Heading
              variant="h5"
              fontFamily="Neue Power Ultra"
              textTransform="uppercase"
              lineHeight="1.5rem"
              fontWeight="800"
            >
              Nigeria
              <Box color="#e5a428">Group Stage</Box>
              Fixtures
            </Heading>
          </Box>
          <Box zIndex="1">
            <Image src={WcLogo} height="100px" />
          </Box>
        </Flex>
        <Box
          className="artwork-body"
          display="flex"
          flexDir="column"
          alignContent="space-between"
          height="490px"
          alignItems="space-between"
          justifyContent="space-between"
          marginBottom="2rem"
          marginTop="0.5rem"
          paddingX="2rem"
        >
          <Box
            className="single-fixture"
            borderRadius="1rem"
            backgroundColor="rgb(255,255,255, 0.7)"
            width="60%"
            border="1px solid #e5a428"
            padding="0.5rem"
            zIndex="100"
          >
            <Flex
              className="single-fixture__fixture"
              justifyContent="space-between"
            >
              <Box>
                <Heading variant="h6">Group B</Heading>

                <Text variant="p" fontSize="0.85rem">
                  Melbourne Rectangular Stadium
                  <br />
                  Melbourne
                </Text>
              </Box>
              <Box>
                <Text variant="p" fontSize="0.85rem">
                  21.07.2023
                </Text>
              </Box>
            </Flex>
            <Flex
              className="single-fixture__info"
              justifyContent="space-between"
              paddingTop="0.5rem"
            >
              <Box>
                <Flex sx={singleClubContainer}>
                  <Image src={ArgentinaImage} sx={flagSize} />

                  <Heading variant="h6" sx={singleClubName}>
                    Canada
                  </Heading>
                </Flex>
                <Flex sx={singleClubContainer}>
                  <Image src={ArgentinaImage} sx={flagSize} />

                  <Heading variant="h6" sx={singleClubName}>
                    Canada
                  </Heading>
                </Flex>
              </Box>
              <Box sx={singleFixtureTime}>
                <Text variant="p" fontSize="0.85rem">
                  16:00
                </Text>
              </Box>
            </Flex>
          </Box>

          {/* ))} */}
        </Box>
        {/* {activeTeam && activeTeam.image != "" ? ( */}
        <Image
          // src={require(`../../../../assets/womensWorldCup/squadImages/${removeTrailingW(
          //   activeTeam.name
          // )}/${activeTeam.image}`)}
          src={PlaceHolderImage}
          marginBottom="100px"
          position="fixed"
          bottom="-120"
          right="-10"
          height="650px"
          zIndex="1"
        />

        <Image src={Pattern} />
      </Box>
    </>
  );
};

export default StandingsArtwork;
