export const ConsolidatedMatchEvents = (extractedMatchEvents) => {
  if (
    !extractedMatchEvents ||
    !extractedMatchEvents.localteam ||
    !extractedMatchEvents.visitorteam
  ) {
    return {
      localteam: [],
      visitorteam: [],
    };
  }

  const aggregatedEvents = {
    localteam: [],
    visitorteam: [],
  };

  const aggregateTeamEvents = (team, category) => {
    team.forEach((event) => {
      const existingEvent = aggregatedEvents[category].find(
        (e) => e.name === event.name && e.category === event.category
      );

      if (!existingEvent) {
        const newEvent = {
          name: event.name,
          category: event.category,
          minutes: [event.minute.toString()],
        };
        aggregatedEvents[category].push(newEvent);
      } else {
        existingEvent.minutes.push(event.minute.toString());
      }
    });

    // Sort the aggregatedEvents array for this team by the lowest minute in each event
    aggregatedEvents[category].sort((a, b) => {
      const minA = Math.min(...a.minutes.map((min) => parseInt(min)));
      const minB = Math.min(...b.minutes.map((min) => parseInt(min)));
      return minA - minB;
    });
  };

  aggregateTeamEvents(extractedMatchEvents.localteam, "localteam");
  aggregateTeamEvents(extractedMatchEvents.visitorteam, "visitorteam");

  return aggregatedEvents;
};
