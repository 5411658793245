import { Box, Image, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

// Helpers
import { removeTrailingW } from "../../../../../helpers/removeTrailingW";

const LeagueTable = ({ displayedLeagueStandings }) => {
  const statContainer = {
    fontSize: "1rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    paddingY: "0.4rem",
    maxWidth: "30px",
  };
  const statGoalDifferenceContainer = {};

  const formContainer = {
    fontSize: "0.75rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    paddingY: "0.4rem",
    width: "25px",
  };

  const statPosContainer = {
    maxWidth: "3.5rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    fontFamily: "Neue Power Bold",
    fontSize: "1rem",
    width: "23px",

    paddingY: "0",
  };

  const statContainerHeading = {
    // maxWidth: "3.5rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    color: "kickOffRed.900",
    fontFamily: "Neue Power Bold",
    fontSize: "1rem",
    paddingY: "0",
    maxWidth: "45px",
  };
  const statGoalDifferentContainerHeading = {
    // maxWidth: "3.5rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    color: "kickOffRed.900",
    fontFamily: "Neue Power Bold",
    fontSize: "1rem",
    paddingY: "0",
    maxWidth: "45px",
  };

  const formContainerHeading = {
    // maxWidth: "3.5rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    color: "kickOffRed.900",
    fontFamily: "Neue Power Bold",
    fontSize: "1rem",
    paddingY: "0",
    width: "25px",
  };

  const pointsContainerHeading = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    color: "kickOffRed.900",
    fontFamily: "Neue Power Bold",
    fontSize: "0.83rem",
    paddingY: "0",
    maxWidth: "25px",
  };

  const clubContainer = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0",
    fontSize: "1rem",
    paddingY: "0",
    paddingLeft: "0.25rem",
    width: "190px",
  };

  const clubContainerHeading = {
    letterSpacing: "1px",
    color: "black",
    color: "kickOffRed.900",
    fontFamily: "Neue Power Bold",
    paddingLeft: "0.25rem",
    fontSize: "1rem",
  };

  const flagContainer = {
    minWidth: "40px",
    paddingY: "0",
    paddingX: "0.25rem",
  };

  const flagStyling = {
    width: "30px",
  };

  const pointsContainer = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    fontSize: "1rem",
    paddingY: "0",
    width: "23px",
  };

  return (
    <Box
      className="artwork-body"
      display="flex"
      flexDir="column"
      // height={"490px"}
      justifyContent="center"
      // margin="1.1rem 0 2rem 0"
      paddingX="2rem"
    >
      <Box
        className="log-container"
        borderRadius="1rem"
        backgroundColor="rgb(255,255,255, 0.7)"
        border="1px solid #d2232a"
        padding="0.5rem"
        zIndex="100"
        paddingY="1rem"
      >
        <Table size="sm" variant="unstyled">
          <Thead
            borderBottom="1px solid #d2232a"
            width="1rem"
            fontFamily="Neue Power Bold"
          >
            <Th sx={statContainerHeading}></Th>
            <Th sx={statContainerHeading}></Th>
            <Th sx={clubContainerHeading}>Club</Th>
            <Th sx={statContainerHeading}>GP</Th>
            <Th sx={statContainerHeading}>W</Th>
            <Th sx={statContainerHeading}>D</Th>
            <Th sx={statContainerHeading}>L</Th>
            <Th sx={statContainerHeading}>GF</Th>
            <Th sx={statContainerHeading}>GA</Th>
            <Th sx={statGoalDifferentContainerHeading}>GD</Th>
            <Th sx={statContainerHeading}>Pts</Th>
            <Th sx={formContainerHeading}>Form</Th>
          </Thead>
          <Tbody>
            {displayedLeagueStandings?.standings &&
            displayedLeagueStandings.standings !== undefined ? (
              displayedLeagueStandings.standings.map((team, index) => (
                <Tr index={index} borderBottom="1px solid #d2232a">
                  <Td
                  // sx={
                  //   index === 0
                  //     ? {
                  //         ...statPosContainer,
                  //         _first: {
                  //           color: "#e5a428",
                  //         },
                  //       }
                  //     : index === 15 || index === 14 // Updated condition
                  //     ? {
                  //         ...statPosContainer,
                  //         _first: {
                  //           color: "#FF530D",
                  //         },
                  //       }
                  //     : statPosContainer
                  // }
                  >
                    {team?.["@position"]}
                  </Td>
                  <Td sx={flagContainer}>
                    {team && team.flag && (
                      <Image
                        sx={flagStyling}
                        src={require(`../../../../../assets/soccer/${displayedLeagueStandings?.league}/flags/${team.flag}`)}
                      />
                    )}
                  </Td>
                  {team && team.overall && (
                    <>
                      <Td sx={clubContainer} className="club-name-container">
                        {removeTrailingW(team?.["@name"])}
                      </Td>
                      <Td sx={statContainer}>{team.overall["@gp"]}</Td>
                      <Td sx={statContainer}>{team.overall["@w"]}</Td>
                      <Td sx={statContainer}>{team.overall["@d"]}</Td>
                      <Td sx={statContainer}>{team.overall["@l"]}</Td>
                      <Td sx={statContainer}>{team.overall["@gs"]}</Td>
                      <Td sx={statContainer}>{team.overall["@ga"]}</Td>
                      <Td sx={statGoalDifferenceContainer}>
                        {team.total["@gd"]}
                      </Td>
                      <Td sx={statContainer} textAlign="center">
                        {team.total["@p"]}
                      </Td>
                      <Td sx={formContainer}>{team["@recent_form"]}</Td>
                    </>
                  )}
                </Tr>
              ))
            ) : (
              <Tr borderBottom="1px solid #000">
                <Td sx={statPosContainer}>1</Td>
                <Td sx={clubContainer}>South Africa</Td>
                <Td sx={statContainer}>1</Td>
                <Td sx={statContainer}>1</Td>
                <Td sx={statContainer}>2</Td>
                <Td sx={statContainer}>0</Td>
                <Td sx={statContainer}>2</Td>
                <Td sx={statContainer}>3</Td>
                <Td sx={statContainer}>3</Td>
                <Td sx={pointsContainer}>3</Td>
                <Td sx={formContainer}>WDL</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default LeagueTable;
