import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import squadPlayer from "../../../../../assets/soccer/1204/squadImages/Manchester United/S. McTominay.png";
import ArgentinaImage from "../../../../../assets/womensWorldCup/flags/South-Africa.png";

// Helpers
import { SaTimeHelper } from "../../../../../helpers/SaTimeHelper";
import { FormatStatsName } from "../../../../../helpers/FormatStatsName";
import { removeTrailingW } from "../../../../../helpers/removeTrailingW";
import { formatPlayerName } from "../../../../../helpers/FormatPlayerName";
import HolyWoodBetsStrap from "../../../psl/components/UxComponents/HolyWoodBetsStrap";

const KoPlayerMatchStatsArtwork = ({
  formattedDisplayedStats,
  activeFixtureInfo,
}) => {
  // Styling
  const introTextStyling = {
    fontSize: "1rem",
    textAlign: "center",
    color: "#fff",
  };
  const flagSize = {
    height: "67px",
    width: "90px",
  };

  const singleTeamScoreContainer = {
    gap: "1rem",
    height: "90px",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  };

  const singleStatContainer = {
    padding: " 0.25rem 0",
    justifyContent: "space-between",
    alignItems: "baseline",
    color: "#fff",
  };

  const matchStatDescription = {
    textAlign: "center",
    fontSize: "1rem",
    color: "#fff",
  };

  const playerHeading = {
    variant: "45",
    fontFamily: "Neue Power Ultra",
    textTransform: "uppercase",
    lineHeight: "1.75rem",
    fontWeight: "800",
    paddingTop: "0.5rem",
    paddingBottom: "0",
    color: "#fff",
  };

  return (
    <Box
      overflow="hidden"
      height="700px"
      width="700px"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      className="artwork-container"
      backgroundColor="kickOffBackground.900"
    >
      <Heading
        textTransform="uppercase"
        className="fixture-heading__kick-off"
        variant="h1"
        fontSize="5.7rem"
        fontFamily="Neue Power Ultra"
        zIndex="1"
        position="relative"
        marginTop="1rem"
        marginLeft="1.5rem"
      >
        Statistics
      </Heading>
      <Flex
        className="artwork-heading"
        justifyContent="space-between"
        alignItems="center"
        margin="-8.35rem 2rem 0 2rem"
        backgroundSize="contain"
        zIndex="1"
      >
        <Box>
          <Heading
            variant="h5"
            fontFamily="Neue Power Ultra"
            textTransform="uppercase"
            lineHeight="1.5rem"
            fontWeight="800"
            position="relative"
            zIndex="1000"
            color="#fff"
          >
            Player
            <Box position="relative" zIndex="100" color="#fff">
              Match
            </Box>
            Stats
          </Heading>
        </Box>
        <Box>
          <Image
            src={require("../../../../../assets/clients/kickOff/KO Icon BW.png")}
            height="90px"
            // width="90px"
          />
        </Box>
      </Flex>
      <Flex
        className="artwork-body"
        flexDirection="column"
        marginX="2rem"
        justifyContent="center"
        height="450px"
        paddingTop="1.5rem"

        // flexWrap="wrap"
      >
        <Grid gridTemplateColumns="50% 50%" marginTop="-2.25rem">
          <GridItem paddingRight="1rem">
            <Image
              src={
                activeFixtureInfo.image != null &&
                activeFixtureInfo.image !== ""
                  ? require(`../../../../../assets/soccer/${activeFixtureInfo?.league}/squadImages/${activeFixtureInfo.activeTeamName}/${activeFixtureInfo.image}`)
                  : squadPlayer
              }
              style={{
                height: "550px",
                marginTop: "-60px",
                zIndex: 2,
                position: "fixed",
                right: "380px",
              }}
            />
          </GridItem>
          <GridItem>
            <Box
              className="stats-container"
              // flexWrap="wrap"
              display="flex"
              flexDir="column"
              justifyContent="center"
            >
              <Box className="fixture-info-container">
                <Text variant="p" sx={introTextStyling}>
                  {activeFixtureInfo &&
                  activeFixtureInfo.stadiumName !== undefined
                    ? activeFixtureInfo.stadiumName
                    : "FNB Stadium Johannesburg"}
                </Text>

                {activeFixtureInfo &&
                activeFixtureInfo.date != undefined &&
                activeFixtureInfo.time ? (
                  <Text sx={introTextStyling}>
                    {activeFixtureInfo.date},{" "}
                    {SaTimeHelper(activeFixtureInfo.time, 2)}
                  </Text>
                ) : (
                  <Text sx={introTextStyling}>31.07.2023, 17:00</Text>
                )}
                <Flex flexDirection="column" marginBottom="0.25rem">
                  <Flex
                    className="team-container"
                    justifyContent="space-between"
                    borderBottom="1px solid #d2232a"
                  >
                    <Flex
                      className="single-team-container"
                      sx={singleTeamScoreContainer}
                      paddingRight="4rem"
                    >
                      <Image
                        src={
                          activeFixtureInfo.localTeamFlag != ""
                            ? require(`../../../../../assets/soccer/${activeFixtureInfo?.league}/flags/${activeFixtureInfo.localTeamFlag}`)
                            : ArgentinaImage
                        }
                        sx={flagSize}
                      />
                      <Heading variant="KoHwbScoreTitle">
                        {activeFixtureInfo &&
                        activeFixtureInfo.localTeamScore != undefined
                          ? activeFixtureInfo.localTeamScore
                          : "0"}
                      </Heading>
                    </Flex>
                    <Flex
                      className="single-team-container"
                      sx={singleTeamScoreContainer}
                    >
                      <Image
                        src={
                          activeFixtureInfo.visitorTeamFlag != ""
                            ? require(`../../../../../assets/soccer/${activeFixtureInfo?.league}/flags/${activeFixtureInfo.visitorTeamFlag}`)
                            : ArgentinaImage
                        }
                        sx={flagSize}
                      />
                      <Heading variant="KoHwbScoreTitle">
                        {activeFixtureInfo &&
                        activeFixtureInfo.visitorTeamScore !== undefined
                          ? activeFixtureInfo.visitorTeamScore
                          : "0"}
                      </Heading>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
              <Flex flexDirection="column" className="stat-container">
                <Heading sx={playerHeading}>
                  {activeFixtureInfo.activePlayerName !== ""
                    ? formatPlayerName(activeFixtureInfo.activePlayerName)
                    : "T. Lebese"}
                </Heading>
                <Box
                  className="player-stats-container"
                  paddingY="0.5rem"
                  borderBottom="1px solid #d2232a"
                >
                  {formattedDisplayedStats != "" &&
                  formattedDisplayedStats.length > 0 ? (
                    formattedDisplayedStats.map(([key, value]) => (
                      <Flex
                        className="single-stat-container"
                        sx={singleStatContainer}
                      >
                        <Heading variant="KoHwbMatchStats">
                          {typeof key === "string" ? FormatStatsName(key) : ""}
                        </Heading>
                        <Heading variant="h6">{value}</Heading>
                      </Flex>
                    ))
                  ) : (
                    <>
                      <Flex
                        className="single-stat-container"
                        sx={singleStatContainer}
                      >
                        <Heading variant="h6" sx={matchStatDescription}>
                          Minutes Played
                        </Heading>
                        <Heading variant="h6">90</Heading>
                      </Flex>
                      <Flex
                        className="single-stat-container"
                        sx={singleStatContainer}
                      >
                        <Heading variant="h6" sx={matchStatDescription}>
                          Minutes Played
                        </Heading>
                        <Heading variant="h6">90</Heading>
                      </Flex>
                      <Flex
                        className="single-stat-container"
                        sx={singleStatContainer}
                      >
                        <Heading variant="h6" sx={matchStatDescription}>
                          Minutes Played
                        </Heading>
                        <Heading variant="h6">90</Heading>
                      </Flex>
                    </>
                  )}
                </Box>
              </Flex>
            </Box>
          </GridItem>
        </Grid>
      </Flex>
      <HolyWoodBetsStrap />
    </Box>
  );
};

export default KoPlayerMatchStatsArtwork;
