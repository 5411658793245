import axios from "axios";
import React, { useEffect, useState } from "react";
import useFetchFirebase from "../../../../actions/useFetchFirebase";
import useUrlState from "@ahooksjs/use-url-state";
import { useQuery } from "react-query";
import { Flex, Grid, GridItem, Heading, Select } from "@chakra-ui/react";
import CustomSpinner from "../../components/CustomSpinner";

const fetchClubsData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/standings/1394.xml?json=1"
  );
};

const fetchSquadData = (teamId) => {
  return axios.get(
    `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerstats/team/${teamId}?json=1`
  );
};

const fetchAfconData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/standings/1204.xml?json=1"
  );
};

const PlayerProfile = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("psl");

  //   Define and get inputs
  const [teamProfileInput, setTeamProfileInput] = useUrlState({
    id: "",
    playerId: "",
    playerImage: "",
    playerPosition: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTeamProfileInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const {
    data: teamData,
    isPending: isTeamDataPending,
    error: teamDataError,
  } = useQuery("teamData", fetchClubsData);

  const { data: afconData } = useQuery("afconData", fetchAfconData);

  console.log("afcon", afconData.data);

  const {
    data: squadData,
    isPending: isSquadDataPending,
    error: squadDataError,
  } = useQuery("squadData", () => fetchSquadData(teamProfileInput.id));

  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    setTeamList(teamData?.data?.standings?.tournament?.team);
  }, [teamData]);

  const [activeClubUrl, setActiveClubUrl] = useState(null);
  const [activeClubData, setActivelubData] = useState(null);

  useEffect(() => {
    if (teamProfileInput.id != "") {
      const activeClubsUrl = `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerstats/team/${teamProfileInput.id}?json=1`;
      setActiveClubUrl(activeClubsUrl);
    }
  }, [teamProfileInput.id]);

  useEffect(() => {
    if (activeClubUrl != null) {
      // setIsActiveClubStatsPending(true);
      fetch(activeClubUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Could not fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data != undefined) {
            // setHeadToheadStats(data.h2h);

            setActivelubData(data?.teams?.team);
          }
        })
        .catch((error) => {
          // Handle the error here, e.g., show an error message or set an error state
          console.error(error);
        });
    }
  }, [activeClubUrl]);

  const [playerList, setPlayerList] = useState([]);

  useEffect(() => {
    if (activeClubData) {
      setPlayerList(activeClubData?.squad?.player);
    }
  }, [activeClubData]);

  const [activePlayerUrl, setActivePlayerUrl] = useState(null);
  const [activePlayerData, setactivePlayerData] = useState(null);

  useEffect(() => {
    if (teamProfileInput.id != "") {
      const activePlayerUrl = `https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/soccerstats/player/${teamProfileInput.playerId}?json=1`;
      setActivePlayerUrl(activePlayerUrl);
    }
  }, [teamProfileInput.playerId]);

  useEffect(() => {
    if (activePlayerUrl != null) {
      // setIsActiveClubStatsPending(true);
      fetch(activePlayerUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Could not fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data != undefined) {
            // setHeadToheadStats(data.h2h);

            setactivePlayerData(data?.players?.player);
          }
        })
        .catch((error) => {
          // Handle the error here, e.g., show an error message or set an error state
          console.error(error);
        });
    }
  }, [activePlayerUrl]);

  const [activePlayerStats, setActivePlayerStats] = useState({
    firstName: "",
    lastname: "",
    nationality: "",
    age: "",
    position: "",
    stats: {
      appearances: "",
      goalKeeping: {},
      defending: [],
      attacking: [],
    },
  });

  useEffect(() => {
    if (activePlayerData) {
      setActivePlayerStats({
        firstName: activePlayerData?.firstname,
        lastName: activePlayerData?.lastname,
        nationality: activePlayerData?.nationality,
        age: activePlayerData?.age,

        stats: {
          appearances: activePlayerData?.statistic?.club?.[0]["@appearences"],
          startingEleven: activePlayerData?.statistic?.club?.[0]["@lineups"],
          minutes: activePlayerData?.statistic?.club?.[0]["@minutes"],
          yellowCards: activePlayerData?.statistic?.club?.[0]["@yellowcards"],
          redCards: activePlayerData?.statistic?.club?.[0]["@redcards"],
          passes: activePlayerData?.statistic?.club?.[0]["@passes"],
          keyPasses: activePlayerData?.statistic?.club?.[0]["@keyPasses"],
          passAccuracy: activePlayerData?.statistic?.club?.[0]["@pAccuracy"],
          goalKeeping: {
            goalsConceded:
              activePlayerData?.statistic?.club?.[0]["@goalsConceded"],
            penaltiesSaved: activePlayerData?.statistic?.club?.[0]["@penSaved"],
            saves: activePlayerData?.statistic?.club?.[0]["@saves"],
          },
          defending: {
            goalsConceded:
              activePlayerData?.statistic?.club?.[0]["@goalsConceded"],
            blocks: activePlayerData?.statistic?.club?.[0]["@blocks"],
            interceptions:
              activePlayerData?.statistic?.club?.[0]["@interceptions"],
            tackles: activePlayerData?.statistic?.club?.[0]["@tackles"],
            clearrances: activePlayerData?.statistic?.club?.[0]["@clearances"],
            duels: activePlayerData?.statistic?.club?.[0]["@duelsTotal"],
            duelsWon: activePlayerData?.statistic?.club?.[0]["@duelsWon"],
            foulsCommitted:
              activePlayerData?.statistic?.club?.[0]["@foulsCommitted"],
            foulsCommitted:
              activePlayerData?.statistic?.club?.[0]["@foulsCommitted"],
          },

          attacking: {
            assists: activePlayerData?.statistic?.club?.[0]["@assists"],
            goals: activePlayerData?.statistic?.club?.[0]["@goals"],
            shots: activePlayerData?.statistic?.club?.[0]["@shotsTotal"],
            shotsOnTarget: activePlayerData?.statistic?.club?.[0]["@shotsOn"],
            foulsdrawn: activePlayerData?.statistic?.club?.[0]["@fouldDrawn"],
            dribbleAttempts:
              activePlayerData?.statistic?.club?.[0]["@dribbleAttempts"],
            dribbleSuccess:
              activePlayerData?.statistic?.club?.[0]["@dribbleSucc"],
          },
        },
      });
    }
  }, [activePlayerData]);

  if (isTeamDataPending) {
    return <CustomSpinner />;
  }

  console.log(activePlayerData);
  console.log(activePlayerStats);
  return (
    <>
      {teamData && graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h2">
                Player Profile
              </Heading>
              <Flex mt={8} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Team Name"
                  name="id"
                  onChange={handleInputChange}
                  value={teamProfileInput.id}
                >
                  {teamList != undefined && teamList?.length > 0
                    ? teamList.map((team, index) => (
                        <option key={index} value={team["@id"]}>
                          {team["@name"]}
                        </option>
                      ))
                    : "Awaiting Team List"}
                </Select>
              </Flex>

              <Flex mt={3} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Player Name"
                  name="playerId"
                  onChange={handleInputChange}
                  value={teamProfileInput.playerId}
                >
                  {playerList != undefined && playerList?.length > 0
                    ? playerList.map((player, index) => (
                        <option key={index} value={player["@id"]}>
                          {player["@name"]}
                        </option>
                      ))
                    : "Choose a player first"}
                </Select>
              </Flex>

              <Flex mt={3} mb={2} gap="2rem">
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Position"
                  name="playerPosition"
                  onChange={handleInputChange}
                  value={teamProfileInput.playerPosition}
                >
                  {teamProfileInput.playerId !== "" ? (
                    <>
                      <option key="goalkeeping" value="goalkeeping">
                        GoalKeeping
                      </option>
                      <option key="anotherOption" value="defending">
                        Defending
                      </option>
                      <option key="anotherOption" value="attacking">
                        Attacking
                      </option>
                    </>
                  ) : (
                    <option value="">Choose a team first</option>
                  )}
                </Select>
              </Flex>

              {/* <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>

              <Button variant="outlineButton" onClick={handleReset}>
                Reset
              </Button> */}
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              {/* <TeamProfileArtwork activeClubStats={activeClubStats} /> */}
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default PlayerProfile;
