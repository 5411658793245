import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import ScrollToTop from "./actions/ScrollToTop";

// Soccer Pages
import LeagueFixtures from "./features/sports/psl/pages/LeagueFixtures";
import HeadToHead from "./features/sports/psl/pages/HeadToHead";
import LeagueInGameUpates from "./features/sports/psl/pages/LeagueInGameUpdates";
import LeagueStartingEleven from "./features/sports/psl/pages/LeagueStartingEleven";
import LeagueTeamMatchStats from "./features/sports/psl/pages/LeagueTeamMatchStats";
import LeaguePlayerMatchStats from "./features/sports/psl/pages/LeaguePlayerMatchStats";
import Interview from "./features/sports/psl/pages/Interview";
import TeamProfile from "./features/sports/psl/pages/TeamProfile";
import LeagueStandings from "./features/sports/psl/pages/LeagueStandings";
import Account from "./features/users/pages/Account";
import TopGoalScorerAssist from "./features/sports/soccer/pages/TopGoalScorerAsisst";
import PlayerProfile from "./features/sports/psl/pages/PlayerProfile";

// Rugby Pages
import RugbyGroupStandings from "./features/sports/rugby/leagueKnockout/pages/RugbyGroupStandings";
import RugbyPoolFixtures from "./features/sports/rugby/leagueKnockout/pages/RugbyPoolFixtures";
import LiveRugbyScore from "./features/sports/rugby/rugbyGeneric/pages/LiveRugbyScore";
import RugbyHeadToHead from "./features/sports/rugby/leagueKnockout/pages/RugbyHeadToHead";
import RugbyLiveFixtures from "./features/sports/rugby/rugbyGeneric/pages/RugbyLiveFixtures";

// Front end pages
import Home from "./features/frontend/pages/Home";
import About from "./features/frontend/pages/About";
import HowItWorks from "./features/frontend/pages/HowItWorks";
import Contact from "./features/frontend/pages/Contact";

// Components
import Sidebar from "./components/Sidebar";
import ConditionalSidebar from "./layouts/ConditionalSidebar";
import Navbar from "./features/frontend/components/Navbar";
import Footer from "./features/frontend/components/Footer";
import FullWidthSpinner from "./features/dashboard/FullWidthSpinner";

// Providers
import { QueryClientProvider, QueryClient, Query } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import LiveFixtures from "./features/sports/psl/pages/LiveFixtures";
import LandingDashboard from "./features/dashboard/LandingDashboard";
import Fixtures from "./features/sports/womensWorldCup/pages/Fixtures";

import InfluencerScore from "./features/sports/pages/InfluencerScore";

// Auth
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./actions/useFetchFirebase";
import PlayerInMatchStats from "./features/sports/rugby/rugbyGeneric/pages/PlayerInMatchStats";

const queryClient = new QueryClient();

export const DashboardRouter = () => {
  // User Management
  // const navigate = useNavigate();
  // const [user, setUser] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [originalRoute, setOriginalRoute] = useState(null);

  // useEffect(() => {
  //   // Initialize the unsubscribe function outside the callback.
  //   let unsubscribe;

  //   // Attach the listener and store the unsubscribe function.
  //   unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     setUser(currentUser);

  //     // Check if user is authenticated and redirect if not.
  //     if (!currentUser) {
  //       // Save the original route before redirecting to login
  //       setOriginalRoute(window.location.pathname);
  //       navigate("/account");
  //     } else if (originalRoute) {
  //       // If the user is logged in and an original route exists, redirect to it
  //       navigate(originalRoute);
  //       setOriginalRoute(null);
  //     }

  //     setLoading(false);
  //   });

  //   // Return the unsubscribe function from this effect.
  //   return () => {
  //     // Unsubscribe when the component unmounts.
  //     if (unsubscribe) {
  //       unsubscribe();
  //     }
  //   };
  // }, [navigate, originalRoute]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div className="top-header">
          {/* {loading ? (
            <FullWidthSpinner />
          ) : ( */}
          <>
            <ConditionalSidebar>
              <Sidebar />
            </ConditionalSidebar>
            <Routes>
              <Route path="/account" element={<Account />} />
              <Route path="/" element={<LandingDashboard />} />

              <Route
                path="/dstv-prem/score-prediction"
                element={<InfluencerScore />}
              />
              <Route path="/dstv-prem/interview" element={<Interview />} />
              <Route path="/dstv-prem/fixtures" element={<LeagueFixtures />} />
              <Route
                path="/dstv-prem/starting-11"
                element={<LeagueStartingEleven />}
              />
              <Route
                path="/dstv-prem/score-update"
                element={<LeagueInGameUpates />}
              />
              <Route
                path="/dstv-prem/match-stats"
                element={<LeagueTeamMatchStats />}
              />
              <Route
                path="/dstv-prem/player-stats"
                element={<LeaguePlayerMatchStats />}
              />
              <Route path="/soccer/head-to-head" element={<HeadToHead />} />
              <Route
                path="/soccer/head-to-head-results"
                element={<HeadToHead />}
              />
              <Route path="/dstv-prem/team-profile" element={<TeamProfile />} />
              <Route
                path="/dstv-prem/player-profile"
                element={<PlayerProfile />}
              />
              <Route
                path="/dstv-prem/standings"
                element={<LeagueStandings />}
              />
              <Route path="dstv-prem/fixtures" element={<LeagueFixtures />} />
              <Route
                path="dstv-prem/live-fixtures"
                element={<LiveFixtures />}
              />
              <Route
                path="/soccer/top-goal-scorer"
                element={<TopGoalScorerAssist />}
              />
              <Route
                path="/soccer/top-assists"
                element={<TopGoalScorerAssist />}
              />
              <Route path="fixtures" element={<Fixtures />} />

              {/* Rugby */}
              <Route
                path="rugby/world-cup/standings"
                element={<RugbyGroupStandings />}
              />
              <Route
                path="rugby/world-cup/fixtures"
                element={<RugbyPoolFixtures />}
              />
              <Route
                path="rugby/world-cup/live-fixtures"
                element={<RugbyLiveFixtures />}
              />
              <Route
                path="rugby/world-cup/score-update"
                element={<LiveRugbyScore />}
              />

              <Route
                path="rugby/world-cup/player-match-stats"
                element={<PlayerInMatchStats />}
              />

              <Route
                path="rugby/world-cup/head-to-head"
                element={<RugbyHeadToHead />}
              />
            </Routes>
          </>
          {/* )} */}
        </div>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </>
  );
};

export const WebsiteRouter = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
};
