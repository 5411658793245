import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Spinner,
} from "@chakra-ui/react";
import useUrlState from "@ahooksjs/use-url-state";
import useFetchFirebase from "../../../../../actions/useFetchFirebase";
// Artwork
import RugbyStandingsArtwork from "../components/RugbyStandingsArtwork";
// Helpers
import { poolFormatter } from "../../../../../helpers/PoolFormatter";
import { downloadImage } from "../../../../../helpers/DownloadImage";
import CustomSpinner from "../../../components/CustomSpinner";
const fetchRugbyData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/1106_table?json=1"
  );
};

const RugbyGroupStandings = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",

    _active: {
      border: "1px solid #e5a428",
    },
  };

  const [activeGroup, setActiveGroup] = useUrlState({
    name: "",
    team: "",
    image: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setActiveGroup((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const {
    isLoading,
    data: rugbyData,
    isError,
    error,
  } = useQuery("group-standings", fetchRugbyData);

  if (isError) {
    console.log(error.message);
  }

  const [rugbyStandings, setRugbyStandings] = useState("");
  useEffect(() => {
    if (rugbyData != undefined) {
      setRugbyStandings(rugbyData?.data.standings?.category.league);
    }
  }, [rugbyData]);

  // Set Active Group Data
  const [activeGroupData, setActiveGroupData] = useState("");

  useEffect(() => {
    if (activeGroup.name !== "" && rugbyStandings?.length > 0) {
      const matchingGroup = rugbyStandings.find(
        (item) => item.name === activeGroup.name
      );

      // console.log(matchingGroup);
      if (matchingGroup) {
        setActiveGroupData(matchingGroup);
      }
    }
  }, [activeGroup.name, rugbyStandings]);

  // Set Graphic Data
  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("rugby");

  const [imageList, setImageList] = useState("");

  useEffect(() => {
    if (activeGroup.team != "") {
      const matchingTeam = graphicData?.international?.find(
        (item) => item.name === activeGroup?.team
      );

      if (matchingTeam) {
        setImageList(matchingTeam.images);
      }
    }
  }, [activeGroup.team, graphicData.international]);

  const [formattedGroupData, setFormattedGroupData] = useState("");
  useEffect(() => {
    if (activeGroupData?.team?.length > 0 && graphicData !== undefined) {
      const activePool = activeGroupData?.team?.map((team) => {
        const matchingTeam = graphicData?.international?.find(
          (item) => item.name === team?.name
        );

        if (matchingTeam !== undefined) {
          return {
            ...team,
            flag: matchingTeam.flag,
            gd: matchingTeam.gs - matchingTeam.ga,
          };
        }
        return team;
      });

      if (activePool !== activeGroupData.team) {
        setFormattedGroupData((prevData) => ({
          ...prevData,
          name: activeGroupData.name,
          playerImage: activeGroup.image,
          playerImageTeam: activeGroup.team,
          pool: activePool,
        }));
      }
    }
  }, [graphicData, activeGroupData, activeGroup.image]);

  // Download Image
  const imageFilename = "Group Standings.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [activeGroup]);

  const handleDownloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&ttl=5&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  if (isLoading) {
    return <CustomSpinner />;
  }

  return (
    <>
      {rugbyData && graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h2">
                Standings
              </Heading>
              <Flex mt={8} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Pools"
                  name="name"
                  onChange={handleInputChange}
                  value={activeGroup.name}
                >
                  {rugbyStandings && rugbyStandings.length > 1 ? (
                    rugbyStandings.map((group, index) => (
                      <option key={index} value={group.name}>
                        {poolFormatter(group.name)}
                      </option>
                    ))
                  ) : (
                    <option value="">No Pools Available</option>
                  )}
                </Select>
              </Flex>

              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Team"
                  name="team"
                  onChange={handleInputChange}
                  value={activeGroup.team}
                >
                  {activeGroupData?.team &&
                  activeGroupData?.team?.length > 0 ? (
                    activeGroupData?.team.map((team, index) => (
                      <option key={index} value={team.name}>
                        {team?.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Select Pool First</option>
                  )}
                </Select>
              </Flex>

              <Flex mt={3} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Image"
                  name="image"
                  onChange={handleInputChange}
                  value={activeGroup.image}
                >
                  {imageList && imageList.length > 0 ? (
                    imageList.map((image, index) => (
                      <option key={index} value={image.image}>
                        {image.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Select Group First</option>
                  )}
                </Select>
              </Flex>
              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={handleDownloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              <RugbyStandingsArtwork formattedGroupData={formattedGroupData} />
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default RugbyGroupStandings;
