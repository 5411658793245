import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../../actions/useFetchFirebase";
import Toast from "./uxComponents/Toast";
import { useNavigate } from "react-router";

const SignUp = () => {
  const selectStyle = {
    width: "379px",
    fontSize: "0.85rem",
    border: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    color: "#000",
    outline: "none",
    p: "0",
    h: "30px",
    _focus: {
      outline: "none",
    },
  };

  const inputContainerStyle = {
    flexDir: "column",
    gap: "0.75rem",
  };

  const [registerInput, setRegisterInput] = useState({
    displayName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setRegisterInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrorMessage("");
  };

  const [user, setUser] = useState({});

  useEffect(() => {
    const activeUser = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => activeUser();
  }, []);

  console.log(user);
  const navigate = useNavigate();

  const [isAuthDataPending, setIsAuthDataPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);
  const [activeComponent, setActiveComponent] = useState("");

  const handleRegister = async () => {
    setIsAuthDataPending(true);
    try {
      if (registerInput.password !== registerInput.confirmPassword) {
        setErrorMessage("Passwords do not match.");
        setIsAuthDataPending(false);
        return;
      }

      const user = await createUserWithEmailAndPassword(
        auth,
        registerInput.email,
        registerInput.password
      );

      sendEmailVerification(auth.currentUser);
      await updateProfile(auth.currentUser, {
        displayName: registerInput.displayName,
      });
      setIsAuthDataPending(false);
      setIsSignUpSuccess(true);
      console.log(user);
    } catch (error) {
      console.log(error);
      if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
        setErrorMessage("Email address already in use.");
      } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
        setErrorMessage("The password is too weak.");
      } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
        setErrorMessage("Invalid email address.");
      } else if (error.code === AuthErrorCodes.MISSING_EMAIL) {
        setErrorMessage("Email address is required.");
      } else if (error.code === AuthErrorCodes.MISSING_PASSWORD) {
        setErrorMessage("Password is required.");
      } else if (error.code === AuthErrorCodes.MISSING_DISPLAY_NAME) {
        setErrorMessage("Username is required.");
      } else if (error.code === AuthErrorCodes.DISPLAY_NAME_EXISTS) {
        setErrorMessage("Username already exists.");
      } else {
        setErrorMessage("An error occurred while signing up.");
      }
      setIsAuthDataPending(false);
    }
  };

  useEffect(() => {
    if (isSignUpSuccess) {
      navigate("/dashboard");
      setActiveComponent("signUp");
    }
  }, [isSignUpSuccess, navigate]);

  return (
    <Box
      margin="4rem 2rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        width="460px"
        height="350px"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Heading fontWeight="600" variant="h2">
          Sign Up
        </Heading>

        <Flex mt={8} mb={2} sx={inputContainerStyle}>
          <Input
            sx={selectStyle}
            placeholder="Company Name"
            name="displayName"
            value={registerInput.displayName}
            onChange={handleInputChange}
          />
          {errorMessage === "Username is required." ||
          errorMessage === "Username already exists." ||
          errorMessage === "Email address is required." ? (
            <Text color="rhestsubRed.900" mt={2}>
              {errorMessage}
            </Text>
          ) : null}
        </Flex>
        {/* <Flex mt={3} mb={2} sx={inputContainerStyle}>
          <Input
            sx={selectStyle}
            placeholder="Contact Number"
            name="phoneNumber"
            type="number"
            value={registerInput.phoneNumber}
            onChange={handleInputChange}
          />
        </Flex> */}
        <Flex mt={3} mb={2} sx={inputContainerStyle}>
          <Input
            isRequired
            sx={selectStyle}
            placeholder="Email Address"
            name="email"
            value={registerInput.email}
            onChange={handleInputChange}
          />
          {errorMessage === "Email address already in use." ||
          errorMessage === "Invalid email address." ? (
            <Text color="rhestsubRed.900" mt={2}>
              {errorMessage}
            </Text>
          ) : null}
        </Flex>
        <Flex mt={3} mb={2} sx={inputContainerStyle} gap={2}>
          <Input
            isRequired
            sx={selectStyle}
            placeholder="Password"
            onChange={handleInputChange}
            type="password"
            name="password"
            value={registerInput.password}
          />
        </Flex>
        <Flex mt={3} mb={2} sx={inputContainerStyle} gap={2}>
          <Input
            isRequired
            sx={selectStyle}
            placeholder="Confirm Password"
            onChange={handleInputChange}
            type="password"
            name="confirmPassword"
            value={registerInput.confirmPassword}
          />
          {errorMessage === "Passwords do not match." ||
          errorMessage === "Password is required." ||
          errorMessage === "An error occurred while signing up." ? (
            <Text color="rhestsubRed.900" mt={1}>
              {errorMessage}
            </Text>
          ) : null}

          {/* {errorMessage == "Passwords do not match." && (
            <Text color="rhestsubRed.900">{errorMessage}</Text>
          )}
          {errorMessage == "Password is required." && (
            <Text color="rhestsubRed.900">{errorMessage}</Text>
          )} */}
        </Flex>

        <Flex mt={6} mb={2}>
          <Button
            isLoading={isAuthDataPending ? true : false}
            loadingText="Siging Up"
            width="379px"
            spinnerPlacement="end"
            variant="yellowButton"
            onClick={handleRegister}
          >
            Sign Up
          </Button>
        </Flex>
        {isSignUpSuccess && <Toast activeComponent={activeComponent} />}
      </Flex>
    </Box>
  );
};

export default SignUp;
