import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import ArgentinaImage from "../../../../assets/womensWorldCup/flags/Argentina.png";
import WcLogo from "../../../../assets/womensWorldCup/logo/FIFA-Womens-World-Cup-2023.png";
import Pattern from "../../../../assets/Horizontal pattern.svg";
import React, { useEffect } from "react";
import PlaceHolderImage from "../../../../assets/womensWorldCup/squadImages/Italy/G. Dragoni.png";
import HeadingBackground from "../../../../assets/headings/Fixtures Heading.png";
// Helpers
import { SaTimeHelper } from "../../../../helpers/SaTimeHelper";
import { removeTrailingW } from "../../../../helpers/removeTrailingW";

const FixturesArtwork = ({ activeTeam, activeFixtures }) => {
  const singleClubContainer = {
    paddingBottom: "0.25rem",
    gap: "0.5rem",
    alignItems: "flex-end",
  };

  const singleClubName = {
    fontSize: "1rem",
  };

  const flagSize = {
    height: "25px",
  };

  const singleFixtureTime = {
    display: "flex",
    alignItems: "flex-end",
  };

  console.log("active Team", activeTeam);

  return (
    <>
      <Box
        overflow="hidden"
        height="700px"
        width="700px"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="artwork-container"
        backgroundColor="#fff8ef"
      >
        <Heading
          textTransform="uppercase"
          className="fixture-heading"
          variant="h1"
          fontSize="8rem"
          fontFamily="Neue Power Ultra"
          textAlign="center"
        >
          Fixtures
        </Heading>
        <Flex
          className="artwork-header"
          justifyContent="space-between"
          alignItems="center"
          margin="-9rem 2rem 0 2rem"
          background={`url(${HeadingBackground})`}
          backgroundSize="contain"
        >
          <Box zIndex="1">
            {activeTeam ? (
              <Heading
                variant="h5"
                fontFamily="Neue Power Ultra"
                textTransform="uppercase"
                lineHeight="1.5rem"
                fontWeight="800"
              >
                {removeTrailingW(activeTeam.name)}
                <Box color="#e5a428">Group Stage</Box>
                Fixtures
              </Heading>
            ) : (
              <Heading
                variant="h5"
                fontFamily="Neue Power Ultra"
                textTransform="uppercase"
                lineHeight="1.5rem"
                fontWeight="800"
              >
                Nigeria
                <Box color="#e5a428">Group Stage</Box>
                Fixtures
              </Heading>
            )}
          </Box>
          <Box zIndex="1">
            <Image src={WcLogo} height="100px" />
          </Box>
        </Flex>
        <Box
          className="artwork-body"
          display="flex"
          flexDir="column"
          height="490px"
          alignItems="space-between"
          justifyContent="space-between"
          marginBottom="1rem"
          marginTop="1rem"
          paddingX="2rem"
        >
          {Object.entries(activeFixtures).map(([index, fixture]) => (
            <Box
              key={index}
              className="single-fixture"
              borderRadius="1rem"
              backgroundColor="rgb(255,255,255, 0.7)"
              width="60%"
              border="1px solid #e5a428"
              padding="0.5rem"
              zIndex="100"
            >
              {/* Rest of the JSX code */}
              <Flex
                className="single-fixture__fixture"
                justifyContent="space-between"
              >
                <Box>
                  {activeTeam.group !== "" ? (
                    <Heading variant="h6">Group {activeTeam.group}</Heading>
                  ) : (
                    <Heading variant="h6">Group B</Heading>
                  )}

                  {fixture &&
                  fixture.stadiumName !== "" &&
                  fixture.stadiumCity !== undefined ? (
                    <Text variant="p" fontSize="0.85rem">
                      {fixture.stadiumName &&
                        fixture.stadiumName.replace(/\([^)]+\)/g, "").trim()}
                      <br />
                      {fixture.stadiumCity}
                    </Text>
                  ) : (
                    <Text variant="p" fontSize="0.85rem">
                      Melbourne Rectangular Stadium
                      <br />
                      Melbourne
                    </Text>
                  )}
                </Box>
                <Box>
                  {fixture && fixture.date !== "" ? (
                    <Text variant="p" fontSize="0.85rem">
                      {fixture.date}
                    </Text>
                  ) : (
                    <Text variant="p" fontSize="0.85rem">
                      21.07.2023
                    </Text>
                  )}
                </Box>
              </Flex>
              <Flex
                className="single-fixture__info"
                justifyContent="space-between"
                paddingTop="0.5rem"
              >
                <Box>
                  <Flex sx={singleClubContainer}>
                    {fixture && fixture.homeTeamFlag != null ? (
                      <Image
                        src={require(`../../../../assets/womensWorldCup/flags/${fixture.homeTeamFlag}`)}
                        sx={flagSize}
                      />
                    ) : (
                      <Image src={ArgentinaImage} sx={flagSize} />
                    )}
                    {fixture && fixture.homeTeam != null ? (
                      <Heading variant="h6" sx={singleClubName}>
                        {fixture.homeTeam}
                      </Heading>
                    ) : (
                      <Heading variant="h6" sx={singleClubName}>
                        Canada
                      </Heading>
                    )}
                    {fixture && fixture.homeTeamScore != null ? (
                      <Heading variant="h6" sx={singleClubName}>
                        {fixture.homeTeamScore}
                      </Heading>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <Flex sx={singleClubContainer}>
                    {fixture && fixture.visitorTeamFlag != null ? (
                      <Image
                        src={require(`../../../../assets/womensWorldCup/flags/${fixture.visitorTeamFlag}`)}
                        sx={flagSize}
                      />
                    ) : (
                      <Image src={ArgentinaImage} sx={flagSize} />
                    )}
                    {fixture && fixture.visitorTeam != null ? (
                      <Heading variant="h6" sx={singleClubName}>
                        {fixture.visitorTeam}
                      </Heading>
                    ) : (
                      <Heading variant="h6" sx={singleClubName}>
                        Canada
                      </Heading>
                    )}
                    {fixture && fixture.visitorTeamScore != null ? (
                      <Heading variant="h6" sx={singleClubName}>
                        {fixture.visitorTeamScore}
                      </Heading>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Box>
                <Box sx={singleFixtureTime}>
                  {fixture && fixture.time != undefined ? (
                    <Text variant="p" fontSize="0.85rem">
                      {SaTimeHelper(fixture.time, 2)}
                    </Text>
                  ) : (
                    <Text variant="p" fontSize="0.85rem">
                      16:00
                    </Text>
                  )}
                </Box>
              </Flex>
            </Box>
          ))}

          {/* ))} */}
        </Box>
        {activeTeam && activeTeam.image != "" ? (
          <Image
            src={require(`../../../../assets/womensWorldCup/squadImages/${removeTrailingW(
              activeTeam.name
            )}/${activeTeam.image}`)}
            marginBottom="100px"
            position="fixed"
            bottom="-120"
            right="-10"
            height="650px"
            zIndex="1"
          />
        ) : (
          <Image
            src={PlaceHolderImage}
            marginBottom="100px"
            position="fixed"
            bottom="-120"
            right="-10"
            height="650px"
            zIndex="1"
          />
        )}

        <Image src={Pattern} />
      </Box>
    </>
  );
};

export default FixturesArtwork;
