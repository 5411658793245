import {
  Box,
  Flex,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import HolyWoodBetsStrap from "../../../psl/components/UxComponents/HolyWoodBetsStrap";

// Helpers
import { removeTrailingW } from "../../../../../helpers/removeTrailingW";

const KoLeagueStandingsArtwork = ({ displayedLeagueStandings }) => {
  const statContainer = {
    fontSize: "0.8rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    paddingY: "0rem",
    maxWidth: "30px",
    color: "#fff",
    maxHeight: "20px",
  };
  const statGoalDifferenceContainer = {
    fontSize: "0.8rem",
    letterSpacing: "1px",
    color: "black",
    padding: "0.25rem",
    maxWidth: "50px",
    color: "#fff",
    minHeight: "20px",
  };

  const formContainer = {
    fontSize: "0.75rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    paddingY: "0rem",
    width: "25px",
    color: "#fff",
    minHeight: "20px",
  };

  const statPosContainer = {
    maxWidth: "3.5rem",
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    fontFamily: "Neue Power Bold",
    fontSize: "0.8rem",
    width: "23px",
    color: "#fff",
    paddingY: "0",
    minHeight: "20px",
  };

  const statContainerHeading = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    color: "#fff",
    fontFamily: "Neue Power Bold",
    fontSize: "0.8rem",
    paddingY: "0",
    width: "25px",
    minHeight: "20px",
  };

  const pointsContainerHeading = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0.25rem",
    color: "#fff",
    fontFamily: "Neue Power Bold",
    fontSize: "0.85rem",
    paddingY: "0",
    maxWidth: "25px",
    maxHeight: "20px",
  };

  const clubContainer = {
    letterSpacing: "1px",
    color: "black",
    paddingX: "0",
    fontSize: "0.8rem",
    paddingY: "0",
    paddingLeft: "0.25rem",
    width: "180px",
    color: "#fff",
    maxHeight: "20px",
  };

  const clubContainerHeading = {
    letterSpacing: "1px",
    // color: "black",
    color: "#fff",
    fontFamily: "Neue Power Bold",
    paddingLeft: "0.25rem",
    fontSize: "0.8rem",
    maxHeight: "20px",
  };

  const flagContainer = {
    maxWidth: "40px",
    paddingY: "0",
    paddingX: "0.25rem",
    maxHeight: "20px",
  };

  const flagStyling = {
    width: "22px",
  };

  return (
    <>
      <Box
        overflow="hidden"
        height="700px"
        width="700px"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="artwork-container"
        backgroundColor="kickOffBackground.900"
      >
        <Heading
          className="fixture-heading__kick-off"
          textTransform="uppercase"
          fontFamily="Neue Power Ultra"
          fontSize="85px"
          position="relative"
          zIndex="1"
          height="120"
          marginTop="-0.5rem"
          marginLeft="1.5rem"
        >
          Standings
        </Heading>
        <Flex
          className="artwork-header"
          justifyContent="space-between"
          alignItems="center"
          margin="-9.25rem 2rem 1rem 2rem"
          zIndex="1"
        >
          <Box>
            <Heading
              variant="h5"
              fontFamily="Neue Power Ultra"
              textTransform="uppercase"
              lineHeight="1.5rem"
              fontWeight="800"
              color="#fff"
            >
              <Box color="#fff">League</Box>
              Standings
            </Heading>
          </Box>
          <Box>
            <Image
              src={require("../../../../../assets/clients/kickOff/KO Icon BW.png")}
              height="70px"
              width="70px"
            />
          </Box>
        </Flex>
        <Box
          className="artwork-body"
          display="flex"
          flexDir="column"
          justifyContent="center"
          height="450px"
          paddingX="2rem"
          marginTop="-1.5rem"
        >
          <Box className="log-container" zIndex="10">
            <Table size="sm" variant="unstyled">
              <Thead
                borderBottom="1px solid #d2232a"
                fontFamily="Neue Power Bold"
              >
                <Tr maxHeight="20px">
                  <Th sx={statContainerHeading}></Th>
                  <Th sx={statContainerHeading}></Th>
                  <Th sx={statContainerHeading}></Th>
                  <Th sx={clubContainerHeading}>Club</Th>
                  <Th sx={statContainerHeading}>GP</Th>
                  <Th sx={statContainerHeading}>W</Th>
                  <Th sx={statContainerHeading}>D</Th>
                  <Th sx={statContainerHeading}>L</Th>
                  <Th sx={statContainerHeading}>GF</Th>
                  <Th sx={statContainerHeading}>GA</Th>
                  <Th sx={statContainerHeading}>GD</Th>
                  <Th sx={statContainerHeading}>PTS</Th>
                  <Th sx={pointsContainerHeading}>Form</Th>
                </Tr>
              </Thead>

              <Tbody>
                {displayedLeagueStandings.standings &&
                  displayedLeagueStandings.standings?.map((team, index) => (
                    <Tr
                      key={index}
                      borderBottom="1px solid #d2232a"
                      maxHeight="20px"
                    >
                      <Td
                        sx={
                          index === 0
                            ? {
                                ...statPosContainer,
                                _first: {
                                  color: "#fff",
                                },
                              }
                            : index === 18 || index === 19 || index === 17 // Updated condition
                            ? {
                                ...statPosContainer,
                                _first: {
                                  color: "#DE1F26",
                                },
                              }
                            : statPosContainer
                        }
                      >
                        {team?.["@position"]}
                      </Td>

                      <Td sx={statContainer}>
                        {team?.["@status"] === "up" ? (
                          <BiSolidUpArrow fontSize="0.85rem" color="#31B35D" />
                        ) : team?.["@status"] === "down" ? (
                          <BiSolidDownArrow
                            fontSize="0.85rem"
                            color="#DE1F26"
                          />
                        ) : null}
                      </Td>

                      <Td sx={flagContainer}>
                        {team && team.flag && (
                          <Image
                            sx={flagStyling}
                            src={require(`../../../../../assets/soccer/${displayedLeagueStandings?.competition}/flags/${team.flag}`)}
                          />
                        )}
                      </Td>

                      {team && team.overall && (
                        <>
                          <Td
                            sx={clubContainer}
                            className="club-name-container"
                          >
                            {removeTrailingW(team?.["@name"])}
                          </Td>
                          <Td sx={statContainer}>{team.overall["@gp"]}</Td>
                          <Td sx={statContainer}>{team.overall["@w"]}</Td>
                          <Td sx={statContainer}>{team.overall["@d"]}</Td>
                          <Td sx={statContainer}>{team.overall["@l"]}</Td>
                          <Td sx={statContainer}>{team.overall["@gs"]}</Td>
                          <Td sx={statContainer}>{team.overall["@ga"]}</Td>
                          <Td sx={statGoalDifferenceContainer}>
                            {team.total["@gd"]}
                          </Td>
                          <Td sx={statPosContainer}>{team.total["@p"]}</Td>
                          <Td sx={formContainer}>{team["@recent_form"]}</Td>
                        </>
                      )}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
        <HolyWoodBetsStrap />
      </Box>
    </>
  );
};

export default KoLeagueStandingsArtwork;
