import React, { useEffect, useState } from "react";
import {
  Link,
  Link as ReachLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import BackgroundImage from "../../../assets/stadium-grass.jpg";
import Thabang from "../../../assets/pundits/RHESTSub-female-pundit.png";
import useFetchFirebase from "../../../actions/useFetchFirebase";
import useFetch from "../../../actions/useFetch";
import useUrlState from "@ahooksjs/use-url-state";
import useSoccerContext from "../../../actions/useSoccerContext";
import SponsorSelect from "../components/SponsorSelect";

const InfluencerScore = () => {
  // Styling
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };
  const logoDimensions = {
    width: "95px",
    height: "auto",
  };
  const TextareaStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderTop: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  const headingStyling = {
    color: "white",
    textAlign: "center",
  };

  // Fetch Club and Pundits data
  const { data: clubData } = useFetchFirebase("clubs");
  const { data: punditsData } = useFetchFirebase("pundits");

  console.log(punditsData);

  const {
    data: sponsorData,
    error: sponsorDataErorr,
    isPending,
  } = useFetchFirebase("sponsors");

  // set pundits
  const [predictionData, setPredictionData] = useUrlState({
    punditName: "",
    homeTeam: "",
    homeScore: 0,
    awayTeam: "",
    awayScore: 0,
    punditComment: "",
    sponsor: "",
  });

  const handlePredictionInputChange = (e) => {
    const { name, value } = e.target;

    setPredictionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  // Set logo and pundit Image
  const [homeLogo, setHomeLogo] = useState("Kaizer_Chiefs.png");
  const [awayLogo, setAwayLogo] = useState("Orlando_Pirates.png");
  const [punditImage, setPunditImage] = useState("RHESTSub null-Pundit.png");

  useEffect(() => {
    // Set club logos
    if (clubData !== null) {
      const matchingHomeClub = clubData.find(
        (item) => item.name === predictionData.homeTeam
      );
      const matchingAwayClub = clubData.find(
        (item) => item.name === predictionData.awayTeam
      );

      if (matchingHomeClub) {
        setHomeLogo(matchingHomeClub.logo);
      }

      if (matchingAwayClub) {
        setAwayLogo(matchingAwayClub.logo);
      }
    }

    // Set Pundit Image
    if (punditsData !== null) {
      const matchingPundit = punditsData.find(
        (item) => item.name === predictionData.punditName
      );

      if (matchingPundit) {
        setPunditImage(matchingPundit.image);
      }
    }
  }, [
    clubData,
    punditsData,
    predictionData.homeTeam,
    predictionData.awayTeam,
    predictionData.punditName,
  ]);

  const { sponsorLogo } = useSoccerContext();
  // Download Image
  const imageFilename = "Score Prediction.png";
  const [isImageDataPending, setIsImageDataPending] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  // Set download URL
  useEffect(() => {
    setDownloadUrl(window.location.href);
  }, [predictionData]);
  // console.log(downloadUrl);

  const downloadImage = () => {
    setIsImageDataPending(true);
    const encodedUrl = encodeURIComponent(downloadUrl);
    fetch(
      `https://api.urlbox.io/v1/WkbCgJW0sEntrTr9/png?url=${encodedUrl}&selector=.artwork-container&retina=true&thumb_height=2000&thumb_width=2000&quality=100&force=true&download=Score%20Prediction.png`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not fetch image");
        }
        return response.blob();
      })
      .then((blob) => {
        const imageUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageFilename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(imageUrl);
        setIsImageDataPending(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setIsImageDataPending(false);
      });
  };

  console.log(predictionData);

  return (
    <>
      {clubData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
            >
              <Heading fontWeight="600" variant="h4">
                Score Prediction
              </Heading>
              <Flex mt={8} mb={3}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Pundit Name"
                  name="punditName"
                  onChange={handlePredictionInputChange}
                  value={predictionData.punditName}
                >
                  <option value="RHESTSub-female-pundit.png">
                    Refilwe Motaung
                  </option>
                  <option value="RHESTSub-male-pundit.png">
                    Thabang Modisakeng
                  </option>
                </Select>
              </Flex>
              <Flex gap="5" mt={8} mb={3}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Home Team"
                  name="homeTeam"
                  onChange={handlePredictionInputChange}
                  value={predictionData.homeTeam}
                >
                  {clubData.map((club) => (
                    <option key={club.id} value={clubData.name}>
                      {club.name}
                    </option>
                  ))}
                </Select>
                <Select
                  sx={selectStyle}
                  placeholder="Away Team"
                  name="awayTeam"
                  onChange={handlePredictionInputChange}
                  value={predictionData.awayTeam}
                >
                  {clubData.map((club) => (
                    <option key={club.id} value={club.name}>
                      {club.name}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Flex gap="5">
                <Input
                  sx={selectStyle}
                  placeholder="Home Score"
                  type="number"
                  name="homeScore"
                  onChange={handlePredictionInputChange}
                  value={predictionData.homeScore}
                />
                <Input
                  sx={selectStyle}
                  placeholder="Away Score"
                  type="number"
                  name="awayScore"
                  onChange={handlePredictionInputChange}
                  value={predictionData.awayScore}
                />
              </Flex>
              <Flex mt={8} mb={3}>
                <Textarea
                  placeholder="Pundit comments"
                  name="punditComment"
                  onChange={handlePredictionInputChange}
                  value={predictionData.punditComment}
                  sx={TextareaStyle}
                />
              </Flex>
              <Flex gap="5" mt={8} mb={3}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Sponsor"
                  name="sponsor"
                  onChange={handlePredictionInputChange}
                  value={predictionData.sponsor}
                >
                  <option value="McDonalds_Logo.png">McDonalds</option>
                </Select>
              </Flex>

              <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button>
            </GridItem>
            <GridItem width="100%">
              <Box
                className="artwork-container"
                backgroundImage={BackgroundImage}
                backgroundSize="cover"
              >
                <Flex
                  className="artwork-flex"
                  width="100%"
                  height="700px"
                  flexDir="column"
                  justifyContent="center"
                  paddingTop="2rem"
                >
                  <Box>
                    <Grid gridTemplateColumns="50% 50%" paddingX="2rem">
                      <GridItem
                        height="100%"
                        display="flex"
                        paddingTop="7.4rem"
                        alignContent="flex-end"
                        justifyContent="flex-end"
                        overflow="hidden"
                      >
                        {predictionData.punditName != "" ? (
                          <Image
                            src={require(`../../../assets/pundits/${predictionData.punditName}`)}
                            height="550px"
                          />
                        ) : (
                          <Image
                            src={require("../../../assets/pundits/RHESTSub null-Pundit.png")}
                            height="550px"
                          />
                        )}
                      </GridItem>
                      <GridItem
                        width="100%"
                        height="100%"
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="center"
                        alignContent="center"
                      >
                        <Heading
                          variant="h3"
                          textAlign="center"
                          color="#fff"
                          paddingTop="2rem"
                        >
                          Score Prediction
                        </Heading>
                        <Heading
                          variant="h5"
                          textAlign="center"
                          color="#e5a428"
                          paddingBottom="2rem"
                        ></Heading>
                        <Flex justifyContent="center" gap="5">
                          <Stack>
                            <Image
                              src={require(`../../../assets/clubLogos/${homeLogo}`)}
                              sx={logoDimensions}
                            ></Image>
                            <Heading sx={headingStyling} variant="h5">
                              {predictionData.homeScore}
                            </Heading>
                          </Stack>
                          <Stack>
                            <Image
                              src={require(`../../../assets/clubLogos/${awayLogo}`)}
                              sx={logoDimensions}
                            ></Image>
                            <Heading sx={headingStyling} variant="h5">
                              {predictionData.awayScore}
                            </Heading>
                          </Stack>
                        </Flex>
                        <Box width="100%">
                          {predictionData.punditComment ? (
                            <Text
                              color="#fff"
                              paddingTop="2rem"
                              textAlign="center"
                            >
                              {predictionData.punditComment}
                            </Text>
                          ) : (
                            <Text
                              color="#fff"
                              paddingTop="2rem"
                              textAlign="center"
                            >
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Donec vel egestas dolor, nec dignissim
                              metus.
                            </Text>
                          )}
                        </Box>
                        <Flex
                          justifyContent="center"
                          paddingTop="4rem"
                          // paddingRight="2rem"
                          gap="1rem"
                        >
                          {predictionData.sponsor != "" ? (
                            <Image
                              src={require(`../../../assets/Sponsors/${predictionData.sponsor}`)}
                              height="50px"
                              width="50px"
                            ></Image>
                          ) : (
                            <></>
                          )}
                        </Flex>
                      </GridItem>
                    </Grid>
                  </Box>
                </Flex>
              </Box>
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default InfluencerScore;
