import { Flex, Grid, GridItem, Heading, Select } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useFetchFirebase from "../../../../../actions/useFetchFirebase";
import useUrlState from "@ahooksjs/use-url-state";
import { ActiveFixtureDataHelper } from "../../../../../helpers/rugby/ActiveFixtureDataHelper";
import { formatPlayerName } from "../../../../../helpers/FormatPlayerName";
import CustomSpinner from "../../../components/CustomSpinner";

// const fetchRugbyLiveScoreData = () => {
//   return axios.get(
//     "http://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/live_stats?json=1"
//   );
// };

const fetchRugbyLiveScoreData = () => {
  return axios.get(
    "https://www.goalserve.com/getfeed/1361a0fbe326463ef05608db80a00c67/rugby/live_stats?date=09.09.2023&json=1"
  );
};

const PlayerInMatchStats = () => {
  const selectStyle = {
    width: "100%",
    h: "30px",
    fontSize: "0.85rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #e5a428",
    borderRadius: "0",
    p: "0",
    color: "#000",
  };

  // Fetch Data
  const {
    isLoading: isFixturesDataLoading,
    data: fixturesData,
    isError,
  } = useQuery("liveStats", fetchRugbyLiveScoreData);

  const {
    data: graphicData,
    isPending: isGraphicDataPending,
    error: graphicDataError,
  } = useFetchFirebase("rugby");

  // Define Active Fixture Data
  const [activeFixture, setActiveFixture] = useUrlState({
    id: "",
    team: "",
    player: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setActiveFixture((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const [activeFixtures, setActiveFixtures] = useState([""]);
  useEffect(() => {
    if (fixturesData !== null) {
      setActiveFixtures(fixturesData?.data?.commentaries?.tournament.match);
    }
  }, [fixturesData]);

  const [activeFixtureData, setActiveFixtureData] = useState(undefined);

  useEffect(() => {
    ActiveFixtureDataHelper(
      activeFixtures,
      fixturesData,
      activeFixture,
      setActiveFixtureData
    );
  }, [activeFixtures, fixturesData, activeFixture.id]);

  // Set Active Teams
  const [activeTeams, setActiveTeams] = useState([]);

  useEffect(() => {
    if (activeFixtureData) {
      const newActiveTeams = [
        {
          id: activeFixtureData?.localteam?.id,
          name: activeFixtureData?.localteam?.name,
          status: "localteam",
        },
        {
          id: activeFixtureData?.visitorteam.id,
          name: activeFixtureData?.visitorteam?.name,
          status: "visitorteam",
        },
      ];

      setActiveTeams(newActiveTeams);
    }
  }, [activeFixtureData]);

  // Define Player List
  const [activePlayers, setActivePlayers] = useState([""]);
  useEffect(() => {
    if (activeFixture.team != "") {
      if (activeFixture.team === "localteam") {
        setActivePlayers(activeFixtureData?.player_stats?.localteam?.player);
      } else if (activeFixture.team === "visitorteam") {
        setActivePlayers(activeFixtureData?.player_stats?.visitorteam?.player);
      }
    }
  }, [activeFixture.team]);

  // Define Active Player
  const [activePlayer, setActivePlayer] = useState("");

  useEffect(() => {
    if (activeFixture.player != "" && activePlayers?.length > 0) {
      const matchingPlayer = activePlayers?.find(
        (item) => item.id === activeFixture.player
      );

      if (matchingPlayer) {
        setActivePlayer(matchingPlayer);
      }
    }
  }, [activeFixture.player, activePlayers]);

  const [activePlayerData, setActivePlayerData] = useState({
    localTeamName: "",
    localTeamScore: "",
    localTeamFlag: "",
    visitorTeamName: "",
    visitorTeamScore: "",
    visitorTeamFlag: "",
    playerMatchStats: {
      defendersBeaten: "",
      metres: "",
      turnovers: "",
      offloads: "",
      tackles: "",
      kicks: "",
      passes: "",
      tries: "",
      tryAssists: "",
      yellowCard: "",
      redCards: "",
      cleanBreaks: "",
      lineoutsWon: "",
    },
  });

  useEffect(() => {
    if (activePlayer && activeFixtureData) {
      setActivePlayerData((prevData) => ({
        ...prevData,
        localTeamName: activeFixtureData.localteam.name,
        localTeamScore: activeFixtureData.localteam.score,
        visitorTeamScore: activeFixtureData.visitorteam.score,
        playerMatchStats: {
          defendersBeaten: activePlayer?.def_beaten,
          metres: activePlayer?.metres,
          turnovers: activePlayer?.turnovers,
          offloads: activePlayer?.offloads,
          tackles: activePlayer?.tackles,
          kicks: activePlayer?.kicks,
          passes: activePlayer?.passes,
          penalties: activePlayer?.penalties,
          tries: activePlayer?.penalties,
          tryAssists: activePlayer?.try_assist,
          yellowCard: activePlayer?.ycard,
          redCards: activePlayer?.rcard,
          cleanBreaks: activePlayer?.clean_brakes,
          lineoutsWon: activePlayer?.lineouts_won,
        },
      }));
    }
  }, [activePlayer, activeFixtureData]);

  if (isFixturesDataLoading) {
    return <CustomSpinner />;
  }

  console.log(activeFixtureData);
  console.log("active player", activePlayer);
  console.log("active player data", activePlayerData);

  return (
    <>
      {fixturesData && graphicData && (
        <Flex
          className="body-container"
          height="100vh"
          alignItems="flex-end"
          flexShrink="1"
          borderRadius={10}
        >
          <Grid
            className="body-container-grid"
            gridTemplateColumns="40% 60%"
            width="100%"
          >
            <GridItem
              className="input-container"
              background="#fff8ef"
              p={10}
              display="flex"
              flexDir="column"
              justifyContent="center"
              borderRight="1px solid #e5a428"
            >
              <Heading fontWeight="600" variant="h3">
                Player Match Sats
              </Heading>
              <Flex mt={8} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Fixture"
                  name="id"
                  onChange={handleInputChange}
                  value={activeFixture.id}
                >
                  {activeFixtures != undefined &&
                  Array.isArray(activeFixtures) ? (
                    activeFixtures.map((dailyFixture, index) => (
                      <option key={index} value={dailyFixture.id}>
                        {dailyFixture?.localteam?.name} -{" "}
                        {dailyFixture?.visitorteam?.name}
                      </option>
                    ))
                  ) : (
                    <option value={activeFixtures?.id}>
                      {`${activeFixtures?.localteam?.name} -
                        ${activeFixtures?.visitorteam?.name}`}
                    </option>
                  )}
                </Select>
              </Flex>
              <Flex mt={2} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Team"
                  name="team"
                  onChange={handleInputChange}
                  value={activeFixture.team}
                >
                  {activeTeams && activeTeams.length > 0 ? (
                    activeTeams.map((activeTeam, index) => (
                      <option key={index} value={activeTeam.status}>
                        {activeTeam.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Select Fixture First</option>
                  )}
                </Select>
              </Flex>
              <Flex mt={2} mb={2}>
                <Select
                  sx={selectStyle}
                  width="100%"
                  placeholder="Player"
                  name="player"
                  onChange={handleInputChange}
                  value={activeFixture.player}
                >
                  {activePlayers && activePlayers.length > 0 ? (
                    activePlayers.map((activePlayer, index) => (
                      <option key={index} value={activePlayer.id}>
                        {formatPlayerName(activePlayer.name)}
                      </option>
                    ))
                  ) : (
                    <option value="">Select Team First</option>
                  )}
                </Select>
              </Flex>
              {/* <Button
                isLoading={isImageDataPending ? true : false}
                loadingText="Downloading Image"
                spinnerPlacement="end"
                variant="outlineButton"
                onClick={downloadImage}
              >
                Download Image
              </Button> */}
            </GridItem>
            <GridItem height="700px" background="#fff8ef" width="100%">
              {/* <RugbyInGameUpdatesArtwork
                activeMatchEvents={activeMatchEvents}
              /> */}
            </GridItem>
          </Grid>
        </Flex>
      )}
    </>
  );
};

export default PlayerInMatchStats;
