import { Flex, Heading, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

const SubHeader = () => {
  // Framer Motion
  const MotionBox = motion(Box);
  const MotionHeading = motion(Heading);
  const MotionFlex = motion(Flex);

  const borderVariants = {
    hidden: {
      width: 0,
    },
    visible: {
      width: "100%",
      transition: { delay: 0.5, duration: 1 },
    },
  };
  const pageHeadingVariants = {
    hidden: {
      opacity: 0,
      y: -25,
    },
    visible: {
      opacity: 1,

      y: 0,
      transition: { delay: 0.5, duration: 1 },
    },
    exit: {
      opacity: 0,
      y: -25,
      transition: { delay: 0.5, duration: 1 },
    },
  };

  // Set Active page & heading
  const location = useLocation();

  const [pageHeading, setPageHeading] = useState("");

  useEffect(() => {
    if (location.pathname === "/contact") {
      setPageHeading("Contact");
    } else if (location.pathname === "/about") {
      setPageHeading("About");
    } else if (location.pathname === "/how-it-works") {
      setPageHeading("How It Works");
    }
  }, []);

  return (
    <>
      <MotionFlex
        height="250px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <MotionHeading
          variants={pageHeadingVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          variant="h1"
          marginX="2rem"
          textAlign="center"
        >
          {pageHeading}
        </MotionHeading>
      </MotionFlex>
      <MotionBox
        variants={borderVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        borderBottom="1px solid #e5a329"
      />
    </>
  );
};

export default SubHeader;
