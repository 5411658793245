import { useLocation } from "react-router";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import Pattern from "../../../../assets/Horizontal pattern.svg";
import RugbyPattern from "../../../../assets/rugby/Patterns/Rugby Horizontal pattern.svg";
import React, { useEffect } from "react";

// Logos
import DstvPremLogo from "../../../../assets/psl/DStv-Prem-logo.svg";
import RugbyWorldCupLogo from "../../../../assets/rugby/Competitions/Rugby World Cup Logo.svg";
import HollyWoodBetsLogo from "../../../../assets/clients/soccerLaduma/clientLogos/Holywood Bets Logo.svg";
import KickoffLogo from "../../../../assets/clients/soccerLaduma/Soccer Laduma Logo.png";
// import RugbyWorldCupLogo from "../../../../assets/rugby/International/";

// Images
import HomePlayer from "../../../../assets/psl/squadImages/Kaizer Chiefs/G. Msimango.png";
import AwayPlayer from "../../../../assets/psl/squadImages/Royal AM/M. Thikazi.png";
import RugbyHomePlayer from "../../../../assets/rugby/International/Players/South Africa/F. de Klerk 2.png";
import RugbyAwayPlayer from "../../../../assets/rugby/International/Players/Ireland/J. Sexton.png";
import NoUnder18 from "../../../../assets/clients/genericaClientAssets/No Under 18-01.svg";
// UX Components
import RugbyTable from "../../rugby/leagueKnockout/components/uxComponents/RugbyTable";
import LeagueTable from "./UxComponents/LeagueTable";
import HolyWoodBetsStrap from "./UxComponents/HolyWoodBetsStrap";
import HwbLeagueTable from "./UxComponents/HwbLeagueTable";

const HeadToHeadArtworkSl = ({
  activeHeadToHeadStats,
  displayedLeagueStandings,
  formattedGroupData,
}) => {
  const singleStatContainer = {
    paddingY: "0.25rem",
  };

  const flagSize = {
    height: "auto",
    width: "100px",
  };
  const rugbyflagSize = {
    height: "auto",
    width: "70px",
  };

  const matchStatHeading = {
    textAlign: "center",
  };
  const matchStatDescription = {
    textAlign: "center",
    fontSize: "1.1rem",
  };

  const location = useLocation();

  return (
    <>
      <Box
        overflow="hidden"
        height="700px"
        width="700px"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="artwork-container"
        backgroundColor="kickOffBackground.900"
      >
        <Heading
          textTransform="uppercase"
          className="fixture-heading__kick-off"
          variant="h1"
          marginTop="1rem"
          fontSize="4.3rem"
          fontFamily="Neue Power Ultra"
          // textAlign="center"
          marginLeft="1.5rem"
          height="120px"
          paddingBottom={
            location.pathname == "/rugby/world-cup/head-to-head" ? "0" : "1rem"
          }
        >
          Head To Head
        </Heading>
        <Flex
          className="artwork-header"
          justifyContent="space-between"
          alignItems="center"
          margin={
            location.pathname == "/rugby/world-cup/head-to-head"
              ? "-8.75rem 2rem 0 2rem"
              : "-8rem 2rem 0 2rem"
          }
        >
          <Box zIndex="1" className="title-container">
            {activeHeadToHeadStats?.teamOne?.name != "" &&
            activeHeadToHeadStats?.teamTwo?.name != "" ? (
              <Heading
                variant="h5"
                fontFamily="Neue Power Ultra"
                textTransform="uppercase"
                lineHeight="1.5rem"
                fontWeight="800"
                color="#fff"
              >
                {activeHeadToHeadStats?.teamOne?.name}
                <br />
                {activeHeadToHeadStats?.teamTwo?.name}
                <Box color="#fff">Head To Head</Box>
              </Heading>
            ) : (
              <Heading
                variant="h5"
                fontFamily="Neue Power Ultra"
                textTransform="uppercase"
                lineHeight="1.5rem"
                fontWeight="800"
                color="#fff"
              >
                Team One
                <Box color="#fff">Head To Head</Box>
                Team Two
              </Heading>
            )}
          </Box>
          <Box>
            <Image
              src={require("../../../../assets/clients/kickOff/KO Icon BW.png")}
              height="90px"
              width="90px"
            />
          </Box>
        </Flex>
        <Box
          className="artwork-container"
          marginTop={
            location.pathname == "/rugby/world-cup/head-to-head"
              ? "-2.5rem"
              : "-2rem"
          }
          zIndex="10"
          paddingTop="1rem"
          display="flex"
          flexDir="column"
          minHeight="495px"
          justifyContent="center"
        >
          <Grid
            gridTemplateColumns="25% 50% 25%"
            padding={
              location.pathname == "/rugby/world-cup/head-to-head"
                ? "1rem 0"
                : "1rem"
            }
            className="stats-container"
          >
            <GridItem></GridItem>
            <GridItem
              className="head-to-head"
              borderRadius="1rem"
              backgroundColor="rgb(255,255,255, 0.7)"
              border={
                location.pathname == "/rugby/world-cup/head-to-head"
                  ? "1px solid #BF1A2F"
                  : "1px solid #d2232a"
              }
              padding="0.5rem"
              zIndex="100"
              position="relative"
              display="flex"
              flexDir="column"
              height="100%"
              alignContent="center"
            >
              <Flex
                className="head-to-head__fixture"
                justifyContent="space-between"
              >
                {activeHeadToHeadStats?.teamOne?.flag != "" &&
                activeHeadToHeadStats?.teamOne?.flag ? (
                  location.pathname == "/rugby/world-cup/head-to-head" ? (
                    <Image
                      src={require(`../../../../assets/rugby/International/${activeHeadToHeadStats.teamOne.flag}`)}
                      sx={rugbyflagSize}
                    />
                  ) : (
                    <Image
                      src={require(`../../../../assets/soccer/${activeHeadToHeadStats?.league}/flags/${activeHeadToHeadStats?.teamOne?.flag}`)}
                      sx={flagSize}
                    />
                  )
                ) : (
                  // <Image src={ClubFlag} sx={flagSize} />
                  <></>
                )}
                {activeHeadToHeadStats?.teamTwo?.flag != "" &&
                activeHeadToHeadStats?.teamTwo?.flag ? (
                  location.pathname == "/rugby/world-cup/head-to-head" ? (
                    <Image
                      src={require(`../../../../assets/rugby/International/${activeHeadToHeadStats?.teamTwo?.flag}`)}
                      sx={rugbyflagSize}
                    />
                  ) : (
                    <Image
                      src={require(`../../../../assets/soccer/${activeHeadToHeadStats?.league}/flags/${activeHeadToHeadStats?.teamTwo.flag}`)}
                      sx={flagSize}
                    />
                  )
                ) : (
                  // <Image src={ClubFlag} sx={flagSize} />
                  <></>
                )}
              </Flex>

              <Box
                className="head-to-head__total-games"
                borderBottom={
                  location.pathname == "/rugby/world-cup/head-to-head"
                    ? "1px solid #BF1A2F"
                    : "1px solid #d2232a"
                }
                paddingY="0.5rem"
              >
                <Heading variant="h6" sx={matchStatHeading}>
                  Matches Played <br />
                  {activeHeadToHeadStats?.teamOne?.gamesTotal != "" ? (
                    <Box as="span" color="kickOffRed.900">
                      {activeHeadToHeadStats?.teamOne?.gamesTotal}
                    </Box>
                  ) : (
                    <Box as="span" color="kickOffRed.900">
                      60
                    </Box>
                  )}
                </Heading>
              </Box>

              <Box className="head-to-head__total-games" paddingTop="0.5rem">
                <Flex justifyContent="space-between" sx={singleStatContainer}>
                  {activeHeadToHeadStats != null &&
                  activeHeadToHeadStats?.teamOne?.biggestVictory != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamOne?.biggestVictory}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatHeading}>
                      0
                    </Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Biggest win
                  </Heading>
                  {activeHeadToHeadStats?.teamTwo?.biggestVictory !== "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamTwo?.biggestVictory}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                </Flex>

                <Flex justifyContent="space-between" sx={singleStatContainer}>
                  {activeHeadToHeadStats?.teamOne?.gamesWon != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamOne?.gamesWon}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Matches Won
                  </Heading>
                  {activeHeadToHeadStats?.teamTwo?.gamesWon != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamTwo?.gamesWon}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                </Flex>

                <Flex justifyContent="space-between" sx={singleStatContainer}>
                  {activeHeadToHeadStats?.teamOne?.gamesLost != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamOne?.gamesLost}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Matches Lost
                  </Heading>
                  {activeHeadToHeadStats?.teamTwo?.gamesLost != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamTwo?.gamesLost}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                </Flex>

                <Flex justifyContent="space-between" sx={singleStatContainer}>
                  {activeHeadToHeadStats?.teamOne?.gamesDrawn != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamOne?.gamesDrawn}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                  <Heading variant="h6" sx={matchStatDescription}>
                    Matches Drawn
                  </Heading>
                  {activeHeadToHeadStats?.teamTwo?.gamesDrawn != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamTwo?.gamesDrawn}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                </Flex>

                <Flex justifyContent="space-between" sx={singleStatContainer}>
                  {activeHeadToHeadStats?.teamOne?.goalsScored != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamOne?.goalsScored}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                  {location.pathname == "/rugby/world-cup/head-to-head" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      Points Scored
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      Goals Scored
                    </Heading>
                  )}
                  {activeHeadToHeadStats?.teamTwo?.goalsScored != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamTwo?.goalsScored}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                </Flex>

                <Flex justifyContent="space-between" sx={singleStatContainer}>
                  {activeHeadToHeadStats?.teamOne?.goalsConceded != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamOne?.goalsConceded}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                  {location.pathname == "/rugby/world-cup/head-to-head" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      Points Conceded
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      Goals Conceded
                    </Heading>
                  )}
                  {activeHeadToHeadStats?.teamTwo?.goalsConceded != "" ? (
                    <Heading variant="h6" sx={matchStatDescription}>
                      {activeHeadToHeadStats?.teamTwo?.goalsConceded}
                    </Heading>
                  ) : (
                    <Heading variant="h6" sx={matchStatDescription}>
                      0
                    </Heading>
                  )}
                </Flex>
              </Box>
            </GridItem>
          </Grid>

          {location.pathname == "/rugby/world-cup/head-to-head" ? (
            <Box paddingX="2rem" zIndex="100">
              <RugbyTable formattedGroupData={formattedGroupData} />
            </Box>
          ) : (
            <LeagueTable displayedLeagueStandings={displayedLeagueStandings} />
          )}
        </Box>

        <Box>
          <Image
            src={
              location.pathname === "/rugby/world-cup/head-to-head"
                ? RugbyHomePlayer
                : activeHeadToHeadStats?.teamOne?.name &&
                  activeHeadToHeadStats?.teamOne?.playerImage &&
                  require(`../../../../assets/soccer/${activeHeadToHeadStats?.league}/squadImages/${activeHeadToHeadStats?.teamOne.name}/${activeHeadToHeadStats?.teamOne.playerImage}`)
            }
            marginBottom="150px"
            position="fixed"
            bottom="-90px"
            right="380"
            height="550px"
            zIndex="0"
          />
          <Image
            src={
              location.pathname === "/rugby/world-cup/head-to-head"
                ? RugbyAwayPlayer
                : activeHeadToHeadStats?.teamTwo?.name &&
                  activeHeadToHeadStats?.teamTwo?.playerImage &&
                  require(`../../../../assets/soccer/${activeHeadToHeadStats?.league}/squadImages/${activeHeadToHeadStats?.teamTwo.name}/${activeHeadToHeadStats?.teamTwo.playerImage}`)
            }
            marginBottom="170px"
            position="fixed"
            bottom="-90px"
            right="-15"
            height="550px"
            zIndex="0"
          />
        </Box>

        <HolyWoodBetsStrap />
      </Box>
    </>
  );
};

export default HeadToHeadArtworkSl;
