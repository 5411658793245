import React, { useEffect, useState } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../actions/useFetchFirebase";
import { Button } from "@chakra-ui/react";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useNavigate } from "react-router";

const SignOut = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/account");
  };

  useEffect(() => {
    const activeUser = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => activeUser();
  }, []);

  return (
    <Button
      onClick={handleLogout}
      backgroundColor="transparent"
      border="none"
      fontSize="1rem"
      color="brand.900"
      fontWeight="900"
      mt={4}
      rightIcon={<RiLogoutCircleRLine />}
      width="100%"
      _hover={{
        background: "transparent",
        color: "#000000",
      }}
    >
      Lougout
    </Button>
  );
};

export default SignOut;
