import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import SundownsImage from "../../../../assets/psl/flags/Mamelodi Sundowns.png";
import RugbyPattern from "../../../../assets/rugby/Patterns/Rugby Horizontal pattern.svg";
import Pattern from "../../../../assets/Horizontal pattern.svg";
import React, { useEffect } from "react";
import DstvPremLogo from "../../../../assets/psl/DStv-Prem-logo.svg";
import RugbyWorldCupLogo from "../../../../assets/rugby/Competitions/Rugby World Cup Logo.svg";

import PlaceHolderImage from "../../../../assets/psl/squadImages/Stellenbosch/L. Langeveldt.png";
import RugbyPlaceHolderImage from "../../../../assets/rugby/International/Players/South Africa/Group 1.png";
// Helpers
import { SaTimeHelper } from "../../../../helpers/SaTimeHelper";
import { useLocation } from "react-router";

const LiveFixturesArtwork = ({ displayedFixtures, activeFixturesData }) => {
  const singleClubContainer = {
    paddingBottom: "0.25rem",
    // flexDir: "",
    gap: "0.5rem",
    alignItems: "center",
    height: "100%",
  };

  const singleTimeContainer = {
    paddingBottom: "0.5rem",
    // height: "100px",
  };

  const singleClubScoreContainer = {
    // justifyContent: "space-between",
    gap: "2rem",
    alignItems: "center",
    width: "170px",
  };

  const singleClubRugbyScoreContainer = {
    // justifyContent: "space-between",
    gap: "2rem",
    alignItems: "center",
    width: "150px",
  };

  const teamNameStyling = {
    fontSize: "0.95rem",
  };

  const singleClubFixtureContainer = {
    flexDir: "column",
    width: "170px",
  };

  const singleClubRugbyFixtureContainer = {
    flexDir: "column",
    width: "150px",
  };

  const flagSize = {
    width: "56px",
    height: "40px",
  };

  const scoreStyling = {
    fontSize: "2rem",
    color: "brand.900",
    marginX: "0.5rem",
  };

  const location = useLocation();

  return (
    <>
      <Box
        overflow="hidden"
        height="700px"
        width="700px"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="artwork-container"
        backgroundColor="#fff8ef"
      >
        <Heading
          textTransform="uppercase"
          className="fixture-heading"
          variant="h1"
          fontSize="8rem"
          fontFamily="Neue Power Ultra"
          textAlign="center"
          marginTop="-0.5rem"
        >
          Fixtures
        </Heading>
        <Flex
          className="artwork-header"
          justifyContent="space-between"
          alignItems="center"
          margin={
            location.pathname == "/rugby/world-cup/live-fixtures"
              ? "-13.75rem 2rem 0 2rem"
              : "-8.75rem 2rem 0 2rem"
          }
          // margin="-13.75rem 2rem 0 2rem"
        >
          <Box zIndex="1">
            <Heading
              variant="h5"
              fontFamily="Neue Power Ultra"
              textTransform="uppercase"
              lineHeight="1.5rem"
              fontWeight="800"
            >
              {location.pathname == "/rugby/world-cup/live-fixtures" ? (
                <>Rugby World Cup</>
              ) : (
                <>DSTV Prem</>
              )}
              <Box color="#e5a428"> {activeFixturesData}</Box>
              Fixtures
            </Heading>
          </Box>
          <Box zIndex="1">
            <Image
              src={
                location.pathname == "/rugby/world-cup/live-fixtures"
                  ? RugbyWorldCupLogo
                  : DstvPremLogo
              }
              height="90px"
            />
          </Box>
        </Flex>
        <Flex minHeight="520px" alignItems="center">
          <Box
            className="artwork-body"
            display="flex"
            gap="1rem"
            margin="1rem 0 1rem 0"
            paddingX="2rem"
          >
            <Box
              className="fixture"
              borderRadius="1rem"
              backgroundColor="rgb(255,255,255, 0.7)"
              // width="65%"
              border="1px solid #e5a428"
              padding="0.5rem"
              zIndex="100"
            >
              {displayedFixtures != null && displayedFixtures?.length > 0 ? (
                displayedFixtures.map((fixture, index) => (
                  <Box
                    key={index}
                    sx={singleTimeContainer}
                    className="single-time-container"
                  >
                    <Heading variant="h5" key={index} textAlign="center">
                      {SaTimeHelper(fixture.time, 2)}
                    </Heading>
                    <Heading variant="h6" color="brand.900" textAlign="center">
                      {fixture.matches?.[0]?.status === "Not Started"
                        ? ""
                        : fixture.matches?.[0]?.status}
                    </Heading>

                    <Flex flexDir="column">
                      {fixture.matches.map((match, index) => (
                        <Box
                          key={index}
                          display="flex"
                          width="100%"
                          justifyContent="space-between"
                          marginY="0.25rem"
                        >
                          <Flex alignItems="center">
                            <Flex
                              sx={
                                location.pathname ==
                                "/rugby/world-cup/live-fixtures"
                                  ? singleClubRugbyFixtureContainer
                                  : singleClubFixtureContainer
                              }
                              className="single-team-container"
                            >
                              <Flex
                                className="single-club-container__socre"
                                flexDir="column"
                              >
                                <Image
                                  src={
                                    location.pathname ==
                                    "/rugby/world-cup/live-fixtures"
                                      ? require(`../../../../assets/rugby/International/${match.localTeam?.flag}`)
                                      : require(`../../../../assets/psl/flags/${match.localTeam.flag}`)
                                  }
                                  sx={flagSize}
                                />

                                <Heading sx={teamNameStyling}>
                                  {match.localTeam.name}
                                </Heading>
                              </Flex>
                            </Flex>
                            <Heading sx={scoreStyling}>
                              {match.localTeam.score}
                            </Heading>
                          </Flex>

                          <Flex alignItems="center">
                            <Heading sx={scoreStyling}>
                              {match.visitorTeam.score}
                            </Heading>
                            <Flex
                              key={index}
                              sx={
                                location.pathname ==
                                "/rugby/world-cup/live-fixtures"
                                  ? singleClubRugbyFixtureContainer
                                  : singleClubFixtureContainer
                              }
                            >
                              <Flex
                                className="single-club-container__socre"
                                sx={
                                  location.pathname ==
                                  "/rugby/world-cup/live-fixtures"
                                    ? singleClubRugbyScoreContainer
                                    : singleClubScoreContainer
                                }
                                justifyContent="flex-end"
                              >
                                <Image
                                  src={
                                    location.pathname ==
                                    "/rugby/world-cup/live-fixtures"
                                      ? require(`../../../../assets/rugby/International/${match.visitorTeam?.flag}`)
                                      : require(`../../../../assets/psl/flags/${match.visitorTeam.flag}`)
                                  }
                                  sx={flagSize}
                                />
                              </Flex>
                              <Heading sx={teamNameStyling} textAlign="right">
                                {match.visitorTeam.name}
                              </Heading>
                            </Flex>
                          </Flex>
                        </Box>
                      ))}
                    </Flex>
                  </Box>
                ))
              ) : !Array.isArray(displayedFixtures) ? (
                <Box sx={singleTimeContainer} className="single-time-container">
                  <Heading variant="h5" textAlign="center">
                    {SaTimeHelper(displayedFixtures?.time, 2)}
                  </Heading>
                  <Heading variant="h6" color="brand.900" textAlign="center">
                    {displayedFixtures?.status}
                  </Heading>

                  <Flex flexDir="column">
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      marginY="0.25rem"
                    >
                      <Flex alignItems="center">
                        <Flex
                          sx={
                            location.pathname ==
                            "/rugby/world-cup/live-fixtures"
                              ? singleClubRugbyFixtureContainer
                              : singleClubFixtureContainer
                          }
                          className="single-team-container"
                        >
                          <Flex
                            className="single-club-container__score"
                            flexDir="column"
                          >
                            <Image
                              src={
                                location.pathname ==
                                "/rugby/world-cup/live-fixtures"
                                  ? require(`../../../../assets/rugby/International/${displayedFixtures.localTeam?.flag}`)
                                  : require(`../../../../assets/psl/flags/${displayedFixtures.localTeam.flag}`)
                              }
                              sx={flagSize}
                            />

                            <Heading sx={teamNameStyling}>
                              {displayedFixtures.localTeam.name}
                            </Heading>
                          </Flex>
                        </Flex>
                        <Heading sx={scoreStyling}>
                          {displayedFixtures.localTeam.score}
                        </Heading>
                      </Flex>

                      <Flex alignItems="center">
                        <Heading sx={scoreStyling}>
                          {displayedFixtures.visitorTeam.score}
                        </Heading>
                        <Flex
                          sx={
                            location.pathname ==
                            "/rugby/world-cup/live-fixtures"
                              ? singleClubRugbyFixtureContainer
                              : singleClubFixtureContainer
                          }
                        >
                          <Flex
                            className="single-club-container__score"
                            sx={
                              location.pathname ==
                              "/rugby/world-cup/live-fixtures"
                                ? singleClubRugbyScoreContainer
                                : singleClubScoreContainer
                            }
                            justifyContent="flex-end"
                          >
                            <Image
                              src={
                                location.pathname ==
                                "/rugby/world-cup/live-fixtures"
                                  ? require(`../../../../assets/rugby/International/${displayedFixtures.visitorTeam?.flag}`)
                                  : require(`../../../../assets/psl/flags/${displayedFixtures.visitorTeam.flag}`)
                              }
                              sx={flagSize}
                            />
                          </Flex>
                          <Heading sx={teamNameStyling} textAlign="right">
                            {displayedFixtures.visitorTeam.name}
                          </Heading>
                        </Flex>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              ) : (
                <Box>
                  <Flex
                    className="single-fixture__info"
                    justifyContent="space-between"
                    paddingTop="0.5rem"
                  >
                    <Flex justifyContent="space-between" width="100%">
                      <Flex sx={singleClubContainer}>
                        <Image src={SundownsImage} sx={flagSize} />
                      </Flex>
                      <Flex sx={singleClubContainer}>
                        <Image src={SundownsImage} sx={flagSize} />
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Box>
          </Box>
        </Flex>
        <></>

        <Image
          // src={require(`../../../../assets/womensWorldCup/squadImages/${removeTrailingW(
          //   activeTeam.name
          // )}/${activeTeam.image}`)}
          src={
            location.pathname == "/rugby/world-cup/live-fixtures"
              ? RugbyPlaceHolderImage
              : PlaceHolderImage
          }
          marginBottom="100px"
          position="fixed"
          bottom="-120"
          right="-10"
          height="620px"
          zIndex="1"
        />

        <Image
          src={
            location.pathname == "/rugby/world-cup/live-fixtures"
              ? RugbyPattern
              : Pattern
          }
        />
      </Box>
    </>
  );
};

export default LiveFixturesArtwork;
