import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import WcLogo from "../../../../assets/womensWorldCup/logo/FIFA-Womens-World-Cup-2023.png";
import squadPlayer from "../../../../assets/womensWorldCup/squadImages/Ireland/Group 1.png";
import ArgentinaImage from "../../../../assets/womensWorldCup/flags/South-Africa.png";
import Pattern from "../../../../assets/Vertical pattern.svg";
// Helpers
import EnsureTwoDigits from "../../../../helpers/EnsureTwoDigits";
import { formatPlayerName } from "../../../../helpers/FormatPlayerName";
import { SaTimeHelper } from "../../../../helpers/SaTimeHelper";
import { removeTrailingW } from "../../../../helpers/removeTrailingW";

const LeagueStartingElevenArtwork = ({
  activeMatchdaySquad,
  activeFixtureInfo,
}) => {
  const playerTextStyling = {
    fontSize: "0.9rem",
    lineHeight: "1.025rem",
  };

  const subHeadingStyling = {
    fontSize: "0.9rem",
    color: "#e5a428",
  };

  const subsTextStyling = {
    fontSize: "0.9rem",
    lineHeight: "1.025rem",
  };

  const flagSize = {
    height: "50px",
    width: "67px",
  };
  // Place Holder Starting 11
  const playerPlaceholder = [];

  for (let i = 1; i <= 11; i++) {
    const player = {
      name: "A. Player",
      number: i,
    };

    playerPlaceholder.push(player);
  }

  return (
    <>
      {/* {activeMatchDaySquad && activeFixtureInfo && ( */}

      <Box
        overflow="hidden"
        maxHeight="700px"
        maxWidth="700px"
        display="flex"
        flexDir="column"
        className="artwork-container"
      >
        <Grid
          gridTemplateColumns="50% 50%"
          overflow="hidden"
          padding="2rem 2rem 0 2rem"
        >
          <GridItem>
            <Box paddingTop="1.5rem" className="logo-container">
              <Image src={WcLogo} height="100px" />
              <Flex>
                {activeMatchdaySquad && activeMatchdaySquad.image !== null ? (
                  <Image
                    src={require(`../../../../assets/psl/squadImages/${removeTrailingW(
                      activeMatchdaySquad.name
                    )}/${activeMatchdaySquad.image}`)}
                    height="600px"
                    marginTop="-4rem"
                    position="relative"
                    zIndex="1"
                  />
                ) : (
                  <Image
                    src={squadPlayer}
                    height="600px"
                    marginTop="-4rem"
                    position="relative"
                    zIndex="1"
                  />
                )}

                <Image
                  src={Pattern}
                  height="750px"
                  marginTop="-200px"
                  marginLeft="-60px"
                  zIndex="0"
                  position="relative"
                  className="vertical-pattern"
                />
              </Flex>
            </Box>
          </GridItem>
          <GridItem
            paddingLeft="2rem"
            paddingTop="1.5rem"
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            alignItems="space-between"
          >
            <Box className="squad-name-container">
              <Box>
                {activeFixtureInfo.stadiumName !== null ? (
                  <Text variant="p" fontSize="0.85rem">
                    {activeFixtureInfo.stadiumName}
                  </Text>
                ) : (
                  <Text variant="p" fontSize="0.85rem">
                    FNB Stadium
                  </Text>
                )}
                {activeFixtureInfo.date !== null &&
                activeFixtureInfo.date !== undefined ? (
                  <Text fontSize="0.85rem">
                    {activeFixtureInfo.date}, &nbsp;
                    {SaTimeHelper(activeFixtureInfo.time, 2)}
                  </Text>
                ) : (
                  <Text fontSize="0.85rem">21.07.2023, 17:00</Text>
                )}
              </Box>
              <Flex className="fixture-container" gap="1rem">
                <Flex flexDir="column">
                  {activeFixtureInfo &&
                  activeFixtureInfo.localTeamFlag != "" ? (
                    <Image
                      sx={flagSize}
                      src={require(`../../../../assets/psl/flags/${activeFixtureInfo.localTeamFlag}`)}
                    />
                  ) : (
                    <Image src={ArgentinaImage} sx={flagSize} />
                  )}
                  {activeFixtureInfo &&
                  activeFixtureInfo.localTeamName != null ? (
                    <Text variant="p" lineHeight="1.1rem">
                      {activeFixtureInfo.localTeamName}
                    </Text>
                  ) : (
                    <Text variant="p">Canada</Text>
                  )}
                </Flex>
                <Flex flexDir="column">
                  {activeFixtureInfo &&
                  activeFixtureInfo.visitorTeamFlag !== "" ? (
                    <Image
                      sx={flagSize}
                      src={require(`../../../../assets/psl/flags/${activeFixtureInfo.visitorTeamFlag}`)}
                    />
                  ) : (
                    <Image src={ArgentinaImage} sx={flagSize} />
                  )}
                  {activeFixtureInfo &&
                  activeFixtureInfo.visitorTeamName !== null ? (
                    <Text variant="p" lineHeight="1.1rem">
                      {activeFixtureInfo.visitorTeamName}
                    </Text>
                  ) : (
                    <Text variant="p">Canada</Text>
                  )}
                </Flex>
              </Flex>
              <Heading
                variant="45"
                fontFamily="Neue Power Ultra"
                textTransform="uppercase"
                lineHeight="1.75rem"
                fontWeight="800"
                paddingTop="0.5rem"
              >
                Starting 11
              </Heading>
              <>
                {activeMatchdaySquad && activeMatchdaySquad.name !== null ? (
                  <Text variant="p" color="#e5a428">
                    {removeTrailingW(activeMatchdaySquad.name)}
                  </Text>
                ) : (
                  <Text variant="p" color="#e5a428">
                    South Africa
                  </Text>
                )}
              </>
            </Box>
            <Box className="squad-player-container" paddingTop="0.5rem">
              {activeMatchdaySquad &&
              Array.isArray(activeMatchdaySquad.startingEleven)
                ? activeMatchdaySquad.startingEleven.map((player, index) => (
                    <Flex key={index} gap="1rem">
                      <Box width="30px">
                        <Heading
                          variant="h6"
                          color="#e5a428"
                          sx={playerTextStyling}
                        >
                          {player["@number"]}
                        </Heading>
                      </Box>
                      <Box>
                        <Heading
                          key={index}
                          variant="h6"
                          sx={playerTextStyling}
                        >
                          {formatPlayerName(player["@name"])}
                        </Heading>
                      </Box>
                    </Flex>
                  ))
                : playerPlaceholder.map((squadPlayer, index) => (
                    <Flex key={index} gap="1rem">
                      <Box width="40px">
                        <Heading
                          variant="h6"
                          color="#e5a428"
                          sx={playerTextStyling}
                        >
                          {squadPlayer.number}
                        </Heading>
                      </Box>
                      <Box>
                        <Heading
                          key={index}
                          variant="h6"
                          sx={playerTextStyling}
                        >
                          {squadPlayer.name}
                        </Heading>
                      </Box>
                    </Flex>
                  ))}
            </Box>
            <Box className="squad-formation-container" paddingTop="0.5rem">
              {activeMatchdaySquad &&
              Array.isArray(activeMatchdaySquad) &&
              activeMatchdaySquad.formation != null ? (
                <Heading variant="h6" sx={playerTextStyling}>
                  {activeMatchdaySquad.formation}
                </Heading>
              ) : (
                <Heading variant="h6" sx={playerTextStyling}>
                  4 5 1
                </Heading>
              )}
            </Box>

            <Box className="squad-subs-container" paddingTop="0.5rem">
              <Heading variant="h6" sx={subHeadingStyling}>
                Substitutes
              </Heading>
              <Flex flexWrap="wrap">
                {activeMatchdaySquad &&
                Array.isArray(activeMatchdaySquad.subs) &&
                activeMatchdaySquad.subs !== null
                  ? activeMatchdaySquad.subs.map((sub, index) => (
                      <Flex key={index}>
                        <Text sx={subsTextStyling} color="#e5a428">
                          &nbsp;{sub["@number"]},&nbsp;
                        </Text>
                        <Text fontSize="0.9rem" lineHeight="1.2rem">
                          &nbsp;{formatPlayerName(sub["@name"])}&nbsp;
                        </Text>
                      </Flex>
                    ))
                  : playerPlaceholder.map((squadPlayer, index) => (
                      <Flex key={index}>
                        <Text sx={subsTextStyling} color="#e5a428">
                          &nbsp;{squadPlayer.number},&nbsp;
                        </Text>
                        <Text>&nbsp;{squadPlayer.name}&nbsp; </Text>
                      </Flex>
                    ))}
              </Flex>
            </Box>
            <Box className="squad-manager-container" paddingTop="0.5rem">
              <Heading variant="h6" sx={subHeadingStyling}>
                Manager
              </Heading>
              {activeMatchdaySquad && activeMatchdaySquad.coach !== null ? (
                <Heading variant="h6" sx={playerTextStyling}>
                  {activeMatchdaySquad.coach}
                </Heading>
              ) : (
                <Heading variant="h6" sx={playerTextStyling}>
                  D. Ellis
                </Heading>
              )}
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};

export default LeagueStartingElevenArtwork;
