import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import ArgentinaImage from "../../../../assets/womensWorldCup/flags/Argentina.png";
import SundownsImage from "../../../../assets/psl/flags/Mamelodi Sundowns.png";
import WcLogo from "../../../../assets/womensWorldCup/logo/FIFA-Womens-World-Cup-2023.png";
import Pattern from "../../../../assets/Horizontal pattern.svg";
import React, { useEffect } from "react";
import DstvPremLogo from "../../../../assets/psl/DStv-Prem-logo.svg";
import PlaceHolderImage from "../../../../assets/psl/squadImages/Orlando Pirates/M. Saleng.png";
// Helpers
import { SaTimeHelper } from "../../../../helpers/SaTimeHelper";
import { WwwcGroupsHelper } from "../../../../helpers/WwcGroupsHelper";
import useFetch from "../../../../actions/useFetch";
import useUrlState from "@ahooksjs/use-url-state";
import { removeTrailingW } from "../../../../helpers/removeTrailingW";
import { RemoveParentheses } from "../../../../helpers/RemoveParantheses";

const LeagueFixturesArtwork = ({ displayedFixtures }) => {
  const singleClubContainer = {
    paddingBottom: "0.25rem",
    // flexDir: "",
    gap: "0.5rem",
    alignItems: "center",
    height: "100%",
  };

  const singleFixtureContainer = {
    paddingBottom: "0.5rem",
    height: "100px",
  };

  const fixtureDetailsStying = {
    fontSize: "0.85rem",
  };

  const fixtureVisitorDetailsStying = {
    fontSize: "0.85rem",
    textAlign: "right",
  };

  const flagSize = {
    width: "70px",
    height: "50px",
  };

  const singleFixtureTime = {
    display: "flex",
    alignItems: "flex-end",
  };

  return (
    <>
      <Box
        overflow="hidden"
        height="700px"
        width="700px"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        className="artwork-container"
        backgroundColor="#fff8ef"
      >
        <Heading
          textTransform="uppercase"
          className="fixture-heading"
          variant="h1"
          fontSize="8rem"
          fontFamily="Neue Power Ultra"
          textAlign="center"
          marginTop="-0.5rem"
        >
          Fixtures
        </Heading>
        <Flex
          className="artwork-header"
          justifyContent="space-between"
          alignItems="center"
          margin="-8.75rem 2rem 0 2rem"
        >
          <Box zIndex="1">
            <Heading
              variant="h5"
              fontFamily="Neue Power Ultra"
              textTransform="uppercase"
              lineHeight="1.5rem"
              fontWeight="800"
            >
              DSTV
              <Box color="#e5a428"> Premiership</Box>
              Fixtures
            </Heading>
          </Box>
          <Box zIndex="1">
            <Image src={DstvPremLogo} height="90px" />
          </Box>
        </Flex>
        <Box
          className="artwork-body"
          display="flex"
          gap="1rem"
          margin="1rem 0 1rem 0"
          paddingX="2rem"
        >
          <Box
            className="fixture"
            borderRadius="1rem"
            backgroundColor="rgb(255,255,255, 0.7)"
            width="60%"
            border="1px solid #e5a428"
            padding="0.5rem"
            zIndex="100"
          >
            {displayedFixtures != "" && displayedFixtures.length > 0 ? (
              displayedFixtures.slice(0, 5).map((fixture, index) => (
                <Box
                  key={index}
                  sx={singleFixtureContainer}
                  className="single-fixture-container"
                >
                  <Flex
                    className="single-fixture__info"
                    justifyContent="space-between"
                  >
                    <Flex justifyContent="space-between" width="100%">
                      <Flex sx={singleClubContainer}>
                        {fixture.localTeam != null &&
                          fixture.localTeam?.flag != "" && (
                            <Image
                              src={require(`../../../../assets/psl/flags/${fixture.localTeam?.flag}`)}
                              sx={flagSize}
                            />
                          )}
                        <Heading variant="h4">
                          {fixture.localTeam?.score}
                        </Heading>
                      </Flex>
                      <Flex sx={singleClubContainer} justifyContent="flex-end">
                        <Heading variant="h4">
                          {fixture.visitorTeam?.score}
                        </Heading>
                        {fixture.visitorTeam != null &&
                          fixture.visitorTeam?.flag != "" && (
                            <Image
                              src={require(`../../../../assets/psl/flags/${fixture.visitorTeam?.flag}`)}
                              sx={flagSize}
                            />
                          )}
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex
                    className="single-fixture__fixture"
                    justifyContent="center"
                    width="100%"
                  >
                    <Flex
                      justifyContent="space-between"
                      gap="1rem"
                      width="100%"
                    >
                      {fixture.venue != "" && fixture.date != "" ? (
                        <>
                          <Text
                            variant="p"
                            sx={fixtureDetailsStying}
                            color="#000"
                          >
                            {RemoveParentheses(fixture.venue)}
                          </Text>
                          <Text
                            variant="p"
                            sx={fixtureDetailsStying}
                            textAlign="right"
                          >
                            {fixture.date}, {SaTimeHelper(fixture.time, 2)}
                          </Text>
                        </>
                      ) : (
                        <>
                          <Text variant="p" sx={fixtureDetailsStying}>
                            FNB Stadium
                          </Text>
                          <Text variant="p" sx={fixtureDetailsStying}>
                            21.07.2023, 16:00
                          </Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Box>
              ))
            ) : (
              <Box>
                <Flex
                  className="single-fixture__info"
                  justifyContent="space-between"
                  paddingTop="0.5rem"
                >
                  <Flex justifyContent="space-between" width="100%">
                    <Flex sx={singleClubContainer}>
                      <Image src={SundownsImage} sx={flagSize} />
                    </Flex>
                    <Flex sx={singleClubContainer}>
                      <Image src={SundownsImage} sx={flagSize} />
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  className="single-fixture__fixture"
                  justifyContent="space-between"
                >
                  <Flex justifyContent="space-between" width="100%">
                    <Text variant="p" sx={fixtureDetailsStying}>
                      FNB Stadium
                    </Text>
                    <Text variant="p" sx={fixtureDetailsStying}>
                      21.07.2023, 16:00
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            )}
          </Box>

          <Box
            className="fixture"
            borderRadius="1rem"
            backgroundColor="rgb(255,255,255, 0.7)"
            width="60%"
            border="1px solid #e5a428"
            padding="0.5rem"
            zIndex="100"
          >
            {displayedFixtures != "" && displayedFixtures.length > 0 ? (
              displayedFixtures.slice(5, 10).map((fixture, index) => (
                <Box key={index} sx={singleFixtureContainer}>
                  <Flex
                    className="single-fixture__info"
                    justifyContent="space-between"
                  >
                    <Flex justifyContent="space-between" width="100%">
                      <Flex sx={singleClubContainer}>
                        <Heading variant="h4">
                          {fixture.localTeam?.score}
                        </Heading>
                        {fixture.localTeam != null &&
                          fixture.localTeam?.flag != "" && (
                            <Image
                              src={require(`../../../../assets/psl/flags/${fixture.localTeam?.flag}`)}
                              sx={flagSize}
                            />
                          )}
                      </Flex>
                      <Flex sx={singleClubContainer} justifyContent="flex-end">
                        <Heading variant="h4">
                          {fixture.visitorTeam?.score}
                        </Heading>
                        {fixture.visitorTeam != null &&
                          fixture.visitorTeam?.flag != "" && (
                            <Image
                              src={require(`../../../../assets/psl/flags/${fixture.visitorTeam?.flag}`)}
                              sx={flagSize}
                            />
                          )}
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex
                    className="single-fixture__fixture"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Flex
                      justifyContent="space-between"
                      gap="1rem"
                      width="100%"
                    >
                      {fixture.venue != "" && fixture.date != "" ? (
                        <>
                          <Text
                            variant="p"
                            sx={fixtureDetailsStying}
                            color="#000"
                          >
                            {RemoveParentheses(fixture.venue)}
                          </Text>
                          <Text variant="p" sx={fixtureVisitorDetailsStying}>
                            {fixture.date}, {SaTimeHelper(fixture.time, 2)}
                          </Text>
                        </>
                      ) : (
                        <>
                          <Text variant="p" sx={fixtureDetailsStying}>
                            FNB Stadium
                          </Text>
                          <Text variant="p" sx={fixtureDetailsStying}>
                            21.07.2023, 16:00
                          </Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Box>
              ))
            ) : (
              <Box>
                <Flex
                  className="single-fixture__info"
                  justifyContent="space-between"
                  paddingTop="0.5rem"
                >
                  <Flex justifyContent="space-between" width="100%">
                    <Flex sx={singleClubContainer}>
                      <Image src={SundownsImage} sx={flagSize} />
                    </Flex>
                    <Flex sx={singleClubContainer}>
                      <Image src={SundownsImage} sx={flagSize} />
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  className="single-fixture__fixture"
                  justifyContent="space-between"
                >
                  <Flex justifyContent="space-between" width="100%">
                    <Text variant="p" fontSize="0.85rem">
                      FNB Stadium
                    </Text>
                    <Text variant="p" fontSize="0.85rem">
                      21.07.2023, 16:00
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            )}
          </Box>
        </Box>

        <Image
          // src={require(`../../../../assets/womensWorldCup/squadImages/${removeTrailingW(
          //   activeTeam.name
          // )}/${activeTeam.image}`)}
          src={PlaceHolderImage}
          marginBottom="100px"
          position="fixed"
          bottom="-120"
          right="-10"
          height="620px"
          zIndex="1"
        />

        <Image src={Pattern} />
      </Box>
    </>
  );
};

export default LeagueFixturesArtwork;
